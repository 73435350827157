import React from 'react';
import { Col, Row } from 'react-bootstrap';
import tipsImg from '../../../../assets/imgs/tips/tipsImg.jpg';
const TipsSection = () => {
  return (
    <Row className="rowSection" id="programa">
      <Col
        xs={12}
        lg={6}
        className="tipsCol tipsColTitle d-flex d-lg-none gs_reveal gs_reveal_direction"
      >
        <div>
          <h2>
            ПРЕИМУЩЕСТВА <span className="featuredText">КУРСА</span>
          </h2>
          <h3>Кати Сафаровой</h3>
        </div>
      </Col>
      <Col
        xs={12}
        lg={6}
        className="tipsCol tipsColImg gs_reveal gs_reveal_fromLeft"
      >
        <div className="triangleTopLeft triangleBgColor2">
          <div className="section-parallax">
            <img src={tipsImg} alt="" className="tipsImg" />
          </div>
        </div>
      </Col>
      <Col xs={12} lg={6} className="tipsCol gs_reveal gs_reveal_fromRight">
        <div>
          <h2 className="d-none d-lg-block">
            ПРЕИМУЩЕСТВА <span className="featuredText">КУРСА</span>
          </h2>
          <h3 className="d-none d-lg-block">Кати Сафаровой</h3>

          <ul className="customList withMarginLeft">
            <li>Возможность заниматься как в спортзале, так и дома.</li>
            <li>
              Подбери для себя любой подходящий уровень тренировок (начальный,
              средний, сложный) и получай удовольствие, прорабатывая всё своё
              тело.
            </li>
            <li>
              Онлайн-формат. Доступ с компьютера, мобильного телефона или айпода
              в удобное для тебя время.
            </li>
            <li>
              Пожизненный доступ к материалам курса и поддержка по е-мейл при
              разовой оплате.
            </li>
            <li>
              Мы дадим тебе конкретные рекомендации, касающиеся тренировок и
              питания, при этом в центре внимания всегда будет оставаться твоё
              здоровье.
            </li>
          </ul>
        </div>
      </Col>
    </Row>
  );
};

export default TipsSection;
