import React, { useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import infoImg1 from "../../../../assets/imgs/info/1.jpg";
import infoImg2 from "../../../../assets/imgs/info/2.jpg";
import { ScrollContext } from "../../../../layout/Layout";
const InfoSection = () => {
   const getScroll = useContext(ScrollContext);

   return (
      <Row className="rowSection" id="beneficios">
         <Row className="infoRow d-none d-lg-flex">
            <Col xs={12} lg={6} className="infoCol gs_reveal gs_reveal gs_reveal_formLeft">
               <div>
                  <h2>
                     Вот список того, чему ты сможешь научиться и чего ты сможешь добиться, записавшись на мой курс:
                     {/* <span className="featuredText">метод</span> */}
                  </h2>
                  <ul className="customList withMarginLeft">
                     <li>Упругие ягодицы, стройные ноги, плоский живот, красивые руки.</li>
                     <li>Наладить режим, который позволит тебе улучшить внутреннее состояние и физическую форму с помощью питания, спорта и йоги.</li>
                     <li>Советы, чтобы прокачать мышцы и избавиться от жира.</li>
                     <li>Комплекс упражнений, который я сама выполняю и котором раньше я ни с кем неделилась.</li>
                  </ul>
               </div>
            </Col>
            <Col xs={12} lg={6} className="infoCol infoColImg gs_reveal gs_reveal_direction">
               <div className="triangleTopRight triangleBgColor2">
                  <div className="section-parallax">
                     <img src={infoImg1} alt="" className="infoImg" />
                  </div>
               </div>
            </Col>
            <Col xs={12} lg={6} className="infoCol infoColImg gs_reveal gs_reveal_direction">
               <div className="triangleTopLeft triangleBgColor2">
                  <div className="section-parallax">
                     <img src={infoImg2} alt="" className="infoImg cropBorderLeft" />
                  </div>
               </div>
            </Col>
            <Col xs={12} lg={6} className="infoCol gs_reveal gs_reveal_fromRight">
               <div>
                  <ul className="customList">
                     <li>Как питаться, чтобы получить тело, о котором ты мечтаешь,не голодая и употребляя я продукты, которые у всех нас найдутся в холодильнике.</li>
                     <li>Интересные тренировки для любого уровня подготовки (начальный, средний, продвинутый).</li>
                     <li>Рекомендации, которым вы должны следовать, чтобы оставаться в гармонии и,таким образом, достичь счастья..</li>
                     <li>Как улучшить cвоё здоровье и, как следствие, повысить свою энергию и благополучие в целом.</li>
                  </ul>
                  <Button
                     bsPrefix
                     className="buttonStyle buttonText button--calypso button--calypso-primary"
                     onClick={() => {
                        getScroll().scrollTo(document.querySelector("#payment"), {
                           offset: -72,
                        });
                     }}
                  >
                     <span>НАЧАТЬ ТРАНСФОРМАЦИЮ</span>
                  </Button>
               </div>
            </Col>
         </Row>
         <Row className="infoRow d-flex d-lg-none">
            <Col xs={12} lg={6} className="infoCol gs_reveal gs_reveal_direction">
               <div>
                  <h2>
                     Вот список того, чему ты сможешь научиться и чего сможешь добиться, записавшись на мой курс:
                     {/* <span className="featuredText">метод</span> */}
                  </h2>
               </div>
            </Col>
            <Col xs={12} lg={6} className="infoCol infoColImg d-flex d-lg-none gs_reveal gs_reveal_direction">
               <div className="triangleTopLeft triangleBgColor2">
                  <div className="section-parallax">
                     <img src={infoImg2} alt="" className="infoImg" />
                  </div>
               </div>
            </Col>
            <Col xs={12} lg={6} className="infoCol gs_reveal gs_reveal_direction">
               <div>
                  <ul className="customList withMarginLeft">
                     <li>Упругие ягодицы, стройные ноги, плоский живот, красивые руки.</li>
                     <li>Наладить режим, который позволит тебе улучшить внутреннее состояние и физическую форму с помощью питания, спорта и йоги.</li>
                     <li>Советы, чтобы прокачать мышцы и избавиться от жира.</li>
                     <li>Комплекс упражнений, который я сама выполняю и котором раньше я ни с кем неделилась.</li>
                  </ul>
               </div>
            </Col>
            <Col xs={12} lg={6} className="infoCol infoColImg gs_reveal gs_reveal_direction">
               <div className="triangleTopRight triangleBgColor2">
                  <div className="section-parallax">
                     <img src={infoImg1} alt="" className="infoImg cropBorderLeft" />
                  </div>
               </div>
            </Col>
            <Col xs={12} lg={6} className="infoCol infoColImg d-none d-lg-flex gs_reveal gs_reveal_direction">
               <div className="triangleTopLeft triangleBgColor2">
                  <div className="section-parallax">
                     <img src={infoImg2} alt="" className="infoImg" />
                  </div>
               </div>
            </Col>
            <Col xs={12} lg={6} className="infoCol gs_reveal gs_reveal_direction">
               <div>
                  <ul className="customList">
                     <li>Как питаться, чтобы получить тело, о котором ты мечтаешь,не голодая и употребляя я продукты, которые у всех нас найдутся в холодильнике.</li>
                     <li>Интересные тренировки для любого уровня подготовки (начальный, средний,продвинутый)</li>
                     <li>Рекомендации, которым вы должны следовать, чтобы оставаться в гармонии и,таким образом, достичь счастья..</li>
                     <li>Как улучшить cвоё здоровье и, как следствие, повысить свою энергию иблагополучие в целом.</li>
                  </ul>

                  <Button
                     bsPrefix
                     className="buttonStyle buttonText button--calypso button--calypso-primary d-none d-lg-inline-block"
                     onClick={() => {
                        getScroll().scrollTo(document.querySelector("#payment"), {
                           offset: -72,
                        });
                     }}
                  >
                     <span>НАЧАТЬ ТРАНСФОРМАЦИЮ</span>
                  </Button>
               </div>
            </Col>
            <Col xs={12} lg={6} className="infoCol infoColButton d-flex d-lg-none">
               <div>
                  <Button
                     bsPrefix
                     className="buttonStyle buttonText button--calypso button--calypso-primary"
                     onClick={() => {
                        getScroll().scrollTo(document.querySelector("#payment"), {
                           offset: -72,
                        });
                     }}
                  >
                     <span>НАЧАТЬ ТРАНСФОРМАЦИЮ</span>
                  </Button>
               </div>
            </Col>
         </Row>
      </Row>
   );
};

export default InfoSection;
