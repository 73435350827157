import React, { useContext, useRef, useState } from 'react';
import { Col, Row, ResponsiveEmbed } from 'react-bootstrap';
import vslInsideImg from '../../../../assets/imgs/inside/vslInsideImg.jpg';

import insideGif from '../../../../assets/imgs/inside/inside.mp4';
import insideGifWebm from '../../../../assets/imgs/inside/inside.webm';
import { PlayerTogglerContext } from '../../../../layout/Layout';

const InsideSection = () => {
  const PlayerToggler = useContext(PlayerTogglerContext);

  return (
    <Row className="rowSection">
      <Col
        xs={12}
        className="insideCol insideColTitle gs_reveal gs_reveal_direction"
      >
        <div>
          <h2>
            Что тебя ждёт <br className="d-none d-lg-block" />в этом{' '}
            <span className="featuredText">курсе</span>
          </h2>
        </div>
      </Col>
      <Col xs={12} className="insideCol insideColVideo">
        <div className="triangleTopLeft playerWrap">
          <video
            className="lazy insideVideo"
            autoPlay
            loop
            muted
            playsInline
            poster={vslInsideImg}
          >
            <source data-src={insideGifWebm} type="video/webm" />
            <source data-src={insideGif} type="video/mp4" />
          </video>

          <div
            className={'playContainer magnetic-wrap'}
            onClick={() => {
              PlayerToggler.setToggler({
                opened: !PlayerToggler.toggler.opened,
                src: 'https://player.vimeo.com/video/568330310?badge=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479',
              });
            }}
          >
            <div className="js-magnetic-area magnetic-size"></div>
            <div className="js-magnetic-content">
              <svg
                width="185"
                height="129"
                viewBox="0 0 185 129"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                id="playIconInside"
              >
                <path
                  d="M18.8623 0L0 129H165.724L185 0H18.8623Z"
                  fill="#F6AF25"
                />
                <path
                  d="M117 64L79.5 85.6506L79.5 42.3494L117 64Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default InsideSection;
