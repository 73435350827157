import React, { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import textBackground from '../../assets/imgs/icons/textBackground.svg';
import { Layout, isPromoActive, isBlackFriday } from '../../layout/Layout';
import FooterEs from '../../components/footer/FooterEs';
import vslInsideImg from '../../assets/imgs/cite/citeImg.jpg';

import { Helmet } from 'react-helmet-async';
import ReactPixel from 'react-facebook-pixel';
import axios from 'axios';

const ThankPageEs = () => {
  useEffect(() => {
    ReactPixel.trackCustom('ThankPageView_ES');
    ReactPixel.track('Purchase', {
      currency: 'EUR',
      value: 39,
    });
    const sendFbConversionReq = async () => {
      let ua = navigator.userAgent;
      let currUrl = document.URL;
      try {
        const text = async (url) => {
          return await fetch(url).then((res) => res.text());
        };
        return text('https://www.cloudflare.com/cdn-cgi/trace').then((data) => {
          let ipRegex = /[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/;
          let ip = data.match(ipRegex)[0];
          axios
            .post(
              'https://graph.facebook.com/v11.0/319179192442493/events?access_token=EAALXJK4pegYBAObtEQxTcJCPJ91jgHuGxNxDZApFrtWvGM0neCW8Y613ZAMLXv9Laxz6BOaqqDxZByYrojWoE0XdrznqZAj5QyOW2QT96KblorZCFd9RhEs6I61qCItJsSHOR0WtgKUrybfj158Fas8AcKunCHg820sAqDr515rjaAZBMnrPZBzzZBomflldzQgZD',
              {
                data: [
                  {
                    event_name: 'Purchase',
                    event_time: 1627306851,
                    user_data: {
                      client_ip_address: ip,
                      client_user_agent: ua,
                    },
                    custom_data: {
                      value: 39,
                      currency: 'EUR',
                    },
                    event_source_url: currUrl,
                    action_source: 'website',
                    data_processing_options: ['LDU'],
                    data_processing_options_country: 1,
                    data_processing_options_state: 1000,
                    opt_out: true,
                  },
                ],
              }
            )
            .then(function (response) {
              return;
            })
            .catch(function (error) {
              return;
            });
        });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendFbConversionReq();
  }, []);

  let webPage = window.location.pathname.includes('-es')
    ? 'katsafarova.com/-es'
    : 'katsafarova.com/es';
  let suportEmail = 'dudascursokat@suprive.com';

  return (
    <Layout>
      <Helmet title="Método Kat Safarova">
        <meta
          name="description"
          content="Consigue un cuerpo saludable y en forma"
        />
      </Helmet>
      <Row className="RootThankPage RootContainers sectionPolygon sectionPolygonBottomExtern">
        <div className="sectionPolygon1 spb" />
        <div className="sectionPolygon2 spb" />
        <Row className="rowSection">
          <Col xs={12} lg={6} className="thankCol thankColTitle">
            <div>
              <div className="textBackgroundWrap">
                <img src={textBackground} alt="" className="textBackground" />
                <h2>
                  Ya estás dentro del{' '}
                  <span className="featuredText">método</span>
                </h2>
                <h3>KAT SAFAROVA</h3>
              </div>

              <h4>
                ¡Gracias por
                <br />
                inscribirte al curso!
              </h4>

              <p>
                Dale al botón de abajo para entrar, y por si no quieres empezar
                ahora, en breves recibirás un correo con instrucciones para
                entrar más tarde.
              </p>

              <ul className="customList">
                <li>
                  (Si no lo encuentras revisa la carpeta de Spam y la de
                  Promociones).
                </li>
              </ul>
              <Button
                bsPrefix
                className="buttonStyle buttonText button--calypso button--calypso-primary"
                href="https://sso.teachable.com/secure/998051/users/sign_in"
              >
                <span>IR AL CURSO</span>
              </Button>
            </div>
          </Col>
          <Col xs={12} lg={6} className="thankCol thankColVid">
            <div className="triangleTopLeft playerWrap">
              <img src={vslInsideImg} alt="" className="vslInsideImg" />

              {/* <ResponsiveEmbed aspectRatio="16by9" className="embedContainer">
                <ReactPlayer
                  ref={videoRef}
                  id="interviewIframe"
                  url="https://player.vimeo.com/video/373624785?badge=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479"
                  width="100%"
                  height="100%"
                  controls={true}
                  playing={playingState}
                  onPlay={onPlay}
                  onPause={onPause}
                />
              </ResponsiveEmbed>
              <div
                className={
                  playingState
                    ? 'playingVideo playContainer magnetic-wrap'
                    : 'playContainer magnetic-wrap'
                }
                onClick={() => {
                  setPlayingState(!playingState);
                }}
              >
                <div className="js-magnetic-area magnetic-size"></div>
                <div className="js-magnetic-content">
                  <svg
                    width="185"
                    height="129"
                    viewBox="0 0 185 129"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    id="playIconInside"
                  >
                    <path
                      d="M18.8623 0L0 129H165.724L185 0H18.8623Z"
                      fill="#F6AF25"
                    />
                    <path
                      d="M117 64L79.5 85.6506L79.5 42.3494L117 64Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div> */}
            </div>
          </Col>
        </Row>
      </Row>
      <Row className="RootThankFaq RootContainers sectionsPadding">
        {/* <div className="sectionPolygon1 spb" />
        <div className="sectionPolygon2 spb" /> */}
        <Row className="rowSection">
          <Col xs={12} lg={6} className="thankFaqCol">
            <div>
              <h3 className="membersTitle">¿Tienes dudas o problemas?</h3>
              <p>
                ¡No te preocupes! Para eso estoy. Contactame al chat que está en
                mi web {webPage} o mandame un correo a{' '}
                <a href={`mailto:${suportEmail}`}>{suportEmail}</a>
              </p>
              <div style={{display: (isPromoActive() && isBlackFriday) ? '' : 'none', color: '#f7f7f7'}}>29</div>
            </div>
          </Col>
        </Row>
      </Row>
      <FooterEs />
    </Layout>
  );
};

export default ThankPageEs;
