import React, { createContext, useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { CustomNavbar as Navbar } from '../components/navbar/Navbar';
import { CustomNavbarEs as NavbarEs } from '../components/navbar/NavbarEs';
import LocomotiveScroll from 'locomotive-scroll';

// STYLE
import './Layout.css';

// GSAP
import gsap, { TimelineLite } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import MagneticArea from '../assets/gsap/magneticArea';
import FsLightbox from 'fslightbox-react';
import polygonAnimation from '../assets/gsap/polygonAnimation';
import aniamtionsOnScroll from '../assets/gsap/aniamtionsOnScroll';
import imagesParallax from '../assets/gsap/imagesParallax';
import CustomNavbarThank from '../components/navbar/navbarsThankPage/Navbar';
import CustomNavbarThankEs from '../components/navbar/navbarsThankPage/NavbarEs';
import DeadLinePromo from '../components/countDownPromo/DeadLinePromo';
// Libraries
// import AOS from 'aos';
// import SmoothScroll from 'smooth-scroll';
export const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua);
};

export const PromoDates = {
  dateActive: new Date('2021-11-22T11:00:00+01:00'),
  dateExpire: new Date('2021-11-26T23:59:00+01:00'),
  dateActive2: new Date('2021-11-28T11:00:00+01:00'),
  dateExpire2: new Date('2021-11-29T23:59:00+01:00'),
  todayDate: new Date(),
};

export const isPromoActive = () => {
  const dateActive = PromoDates.dateActive;
  const dateExpire = PromoDates.dateExpire;
  const dateActive2 = PromoDates.dateActive2;
  const dateExpire2 = PromoDates.dateExpire2;
  const todayDate = PromoDates.todayDate;
  //if (window.location.pathname.indexOf('/es') === 0) return false;
  if((new URLSearchParams(window.location.search).get('chkprm')) == 1) return true;
  return (dateActive < todayDate && dateExpire > todayDate) || (dateActive2 < todayDate && dateExpire2 > todayDate);
};

export const isPromoFinish = () => {
  const dateExpire2 = PromoDates.dateExpire2;
  const todayDate = PromoDates.todayDate;
  if((new URLSearchParams(window.location.search).get('chkprmfin')) == 1) return false;
  return dateExpire2 < todayDate;
};

export const isBlackFriday = false

export const PlayerTogglerContext = createContext(null);
export const ScrollContext = createContext(null);

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
}

export const Layout = (props) => {
  let scroll = useRef(null);

  useEffect(() => {
    // AOS.init();
    // new SmoothScroll('a[href*="#"]', { offset: 50 });
    scroll.current = new LocomotiveScroll({
      el: document.querySelector('[data-scroll-container]'),
      smooth: true,
      reloadOnContextChange: true,

      // smartphone: {
      //   smooth: true,
      // },
      // tablet: {
      //   smooth: true,
      // },
      getDirection: true,
    });
    setTimeout(() => scroll.current.update(), 500);
    window.addEventListener('load', () => {
      setTimeout(() => {
        scroll.current.update();
      }, 300);
    });
    scroll.current.on('scroll', (instance) => {
      document.documentElement.setAttribute(
        'data-scroll-direction',
        instance.direction
      );
    });

    scroll.current.on('resize', () => {
      setTimeout(() => {
        scroll.current.update();
      }, 300);
    });

    scroll.current.on('scroll', () => {
      ScrollTrigger.update();
    });

    ScrollTrigger.scrollerProxy('[data-scroll-container]', {
      scrollTop(value) {
        return arguments.length
          ? scroll.current.scrollTo(value, 0, 0)
          : scroll.current.scroll.instance.scroll.y;
      }, // we don't have to define a scrollLeft because we're only scrolling vertically.
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
      // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
      pinType: document.querySelector('[data-scroll-container]').style.transform
        ? 'transform'
        : 'fixed',
    });

    ScrollTrigger.addEventListener('refresh', () => scroll.current.update());

    ScrollTrigger.refresh();
  }, []);

  // useEffect(() => {
  //   window.onload = () => {
  //     document.body.classList.add('loaded');
  //     setTimeout(() => {
  //       document.querySelector('.loaded #loader-wrapper').style.display =
  //         'none';
  //     }, 1100);
  //   };
  // }, []);

  useEffect(() => {
    if (!isMobile()) {
      MagneticArea();
    }

    window.onload = () => {
      polygonAnimation();
    };

    aniamtionsOnScroll(isMobile);
    // window.addEventListener('DOMContentLoaded', (event) => {
    imagesParallax();
    // });

    new TimelineLite({
      onUpdate: () => {
        window.dispatchEvent(new Event('resize'));
      },
      paused: true,
    });
  }, []);

  const [toggler, setToggler] = useState({
    opened: false,
    src: null,
  });
  let PlayerToggler = useRef(setToggler);
  const getPlayerToggler = () => {
    return PlayerToggler.current;
  };

  const getScroll = () => {
    return scroll.current;
  };

  useEffect(() => {
    // VIDEO LAZY LOAD
    document.addEventListener('DOMContentLoaded', function () {
      var lazyVideos = [].slice.call(document.querySelectorAll('video.lazy'));

      if ('IntersectionObserver' in window) {
        var lazyVideoObserver = new IntersectionObserver(function (
          entries,
          observer
        ) {
          entries.forEach(function (video) {
            if (video.isIntersecting) {
              for (var source in video.target.children) {
                var videoSource = video.target.children[source];
                if (
                  typeof videoSource.tagName === 'string' &&
                  videoSource.tagName === 'SOURCE'
                ) {
                  videoSource.src = videoSource.dataset.src;
                }
              }

              video.target.load();
              video.target.classList.remove('lazy');
              lazyVideoObserver.unobserve(video.target);
            }
          });
        });

        lazyVideos.forEach(function (lazyVideo) {
          lazyVideoObserver.observe(lazyVideo);
        });
      }
    });
  }, []);

  return (
    <ScrollContext.Provider value={getScroll}>
      <PlayerTogglerContext.Provider
        value={{ getPlayerToggler, toggler, setToggler }}
      >
        {window.location.pathname.includes('k4t-s4f4r0v4-th4nk-y0u') ? (
          window.location.pathname.includes('/es') ? (
            <CustomNavbarThankEs />
          ) : (
            <CustomNavbarThank />
          )
        ) : window.location.pathname.includes('/es') ||
          window.location.pathname.includes('/-es') ||
          window.location.pathname.includes('/es-') ? (
          <NavbarEs />
        ) : (
          <Navbar />
        )}

        {isPromoActive() &&
        window.location.pathname !== '/ru/k4t-s4f4r0v4-th4nk-y0u' ? (
          <>
            {window.location.pathname.includes('/es') && (
              <DeadLinePromo lang={'es'} />
            )}
            {window.location.pathname.includes('/ru') && (
              <DeadLinePromo lang={'ru'} />
            )}
          </>
        ) : (
          ''
        )}

        <div
          data-scroll-container
          data-scroll-direction
          data-scroll-pos
          id="top"
        >
          <Container
            nogutters="true"
            fluid
            id="inicio"
            className="layoutContainer"
          >
            {props.children}
          </Container>
        </div>

        <FsLightbox
          toggler={toggler.opened}
          sources={[
            <iframe
              src={toggler.src}
              width="1920"
              height="1080"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="VSL - NFT MASTERY"
            ></iframe>,
          ]}
          onOpen={() => {
            // bodyScrollBar.current.track.yAxis.hide();
          }}
          onClose={() => {
            // bodyScrollBar.current.track.yAxis.show();
          }}
          type="video"
        />
        {/* <div id="loader-wrapper">
          <div id="loader"></div>

          <div className="loader-section section-left"></div>
          <div className="loader-section section-right"></div>
        </div> */}
      </PlayerTogglerContext.Provider>
    </ScrollContext.Provider>
  );
};

export default Layout;
