import React from 'react';
import { Button, Card, CardDeck, Col, Row } from 'react-bootstrap';
import { isPromoFinish } from "../../../../layout/Layout"

const PaymentSection = (props) => {
  const { discount } = props;

  if (isPromoFinish()) {
    return (
      <Row className="rowSection" id="payment">
        <Row className="paymentRow paymentRowTitle">
          <Col xs={12} className="paymentCol gs_reveal gs_reveal_direction">
            <h2>
              Selecciona tu <span className="featuredText">método de pago</span>
            </h2>
          </Col>
        </Row>
        <Row className="paymentRow paymentRowPrice">
          <div className="fullWidthBackgroundTextWrap">
            <div className="gs_reveal gs_reveal_fromLeft">
              <p className="fullWidthBackgroundTextStroke fullWidthBackgroundTextStrokeES">
                MÉTODO
              </p>
              <p className="fullWidthBackgroundTextStroke fullWidthBackgroundTextStrokeES">
                MÉTODO
              </p>
            </div>
            <div className="gs_reveal gs_reveal_fromRight">
              <p className="fullWidthBackgroundTextStroke katName katNameEs">
                KAT
              </p>
              <p className="fullWidthBackgroundTextStroke katName katNameEs">
                SAFAROVA
              </p>
            </div>
          </div>
          <Col xs={12} className="paymentCol">
            <CardDeck style={{width: '100%'}}>
              <Card className="cardStyle cardStyleSingle cardConfig cardStylePayV gs_reveal gs_reveal_fromLeft">
                <Card.Header className="cardHeader triangleTopLeftPay">
                <form method="POST" action="https://suprive40873.activehosted.com/proc.php" id="_form_6155C1216F737_" className="_form _form_209 _inline-form  _dark" noValidate="">
                  <input type="hidden" name="u" value="6155C1216F737" data-name="u" />
                  <input type="hidden" name="f" value="209" data-name="f" />
                  <input type="hidden" name="s" data-name="s" />
                  <input type="hidden" name="c" value="0" data-name="c" />
                  <input type="hidden" name="m" value="0" data-name="m" />
                  <input type="hidden" name="act" value="sub" data-name="act" />
                  <input type="hidden" name="v" value="2" data-name="v" />
                    <h5 className="cardTitle">Las plazas están cerradas</h5>
                    <p className="cardText" style={{textAlign: 'left'}}>Puedes dejar tu correo electrónico y te avisaremos cuando se vuelvan a abrir</p><br />
                    <div style={{width: '100%'}}>
                        <input type="text" name="fullname" required placeholder="nombre*" style={{border: "2px solid #ffca64", width: '90%', padding: '10px', color: '#fff', background: 'transparent'}} />
                    </div><br />
                    <div style={{width: '100%'}}>
                        <input type="email" name="email" required placeholder="correo electrónico*" style={{border: "2px solid #ffca64", width: '90%', padding: '10px', color: '#fff', background: 'transparent'}} />
                    </div><br />
                    <div style={{width: '100%', lineHeight: '1em', textAlign: 'left'}}>
                        <input type="checkbox" id="chk" name="field[11][]" style={{fontSize: '20px', width: '20px', height: '20px', border: "2px solid #ffca64", background: 'transparent'}} /><label htmlFor="chk" style={{color: '#fff', paddingLeft: '10px'}}>*He leído y acepto la política de privacidad</label>
                    </div><br />
                    <Button
                        bsPrefix
                        className="buttonStyle buttonPaymentText button--calypso button--calypso-primary"
                        type="submit"
                        >
                        <span>enviar</span>
                    </Button>
                  </form>
              </Card.Header>
              </Card>
              <Card className="cardStyle cardStyleSingle cardStyleSecondary cardConfig cardStylePayY gs_reveal gs_reveal_fromRight" style={{filter: 'grayscale(100%)'}}>
                <Card.Header className="cardHeader triangleTopLeftPay">
                  <h5 className="cardTitle">PAYPAL</h5>
                  <p className="cardText">Pago único (usd)</p>
                </Card.Header>
                <Card.Body className="cardBody">
                  <div>
                    <div>
                      <span
                        className={
                          discount.price_usd < 49
                            ? 'priceText beforePrice'
                            : 'd-none'
                        }
                      >
                        $49
                      </span>
                    </div>
                    <div>
                      <span className="priceText">$94</span>
                    </div>
                    <br />
                    <div>
                      <span className="priceText"><br />70€</span>
                    </div>
                  </div>
                </Card.Body>
                <Card.Footer className="cardFooter">
                  <Button
                    bsPrefix
                    className="buttonStyle buttonPaymentText button--calypso button--calypso-secondary-payment"
                    href={discount.usd_url}
                    style={{pointerEvents: 'none'}}
                    >
                    <span>PAGAR</span>
                  </Button>
                </Card.Footer>
              </Card>
            </CardDeck>
          </Col>
        </Row>
      </Row>
    );
  }

  return (
    <Row className="rowSection" id="payment">
      <Row className="paymentRow paymentRowTitle">
        <Col xs={12} className="paymentCol gs_reveal gs_reveal_direction">
          <h2>
            Selecciona tu <span className="featuredText">método de pago</span>
          </h2>
        </Col>
      </Row>
      <Row className="paymentRow paymentRowPrice">
        <div className="fullWidthBackgroundTextWrap">
          <div className="gs_reveal gs_reveal_fromLeft">
            <p className="fullWidthBackgroundTextStroke fullWidthBackgroundTextStrokeES">
              MÉTODO
            </p>
            <p className="fullWidthBackgroundTextStroke fullWidthBackgroundTextStrokeES">
              MÉTODO
            </p>
          </div>
          <div className="gs_reveal gs_reveal_fromRight">
            <p className="fullWidthBackgroundTextStroke katName katNameEs">
              KAT
            </p>
            <p className="fullWidthBackgroundTextStroke katName katNameEs">
              SAFAROVA
            </p>
          </div>
        </div>
        <Col xs={12} className="paymentCol">
          <CardDeck style={{width: '100%'}}>
            <Card className="cardStyle cardStyleSingle cardConfig cardStylePayV gs_reveal gs_reveal_fromLeft">
              <Card.Header className="cardHeader triangleTopLeftPay">
                <h5 className="cardTitle" style={{position: 'relative', left: '-28px'}}>Tarjeta de Crédito</h5>
                <p className="cardText">Pago único (eur)</p>
              </Card.Header>
              <Card.Body className="cardBody">
                <div>
                  <div>
                    <span
                      className={
                        discount.price_eur < 39
                          ? 'priceText beforePrice'
                          : 'd-none'
                      }
                    >
                      39€
                    </span>
                  </div>
                  <div>
                    <span className="priceText">{discount.price_eur}€</span>
                  </div>
                </div>
              </Card.Body>
              <Card.Footer>
                <Button
                  bsPrefix
                  className="buttonStyle buttonPaymentText button--calypso button--calypso-primary"
                  href={discount.eur_url}
                >
                  <span>PAGAR</span>
                </Button>
              </Card.Footer>
            </Card>
            <Card className="cardStyle cardStyleSingle cardStyleSecondary cardStylePayY cardConfig gs_reveal gs_reveal_fromRight">
              <Card.Header className="cardHeader triangleTopLeftPay">
                <h5 className="cardTitle">PAYPAL</h5>
                <p className="cardText">Pago único (usd)</p>
              </Card.Header>
              <Card.Body className="cardBody">
                <div>
                  <div>
                    <span
                      className={
                        discount.price_usd < 47
                          ? 'priceText beforePrice'
                          : 'd-none'
                      }
                    >
                      $47
                    </span>
                  </div>
                  <div>
                    <span className="priceText">${discount.price_usd}</span>
                  </div>
                </div>
              </Card.Body>
              <Card.Footer className="cardFooter">
                <Button
                  bsPrefix
                  className="buttonStyle buttonPaymentText button--calypso button--calypso-secondary-payment"
                  href={discount.usd_url}
                >
                  <span>PAGAR</span>
                </Button>
              </Card.Footer>
            </Card>
          </CardDeck>
        </Col>
      </Row>
    </Row>
  );
};

export default PaymentSection;
