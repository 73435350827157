import React from 'react';
import { Row } from 'react-bootstrap';
import MembersSection from '../../sections_Ads/membersSection/MembersSection';

const Members = () => {
  return (
    <Row className="RootMembers RootContainers sectionsPadding">
      <MembersSection />
    </Row>
  );
};

export default Members;
