import React from 'react';
import { Col, Row } from 'react-bootstrap';
import aboutMeImg from '../../../../assets/imgs/aboutMe/aboutMeImg.jpg';
const AboutMeSection = () => {
  return (
    <Row className="rowSection">
      <Col
        className="aboutMeCol aboutMeColImg gs_reveal gs_reveal_fromRight"
        xs={12}
        lg={6}
      >
        <div className="aboutMeImg triangleTopLeft triangleBgColor2">
          <div className="section-parallax">
            <img src={aboutMeImg} alt="" />
          </div>
        </div>
      </Col>
      <Col className="aboutMeCol" xs={12} lg={6}>
        <div className="aboutMeContent">
          <div className="aboutMeTitleWrap gs_reveal gs_reveal_fromLeft">
            <h2>
              В чём <br className="d-block d-lg-none" />
              состоит <span className="featuredText">курс</span>
            </h2>
            <h3>Кати Сафаровой?</h3>
          </div>
          <div className="aboutMeTextWrap gs_reveal gs_reveal_direction">
            <div>
              <p>
                Это курс, которым я пользуюсь сама и который разработан моим
                личными тренером и нутрициологом. Он направлен на то, чтобы
                достичь видимых улучшений твоего тела в кратчайшие сроки, при
                условии соблюдения здорового образа жизни.
              </p>
              <p className="d-none d-lg-block">
                В нём ты найдёшь программу тренировок, узнаешь, чем я питаюсь,
                чтобы быть здоровой, и как с помощью йоги мне удаётся связать в
                единой целое моё тело и мой разум.
              </p>
            </div>
          </div>
        </div>
      </Col>
      <Col
        className="aboutMeCol aboutMeColMobileText d-flex d-lg-none gs_reveal gs_reveal_direction"
        xs={12}
        lg={6}
      >
        <div className="aboutMeContent">
          <div className="aboutMeTextWrap">
            <div>
              <div className="h3sWrap">
                <h3>распорядки</h3>
                <h3>суточные</h3>
                <h3>Онлайн-формат</h3>
              </div>
              <p>
                В нём ты найдёшь программу тренировок, узнаешь, чем я питаюсь,
                чтобы быть здоровой, и как с помощью йоги мне удаётся связать в
                единой целое моё тело и мой разум.
              </p>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AboutMeSection;
