import gsap, { Power0 } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
const imagesParallax = () => {
  gsap.registerPlugin(ScrollTrigger);

  window.addEventListener('load', (event) => {
    gsap.utils.toArray('.section-parallax img').forEach((section, i) => {
      // if (section.offsetHeight > 400) {
      //   gsap.set(section, {
      //     margin: '-2.5vw 0',
      //   });
      // }
      const heightDiff =
        section.offsetHeight - section.parentElement.offsetHeight;
      gsap.fromTo(
        section,
        {
          y: -heightDiff / 2,
        },
        {
          scrollTrigger: {
            trigger: section.parentElement,
            scrub: true,
            start: 'top 60%',
            end: 'bottom 30%',
            // markers: true,
            scroller: '[data-scroll-container]',
          },
          y: 0,
          ease: Power0.easeNone,
          immediateRender: false,
        }
      );
    });
  });
};

export default imagesParallax;
