import React from 'react';
import FooterEs from '../../../components/footer/FooterEs';
import Layout from '../../../layout/Layout';

export const Exclusion = () => {
  return (
    <Layout>
      <div className="RootLegals">
        <div className="first-section privacityContainer">
          <div className="container privacityContent">
            <div>
              <header>
                <h2>Exclusi&oacute;n de Responsabilidad</h2>
              </header>
              <div>
                <div>
                  <p>En 4 &aacute;mbitos:</p>
                  <ul>
                    <li>General</li>
                    <li>M&eacute;dico/Salud</li>
                    <li>Personal</li>
                    <li>De Resultados</li>
                  </ul>
                  <h4>General:</h4>
                  <p>
                    Al navegar por este sitio web, comprar nuestros cursos, usar
                    nuestra plataforma, correos electr&oacute;nicos, programas,
                    servicios y / o productos, usted acepta todas las partes de
                    esta exclusi&oacute;n de responsabilidad.
                  </p>
                  <p>
                    No nos hacemos responsables de la informaci&oacute;n que
                    solicite o reciba a trav&eacute;s de nuestro sitio web,
                    nuestra plataforma, correos electr&oacute;nicos, programas,
                    servicios y / o productos. En ning&uacute;n caso seremos
                    responsables por los da&ntilde;os directos o indirectos que
                    el uso o dependencia de este sitio web, nuestra plataforma,
                    correos electr&oacute;nicos, programas, servicios y / o
                    productos, le puedan causar. No nos haremos responsables de
                    ninguna p&eacute;rdida que pueda sufrir, tampoco nos hacemos
                    responsables de las lesiones personales, accidentes que
                    pueda sufrir por la realizaci&oacute;n de los ejercicios
                    propuestos.
                  </p>
                  <h4>M&eacute;dico/Salud:</h4>
                  <p>
                    SUPRIVE le recomienda que consulte con su m&eacute;dico
                    antes de comenzar cualquier programa de ejercicios a fin de
                    que le certifique que puede realizar los ejercicios que
                    pretende y que tiene la condici&oacute;n f&iacute;sica para
                    realizar los ejercicios. SUPRIVE, no dispone de un equipo
                    m&eacute;dico, por lo que no est&aacute; capacitado para
                    realizar ning&uacute;n tipo de diagn&oacute;stico, examen o
                    tratamiento de afecciones m&eacute;dicas de ning&uacute;n
                    tipo, o en la determinaci&oacute;n del efecto de cualquier
                    ejercicio espec&iacute;fico sobre una afecci&oacute;n
                    m&eacute;dica.
                  </p>
                  <p>
                    Usted asume que, al participar en cualquier ejercicio o
                    programa de ejercicios, existe la posibilidad de que pueda
                    sufrir lesiones f&iacute;sicas. Si participa en este
                    ejercicio o programa de ejercicios, lo hace bajo su entera
                    responsabilidad, asumiendo los riesgos por las lesiones que
                    pueda sufrir, eximiendo de toda responsabilidad a SUPRIVE.
                  </p>
                  <p>
                    La informaci&oacute;n que se recoge en la web es
                    gen&eacute;rica y en ning&uacute;n caso sustituye al consejo
                    de un profesional m&eacute;dico, no se pretende realizar un
                    diagn&oacute;stico o tratamiento. Nunca debe ignorar el
                    asesoramiento m&eacute;dico, ni retrasar su asesoramiento.
                  </p>
                  <p>
                    SUPRIVE no se hace responsable de ning&uacute;n consejo, ni
                    de ning&uacute;n diagnostico o tratamiento. Ante cualquier
                    duda debe consultar con su m&eacute;dico respecto a la
                    informaci&oacute;n contenida en esta web.
                  </p>
                  <p>
                    Recuerde que puede surgir riesgos para su salud, en la
                    ejecuci&oacute;n incorrecta de la t&eacute;cnica propuesta
                    por Katerina Safarova A. De Lorenzana, as&iacute; mismo
                    podr&iacute;a causarse lesiones si realiza los ejercicios de
                    forma incorrecta o realiza m&aacute;s repeticiones de las
                    recomendadas.
                  </p>
                  <p>
                    En caso de que no adecue el grado de esfuerzo a su capacidad
                    f&iacute;sica y no respete los tiempos y los d&iacute;as de
                    descanso podr&iacute;a causarle lesiones de las que no nos
                    hacemos responsables.
                  </p>
                  <p>
                    En ning&uacute;n caso debe utilizar m&aacute;s peso que el
                    recomendado y este debe estar adecuado a su situaci&oacute;n
                    f&iacute;sica de lo contrario podr&iacute;a causarle
                    lesiones.
                  </p>
                  <p>
                    En ning&uacute;n caso debe entrenar con una lesi&oacute;n
                    anterior por cuanto la misma podr&iacute;a agravarse y en
                    ning&uacute;n caso nos hacemos responsables.
                  </p>
                  <h4>Personal:</h4>
                  <p>
                    Katerina Safarova A. De Lorenzana no es un m&eacute;dico. La
                    informaci&oacute;n que proporciona se basa en su experiencia
                    personal.
                  </p>
                  <p>
                    La informaci&oacute;n contenida en web, nuestra plataforma,
                    nuestra plataformas de invitados, correos
                    electr&oacute;nicos, programas, servicios y / o productos es
                    solo para fines informativos, y est&aacute; disponible para
                    usted como herramientas para su propio uso. Si bien Katerina
                    Safarova A. De Lorenzana s&oacute;lo se basa en su
                    experiencia profesional, no en formaci&oacute;n cualificada
                    al respecto.
                  </p>
                  <p>
                    Recuerde que Katerina Safarova A. De Lorenzana no es una
                    profesional de la salud m&eacute;dica, ni un proveedor de
                    salud mental. Su &uacute;nico fin en servir como entrenadora
                    que le ayudan a alcanzar sus propios objetivos de salud y
                    bienestar.
                  </p>
                  <p>
                    Usted participa voluntariamente en el uso de nuestra web y
                    es el &uacute;nico responsable de sus resultados. Usted
                    asume la plena responsabilidad sobre su salud, vida y
                    bienestar.
                  </p>
                  <p>
                    En el caso de que utilice la informaci&oacute;n
                    proporcionada a trav&eacute;s de nuestro web, no asumimos
                    ninguna responsabilidad.
                  </p>
                  <p>
                    Katerina Safarova A. De Lorenzana hace todo lo posible para
                    garantizar la exactitud de la informaci&oacute;n publicada
                    en nuestra web sin embargo, la informaci&oacute;n puede
                    contener imprecisiones o errores tipogr&aacute;ficos.
                  </p>
                  <h4>Resultados:</h4>
                  <p>
                    Hacemos todo lo posible para garantizar los servicios
                    prestados, pero el resultado depende del desarrollo
                    muscular, de su condici&oacute;n f&iacute;sica sin perder de
                    vista que los resultados de p&eacute;rdida de grasa difieren
                    seg&uacute;n el individuo.
                  </p>
                  <p>
                    No garantizamos el nivel de &eacute;xito que puede
                    experimentar, y usted acepta que los resultados sean
                    diferentes para cada individuo. Los testimonios y ejemplos
                    proporcionados son resultados excepcionales, que pueden no
                    aplicase al comprador promedio, y no tienen la
                    intenci&oacute;n de representar o garantizar que alguien
                    lograr&aacute; los mismos resultados o resultados similares.
                  </p>
                  <p>
                    El &eacute;xito de la salud, el estado f&iacute;sico y la
                    nutrici&oacute;n de cada individuo depende de sus
                    antecedentes, dedicaci&oacute;n, deseo y motivaci&oacute;n.
                    Al igual que con cualquier programa o servicio relacionado
                    con la salud, sus resultados pueden variar y se
                    basar&aacute;n en muchas variables, que incluyen, entre
                    otras, su capacidad individual, experiencia de vida, perfil
                    gen&eacute;tico y de salud, punto de partida, experiencia y
                    nivel de compromiso.
                  </p>
                  <p>
                    No hay garant&iacute;a de que los ejemplos de resultados de
                    acondicionamiento f&iacute;sico, competencia, desarrollo
                    muscular y / o quema de grasa pasados puedan duplicarse en
                    el futuro. No podemos garantizar sus resultados futuros y /
                    o &eacute;xito. Tampoco podemos garantizarle que mantenga
                    los resultados que experimenta si no contin&uacute;a
                    siguiendo el programa.
                  </p>
                  <p>
                    El uso de nuestra informaci&oacute;n, productos y servicios
                    debe basarse en su propia diligencia y usted acepta que
                    nuestra empresa no es responsable del &eacute;xito o el
                    fracaso de su estado y resultados f&iacute;sicos.
                  </p>
                  <p>
                    Las experiencias que presentamos son basadas en personas
                    reales del mundo real solo con fines ilustrativos.
                  </p>
                  <p>
                    Los testimonios, ejemplos y fotos utilizados son de clientes
                    reales y resultados que lograron personalmente. Cada cliente
                    ha aprobado estos testimonios, ejemplos y fotos para usar en
                    materiales para hablar con nuestro programa, servicio y / o
                    capacidades del producto, pero no tienen la intenci&oacute;n
                    de representar o garantizar que los clientes actuales o
                    futuros obtendr&aacute;n los mismos resultados o similares.
                    Las historias de clientes representan lo que es posible con
                    nuestros programas, servicios y / o productos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterEs polygons={true} />
    </Layout>
  );
};

export default Exclusion;
