import React, { useContext } from 'react';
import { Row, Button, Col, ListGroup } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import brandLogo from '../../assets/imgs/home/heroTitle.svg';
import { ScrollContext } from '../../layout/Layout';
export const Footer = (props) => {
  const getScroll = useContext(ScrollContext);

  return (
    <Row className="RootFooter sectionPolygon sectionPolygonTopExterior">
      {props.polygons != null ? (
        ''
      ) : (
        <>
          <div className="sectionPolygon1 spb" />
          <div className="sectionPolygon2 spb" />
        </>
      )}
      <Row className="rowSection">
        <Col xs={12} lg={3} className="brandFooter footerCol">
          <div className="footerColWrapper">
            <img src={brandLogo} alt="" className="footerBrand" />
          </div>
        </Col>
        <Col xs={12} lg={5} className="brandFooter footerCol d-none d-lg-flex">
          <p className="footerFaceText">
            Этот веб-сайт не принадлежит Facebook или Meta Inc. и никоим
            образом не поддерживается Facebook. Facebook предоставляет платформу
            для размещения рекламы, но контент, предлагаемый на этом веб-сайте,
            не является представителем Facebook Inc.
            <br />
            <br />
            This website does not belong to Facebook or Meta Inc. and is not
            endorsed by Facebook in any way. Facebook provides a platform to
            offer advertising, but the content offered on this website is not
            representative of Facebook Inc.
          </p>
        </Col>
        <Col xs={12} lg={2} className="interestLinks footerCol">
          <ListGroup className="listGroupFooter">
            <ListGroup.Item className="listGroupItemFooter listGroupTitle footerTitle">
              РАЗДЕЛЫ
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter footerText listGroupText">
              <Button
                as="a"
                bsPrefix
                className="footerText"
                onClick={() => {
                  getScroll().scrollTo(document.querySelector('#info'), {
                    offset: -72,
                  });
                }}
              >
                какой метод
              </Button>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter footerText listGroupText">
              <Button
                as="a"
                bsPrefix
                className="footerText"
                onClick={() => {
                  getScroll().scrollTo(document.querySelector('#sliderInfo'), {
                    offset: -72,
                  });
                }}
              >
                Для кого этот курс
              </Button>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter footerText listGroupText">
              <Button
                as="a"
                bsPrefix
                className="footerText"
                onClick={() => {
                  getScroll().scrollTo(document.querySelector('#aboutMe'), {
                    offset: -72,
                  });
                }}
              >
                кто я
              </Button>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter footerText listGroupText">
              <Button
                as="a"
                bsPrefix
                className="footerText"
                onClick={() => {
                  getScroll().scrollTo(document.querySelector('#advantages'), {
                    offset: -72,
                  });
                }}
              >
                преимущества метода
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        {/* <Col xs={1}></Col> */}
        <Col xs={12} lg={2} className="footerCol">
          <ListGroup className="listGroupFooter">
            <ListGroup.Item className="listGroupItemFooter listGroupTitle footerTitle">
              Юридические тексты
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter footerText listGroupText">
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={(location) => ({
                  ...location,
                  pathname: '/ru/privacy-policy',
                })}
              >
                Политика конфиденциальности
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter footerText listGroupText">
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={(location) => ({
                  ...location,
                  pathname: '/ru/cookies-policy',
                })}
              >
                Политика использования файлов cookie
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter footerText listGroupText">
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={(location) => ({
                  ...location,
                  pathname: '/ru/terms-of-use',
                })}
              >
                Правовое предупреждение
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter footerText listGroupText">
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={(location) => ({
                  ...location,
                  pathname: '/ru/liability-exclusion',
                })}
              >
                исключение ответственности
              </Link>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col xs={12} lg={5} className="brandFooter footerCol d-flex d-lg-none">
          <p className="footerFaceText">
            Этот веб-сайт не принадлежит Facebook или Facebook Inc. и никоим
            образом не поддерживается Facebook. Facebook предоставляет платформу
            для размещения рекламы, но контент, предлагаемый на этом веб-сайте,
            не является представителем Facebook Inc.
            <br />
            <br />
            This website does not belong to Facebook or Facebook Inc. and is not
            endorsed by Facebook in any way. Facebook provides a platform to
            offer advertising, but the content offered on this website is not
            representative of Facebook Inc.
          </p>
        </Col>
        <Col xs={12} className="poweredSection">
          <p className="powered">
            Powered by{' '}
            <a
              href="https://suprive.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Suprive
            </a>
          </p>
        </Col>
      </Row>
    </Row>
  );
};
export default Footer;
