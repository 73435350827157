import React from 'react';
import { Table } from 'react-bootstrap';
import FooterEs from '../../../components/footer/FooterEs';
import Layout from '../../../layout/Layout';

export const PoliticaDeCookies = () => {
  return (
    <Layout>
      <div className="RootLegals">
        <div className="first-section cookiesContainer">
          <div className="container cookiesContent">
            <div>
              <header>
                <h2>Pol&iacute;tica de Cookies</h2>
              </header>
              <div>
                <div>
                  <p>
                    En esta web se utilizan cookies de terceros y propias para
                    conseguir que tengas una mejor experiencia de
                    navegaci&oacute;n, puedas compartir contenido en redes
                    sociales y para que podamos obtener estad&iacute;sticas de
                    los usuarios.
                  </p>
                  <p>
                    Puedes evitar la descarga de cookies a trav&eacute;s de la
                    configuraci&oacute;n de tu navegador, evitando que las
                    cookies se almacenen en su dispositivo.
                  </p>
                  <p>
                    Como propietario de este sitio web, te comunico que no
                    utilizamos ninguna informaci&oacute;n personal procedente de
                    cookies, tan s&oacute;lo realizamos estad&iacute;sticas
                    generales de visitas que no suponen ninguna
                    informaci&oacute;n personal.
                  </p>
                  <p>
                    Es muy importante que leas la presente pol&iacute;tica de
                    cookies y comprendas que, si contin&uacute;as navegando,
                    consideraremos que aceptas su uso.
                  </p>
                  <p>
                    Seg&uacute;n los t&eacute;rminos incluidos en el
                    art&iacute;culo 22.2 de la Ley 34/2002 de Servicios de la
                    Sociedad de la Informaci&oacute;n y Comercio
                    Electr&oacute;nico, si contin&uacute;as navegando,
                    estar&aacute;s prestando tu consentimiento para el empleo de
                    los referidos mecanismos.
                  </p>
                  <h4>Entidad Responsable</h4>
                  <p>
                    La entidad responsable de la recogida, procesamiento y
                    utilizaci&oacute;n de tus datos personales, en el sentido
                    establecido por la Ley de Protecci&oacute;n de Datos
                    Personales es la p&aacute;gina https://katsafarova.com/,
                    propiedad de Suprive Media SL &ndash; Av. Carles III, 124
                    Entr. 1era, 08034, Barcelona, Espa&ntilde;a.
                  </p>
                  <h4>&iquest;Qu&eacute; son las cookies?</h4>
                  <p>
                    Las cookies son un conjunto de datos que un servidor
                    deposita en el navegador del usuario para recoger la
                    informaci&oacute;n de registro est&aacute;ndar de Internet y
                    la informaci&oacute;n del comportamiento de los visitantes
                    en un sitio web. Es decir, se trata de peque&ntilde;os
                    archivos de texto que quedan almacenados en el disco duro
                    del ordenador y que sirven para identificar al usuario
                    cuando se conecta nuevamente al sitio web. Su objetivo es
                    registrar la visita del usuario y guardar cierta
                    informaci&oacute;n. Su uso es com&uacute;n y frecuente en la
                    web ya que permite a las p&aacute;ginas funcionar de manera
                    m&aacute;s eficiente y conseguir una mayor
                    personalizaci&oacute;n y an&aacute;lisis sobre el
                    comportamiento del usuario.
                  </p>
                  <h4>&iquest;Qu&eacute; tipos de cookies existen?</h4>
                  <p>
                    Las cookies utilizadas en nuestro sitio web, son de
                    sesi&oacute;n y de terceros, y nos permiten almacenar y
                    acceder a informaci&oacute;n relativa al idioma, el tipo de
                    navegador utilizado, y otras caracter&iacute;sticas
                    generales predefinidas por el usuario, as&iacute; como,
                    seguir y analizar la actividad que lleva a cabo, con el
                    objeto de introducir mejoras y prestar nuestros servicios de
                    una manera m&aacute;s eficiente y personalizada.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    Las cookies, en funci&oacute;n de su permanencia, pueden
                    dividirse en cookies de sesi&oacute;n o permanentes. Las que
                    expiran cuando el usuario cierra el navegador. Las que
                    expiran en funci&oacute;n de cuando se cumpla el objetivo
                    para el que sirven (por ejemplo, para que el usuario se
                    mantenga identificado en los servicios de Suprive Media SL)
                    o bien cuando se borran manualmente.
                  </p>
                  <Table striped bordered hover>
                    <tbody>
                      <tr>
                        <td>
                          <p>Nombre</p>
                        </td>
                        <td>
                          <p>Tipo</p>
                        </td>
                        <td>
                          <p>Caducidad</p>
                        </td>
                        <td>
                          <p>Finalidad</p>
                        </td>
                        <td>
                          <p>Clase</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>__utma</p>
                        </td>
                        <td>
                          <p>De Terceros (Google Analytics)</p>
                        </td>
                        <td>
                          <p>2 a&ntilde;os</p>
                        </td>
                        <td>
                          <p>Se usa para distinguir usuarios y sesiones.</p>
                        </td>
                        <td>
                          <p>No Exenta</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>__utmb</p>
                        </td>
                        <td>
                          <p>De Terceros (Google Analytics)</p>
                        </td>
                        <td>
                          <p>30 minutos</p>
                        </td>
                        <td>
                          <p>
                            Se usa para determinar nuevas sesiones o visitas
                          </p>
                        </td>
                        <td>
                          <p>No Exenta</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>__utmc</p>
                        </td>
                        <td>
                          <p>De Terceros (Google Analytics)</p>
                        </td>
                        <td>
                          <p>Al finalizar la sesi&oacute;n</p>
                        </td>
                        <td>
                          <p>Se configura para su uso con Urchin</p>
                        </td>
                        <td>
                          <p>No Exenta</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>__utmz</p>
                        </td>
                        <td>
                          <p>De Terceros (Google Analytics)</p>
                        </td>
                        <td>
                          <p>6 meses</p>
                        </td>
                        <td>
                          <p>
                            Almacena el origen o la campa&ntilde;a que explica
                            c&oacute;mo el usuario ha llegado hasta la
                            p&aacute;gina web
                          </p>
                        </td>
                        <td>
                          <p>No Exenta</p>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <p>
                    Adicionalmente, en funci&oacute;n de su objetivo, las
                    cookies pueden clasificarse de la siguiente forma:
                  </p>
                  <h4>Cookies de rendimiento</h4>
                  <p>
                    Este tipo de Cookie recuerda sus preferencias para las
                    herramientas que se encuentran en los servicios, por lo que
                    no tiene que volver a configurar el servicio cada vez que
                    usted visita. A modo de ejemplo, en esta tipolog&iacute;a se
                    incluyen: Ajustes de volumen de reproductores de
                    v&iacute;deo o sonido. Las velocidades de transmisi&oacute;n
                    de v&iacute;deo que sean compatibles con su navegador. Los
                    objetos guardados en el &ldquo;carrito de la compra&rdquo;
                    en los servicios de e-commerce tales como tiendas.
                  </p>
                  <h4>Cookies de geo-localizaci&oacute;n</h4>
                  <p>
                    Estas cookies son utilizadas para averiguar en qu&eacute;
                    pa&iacute;s se encuentra cuando se solicita un servicio.
                    Esta cookie es totalmente an&oacute;nima, y s&oacute;lo se
                    utiliza para ayudar a orientar el contenido a su
                    ubicaci&oacute;n.
                  </p>
                  <h4>Cookies de registro</h4>
                  <p>
                    Las cookies de registro se generan una vez que el usuario se
                    ha registrado o posteriormente ha abierto su sesi&oacute;n,
                    y se utilizan para identificarle en los servicios con los
                    siguientes objetivos:
                  </p>
                  <p>
                    Mantener al usuario identificado de forma que, si cierra un
                    servicio, el navegador o el ordenador y en otro momento u
                    otro d&iacute;a vuelve a entrar en dicho servicio,
                    seguir&aacute; identificado, facilitando as&iacute; su
                    navegaci&oacute;n sin tener que volver a identificarse. Esta
                    funcionalidad se puede suprimir si el usuario pulsa la
                    funcionalidad [cerrar sesi&oacute;n], de forma que esta
                    cookie se elimina y la pr&oacute;xima vez que entre en el
                    servicio el usuario tendr&aacute; que iniciar sesi&oacute;n
                    para estar identificado.
                  </p>
                  <p>
                    Comprobar si el usuario est&aacute; autorizado para acceder
                    a ciertos servicios, por ejemplo, para participar en un
                    concurso.
                  </p>
                  <p>
                    Adicionalmente, algunos servicios pueden utilizar conectores
                    con redes sociales tales como Facebook o Twitter. Cuando el
                    usuario se registra en un servicio con credenciales de una
                    red social, autoriza a la red social a guardar una Cookie
                    persistente que recuerda su identidad y le garantiza acceso
                    a los servicios hasta que expira. El usuario puede borrar
                    esta Cookie y revocar el acceso a los servicios mediante
                    redes sociales actualizando sus preferencias en la red
                    social que espec&iacute;fica.
                  </p>
                  <h4>Cookies de anal&iacute;ticas</h4>
                  <p>
                    Cada vez que un usuario visita un servicio, una herramienta
                    de un proveedor externo genera una cookie anal&iacute;tica
                    en el ordenador del usuario. Esta cookie que s&oacute;lo se
                    genera en la visita, servir&aacute; en pr&oacute;ximas
                    visitas a los servicios de Suprive Media SL para identificar
                    de forma an&oacute;nima al visitante. Los objetivos
                    principales que se persiguen son:
                  </p>
                  <p>
                    Permitir la identificaci&oacute;n an&oacute;nima de los
                    usuarios navegantes a trav&eacute;s de la cookie (identifica
                    navegadores y dispositivos, no personas) y por lo tanto la
                    contabilizaci&oacute;n aproximada del n&uacute;mero de
                    visitantes y su tendencia en el tiempo.
                  </p>
                  <p>
                    Identificar de forma an&oacute;nima los contenidos
                    m&aacute;s visitados y por lo tanto m&aacute;s atractivos
                    para los usuarios Saber si el usuario que est&aacute;
                    accediendo es nuevo o repite visita.
                  </p>
                  <p>
                    Importante: Salvo que el usuario decida registrarse en un
                    servicio de Suprive Media SL, la cookie nunca ir&aacute;
                    asociada a ning&uacute;n dato de car&aacute;cter personal
                    que pueda identificarle. Dichas cookies s&oacute;lo
                    ser&aacute;n utilizadas con prop&oacute;sitos
                    estad&iacute;sticos que ayuden a la optimizaci&oacute;n de
                    la experiencia de los usuarios en el sitio.
                  </p>
                  <h4>Cookies de publicidad</h4>
                  <p>
                    Este tipo de cookies permiten ampliar la informaci&oacute;n
                    de los anuncios mostrados a cada usuario an&oacute;nimo en
                    los servicios de Suprive Media SL. Entre otros, se almacena
                    la duraci&oacute;n o frecuencia de visualizaci&oacute;n de
                    posiciones publicitarias, la interacci&oacute;n con las
                    mismas, o los patrones de navegaci&oacute;n y/o
                    comportamientos del usuario ya que ayudan a conformar un
                    perfil de inter&eacute;s publicitario. De este modo,
                    permiten ofrecer publicidad af&iacute;n a los intereses del
                    usuario.
                  </p>
                  <h4>Cookies publicitarias de terceros</h4>
                  <p>
                    Adem&aacute;s de la publicidad gestionada por las webs de
                    Suprive Media SL en sus servicios, las webs de Suprive Media
                    SL ofrecen a sus anunciantes la opci&oacute;n de servir
                    anuncios a trav&eacute;s de terceros
                    (&ldquo;Ad-Servers&rdquo;). De este modo, estos terceros
                    pueden almacenar cookies enviadas desde los servicios de
                    Suprive Media SL procedentes de los navegadores de los
                    usuarios, as&iacute; como acceder a los datos que en ellas
                    se guardan.
                  </p>
                  <p>
                    Las empresas que generan estas cookies tienen sus propias
                    pol&iacute;ticas de privacidad. En la actualidad, las webs
                    de Suprive Media SL utilizan la plataforma Doubleclick
                    (Google) para gestionar estos servicios. Para m&aacute;s
                    informaci&oacute;n, acuda a
                  </p>
                  <p>
                    <a href="http://www.google.es/policies/privacy/ads/#toc-doubleclick">
                      http://www.google.es/policies/privacy/ads/#toc-doubleclick{' '}
                    </a>
                    y a{' '}
                    <a href="http://www.google.es/policies/privacy/ads/">
                      http://www.google.es/policies/privacy/ads/
                    </a>
                    .
                  </p>
                  <h4>
                    &iquest;C&oacute;mo puedo deshabilitar las cookies en mi
                    navegador?
                  </h4>
                  <p>
                    Se pueden configurar los diferentes navegadores para avisar
                    al usuario de la recepci&oacute;n de cookies y, si se desea,
                    impedir su instalaci&oacute;n en el equipo. Asimismo, el
                    usuario puede revisar en su navegador qu&eacute; cookies
                    tiene instaladas y cu&aacute;l es el plazo de caducidad de
                    las mismas, pudiendo eliminarlas.
                  </p>
                  <p>
                    Para ampliar esta informaci&oacute;n consulte las
                    instrucciones y manuales de su navegador:
                  </p>
                  <p>
                    Para m&aacute;s informaci&oacute;n sobre la
                    administraci&oacute;n de las cookies en Google Chrome:
                    <br />
                    <a href="https://support.google.com/chrome/answer/95647?hl=es">
                      https://support.google.com/chrome/answer/95647?hl=es
                    </a>
                  </p>
                  <p>
                    Para m&aacute;s informaci&oacute;n sobre la
                    administraci&oacute;n de las cookies en Internet Explorer:
                    <br />
                    <a href="http://windows.microsoft.com/es-es/windows-vista/cookies-frequently-asked-questions">
                      http://windows.microsoft.com/es-es/windows-vista/cookies-frequently-asked-questions
                    </a>
                  </p>
                  <p>
                    Para m&aacute;s informaci&oacute;n sobre la
                    administraci&oacute;n de las cookies en Mozilla Firefox:
                    <br />
                    <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we%20">
                      http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we
                    </a>
                  </p>
                  <p>
                    Para m&aacute;s informaci&oacute;n sobre la
                    administraci&oacute;n de las cookies en Safari:
                    <br />
                    <a href="http://www.apple.com/es/privacy/use-of-cookies/%20">
                      http://www.apple.com/es/privacy/use-of-cookies/
                    </a>
                  </p>
                  <p>
                    Para m&aacute;s informaci&oacute;n sobre la
                    administraci&oacute;n de las cookies en Opera:
                    <br />
                    <a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">
                      http://help.opera.com/Windows/11.50/es-ES/cookies.html
                    </a>
                  </p>
                  <p>
                    Si desea dejar de ser seguido por Google Analytics visite:
                    <br />
                    <a href="http://tools.google.com/dlpage/gaoptout">
                      http://tools.google.com/dlpage/gaoptout
                    </a>
                  </p>
                  <h4>Para saber m&aacute;s sobre las cookies</h4>
                  <p>
                    Puede obtener m&aacute;s informaci&oacute;n sobre la
                    publicidad online basada en el comportamiento y la
                    privacidad online en el siguiente enlace:
                    <br />
                    <a href="http://www.youronlinechoices.com/es/">
                      http://www.youronlinechoices.com/es/
                    </a>
                  </p>
                  <p>
                    Protecci&oacute;n de datos de Google Analytics:
                    <br />
                    <a href="http://www.google.com/analytics/learn/privacy.html">
                      http://www.google.com/analytics/learn/privacy.html
                    </a>
                  </p>
                  <p>
                    C&oacute;mo usa Google Analytics las cookies:
                    <br />
                    <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es#analyticsjs">
                      https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es#analyticsjs
                    </a>
                  </p>
                  <h4>
                    Actualizaciones y cambios en la pol&iacute;tica de
                    privacidad/cookies
                  </h4>
                  <p>
                    Las webs de Suprive Media SL pueden modificar esta
                    Pol&iacute;tica de Cookies en funci&oacute;n de exigencias
                    legislativas, reglamentarias, o con la finalidad de adaptar
                    dicha pol&iacute;tica a las instrucciones dictadas por la
                    Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos, por
                    ello se aconseja a los usuarios que la visiten
                    peri&oacute;dicamente.
                  </p>
                  <p>
                    Cuando se produzcan cambios significativos en esta
                    Pol&iacute;tica de Cookies, estos se comunicar&aacute;n a
                    los usuarios bien mediante la web o a trav&eacute;s de
                    correo electr&oacute;nico a los usuarios registrados.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterEs polygons={true} />
    </Layout>
  );
};

export default PoliticaDeCookies;
