import React from 'react';

export const CustomPrevArrow = (props) => {
  const { className, onClick, sliderArrowMouse } = props;
  return (
    <div className={`${className} ${sliderArrowMouse}`} onClick={onClick}>
      {/* <FontAwesomeIcon icon={faArrowLeft} /> */}
      <div className="slickPrev link" onClick={onClick}>
        <svg
          width="18"
          height="39"
          viewBox="0 0 18 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16 1L2 19.2357L16 38" stroke="#F3F3F3" strokeWidth="3" />
        </svg>
      </div>
    </div>
  );
};

export default CustomPrevArrow;
