import React from 'react';
import { Row } from 'react-bootstrap';
import SliderInfoSection from '../../sections_Ads/sliderInfoSection/SliderInfoSection';

const SliderInfo = () => {
  return (
    <Row
      className="RootSliderInfo RootContainers sectionsPadding sectionPolygon sectionPolygonBottomExtern"
      id="sliderInfo"
    >
      <div className="sectionPolygon1 spb" />
      <div className="sectionPolygon2 spb" />
      <SliderInfoSection />
    </Row>
  );
};

export default SliderInfo;
