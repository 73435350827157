import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

const aniamtionsOnScroll = (isMobile) => {
  function animateFrom(elem, direction) {
    direction = direction || 1;
    var x = 0,
      y = 0,
      delay = 0,
      scale = 1;
    if (elem.classList.contains('gs_reveal_fromLeft')) {
      x = -100;
      y = 0;
    } else if (elem.classList.contains('gs_reveal_fromRight')) {
      x = 100;
      y = 0;
    } else if (elem.classList.contains('gs_reveal_title')) {
      x = 0;
      y = 200;
    }
    if (elem.classList.contains('gs_reveal_direction')) {
      y = direction * 100;
    }
    if (elem.classList.contains('gs_reveal_delay1')) {
      delay = 0.8;
    } else if (elem.classList.contains('gs_reveal_delay2')) {
      delay = 1.1;
    } else if (elem.classList.contains('gs_reveal_delay3')) {
      delay = 1.3;
    }
    if (elem.classList.contains('gs_reveal_scale')) {
      scale = 0;
    }
    elem.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
    // if (!isMobile()) {
    elem.style.opacity = '0';
    // }
    // if (isMobile()) {
    //   gsap.to(elem, {
    //     delay: delay,
    //     scale: 1,
    //     duration: 1.25,
    //     x: 0,
    //     y: 0,
    //     autoAlpha: 1,
    //     ease: 'expo',
    //     overwrite: 'auto',
    //   });
    // } else {
    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0, scale: scale },
      {
        delay: delay,
        scale: 1,
        duration: 1.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: 'expo',
        overwrite: 'auto',
      }
    );
    // }
  }

  function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 });
  }

  document.addEventListener('DOMContentLoaded', function () {
    // gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray('.gs_reveal').forEach(function (elem) {
      hide(elem); // assure that the element is hidden when scrolled into view
      ScrollTrigger.create({
        trigger: elem,
        scroller: '[data-scroll-container]',
        // start: 'top 100%',
        // end: 'bottom 100%',
        // markers: true,
        onEnter: function () {
          animateFrom(elem);
        },
        onLeaveBack: function () {
          // if (!isMobile()) {
          hide(elem);
          // }
        }, // assure that the element is hidden when scrolled into view
        // onEnterBack: function () {
        //   if (!isMobile()) {
        //     animateFrom(elem, -1);
        //   }
        // },
        // onLeave: function () {
        //   if (!isMobile()) {
        //     hide(elem);
        //   }
        // }, // assure that the element is hidden when scrolled into view
      });
    });
  });
};

export default aniamtionsOnScroll;
