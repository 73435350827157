import React from "react";
import CountDownPromo from "./CountDownPromo";
import { isBlackFriday } from "../../layout/Layout"
import party from "../../assets/imgs/blackfriday/party.png";

// PROMO STYLE
import "../../assets/sass/promo/_promo.scss";

const DeadLinePromo = ({ lang }) => {
   return (
      <div className="deadLineFunnelCustom">
         <div className="deadLineWrapper">
            <h4 className={(isBlackFriday) ? '' : 'homeTextGolden' } style={{fontSize: (isBlackFriday) ? '1.7em' : '', color: (isBlackFriday) ? '#fff' : ''}}>
               <img src={party}  style={{display: (isBlackFriday) ? '' : 'none'}} width="20" hspace="6" />
               {lang === "es" && !isBlackFriday && <>QUEDAN...</>}
               {lang === "ru" && !isBlackFriday && <>ОСТАЛОСЬ...</>}
               {lang === "es" && isBlackFriday && <>OFERTA BLACK FRIDAY...</>}
               {lang === "ru" && isBlackFriday && <>ПРЕДЛОЖЕНИЕ НА ЧЁРНУЮ ПЯТНИЦУ...</>}
            </h4>
            <CountDownPromo lang={lang} />
         </div>
      </div>
   );
};

export default DeadLinePromo;
