import React from 'react';
import { Row } from 'react-bootstrap';
import HomeSection from '../../sections_Ads/homeSection/HomeSection';
import bgImg from '../../../../assets/imgs/home/hero.mp4';
import bgImgBF from '../../../../assets/imgs/home/blackfriday_ru.mp4';
import heroPoster from '../../../../assets/imgs/home/heroPoster2.jpg';
import { isPromoActive, isBlackFriday } from '../../../../layout/Layout';

export const Home = () => {
  return (
    <Row
      className={
        isPromoActive()
          ? 'RootHome RootHomePromo RootContainers sectionPolygon sectionPolygonBottomExtern'
          : 'RootHome RootContainers sectionPolygon sectionPolygonBottomExtern'
      }
    >
      <div className="sectionPolygon1 spb" />
      <div className="sectionPolygon2 spb" />
      <HomeSection />
      <video
        className="lazy bgImg"
        autoPlay
        loop
        muted
        playsInline
        poster={heroPoster}
      >
        {/* <source src={bgLoopWebm} type="video/webm" /> */}
        <source data-src={(isPromoActive() && isBlackFriday) ? bgImgBF : bgImg} type="video/mp4" />
      </video>
      {/* <img src={bgImg} alt="" className="bgImg" /> */}
    </Row>
  );
};

export default Home;
