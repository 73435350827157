const checkPaths = (path) => {
  let pathSplit = path.split('/').filter((el) => el != '');
  if (pathSplit.length == 1) {
    return {
      status: false,
      path: pathSplit[0],
    };
  } else {
    return {
      status: true,
      path: pathSplit[0],
    };
  }
};

export default checkPaths;
