import React, { useContext } from "react";
import { Button, Card, CardDeck, Col, Row } from "react-bootstrap";

import sliderImg1 from "../../../../assets/imgs/modules/1.jpg";
import gif1 from "../../../../assets/imgs/modules/gif1.mp4";
import gif1Webm from "../../../../assets/imgs/modules/gif1.webm";
import sliderImg2 from "../../../../assets/imgs/modules/2.jpg";
import gif2 from "../../../../assets/imgs/modules/gif2.mp4";
import gif2Webm from "../../../../assets/imgs/modules/gif2.webm";
import sliderImg3 from "../../../../assets/imgs/modules/3.jpg";
import gif3 from "../../../../assets/imgs/modules/gif3.mp4";
import gif3Webm from "../../../../assets/imgs/modules/gif3.webm";
import { ScrollContext } from "../../../../layout/Layout";

import img01 from "../../../../assets/imgs/home/numbers/01.jpg";
import img02 from "../../../../assets/imgs/home/numbers/02.jpg";
import img03 from "../../../../assets/imgs/home/numbers/03.jpg";

const ModulesSection = () => {
   const getScroll = useContext(ScrollContext);

   return (
      <Row className="rowSection">
         <Row className="modulesRow modulesRowTitle">
            <Col xs={12} className="modulesCol gs_reveal gs_reveal_direction">
               <h2>
                  «Три кита» <span className="featuredText">курса</span>
               </h2>
               <h3>Кати Сафаровой</h3>
            </Col>
         </Row>
         <Row className="modulesRow modulesRowSlider">
            <Col xs={12} className="modulesCol gs_reveal gs_reveal_direction">
               <CardDeck>
                  <Card className="cardStyle cardConfig2">
                     <div>
                        <Card.Header className="cardHeader triangleTopRight">
                           <div>
                              <video className="lazy modulesGif" autoPlay loop muted playsInline poster={sliderImg1}>
                                 <source data-src={gif1Webm} type="video/webm" />
                                 <source data-src={gif1} type="video/mp4" />
                              </video>
                              {/* <img src={sliderImg1} alt="" className="modulesGif" /> */}
                           </div>
                        </Card.Header>
                        <Card.Body className="cardBody">
                           <div className="cardBodyWrap">
                              {/*<span className="numbersStroke numbersStroke1">01</span>*/}
                              <img src={img01} className="img-fluid" alt="01" />
                              <h5 className="cardTitle">
                                 Комплекс упражнений
                                 <br />
                                 от Кати Сафаровой
                              </h5>
                              <p className="cardText">Мой «секретный» комплекс упражнений для тренировки всего тела, который поможет тебе достичь видимых результатов крайне быстро.</p>
                           </div>
                        </Card.Body>
                     </div>
                  </Card>

                  <Card className="cardStyle cardConfig2">
                     <div>
                        <Card.Header className="cardHeader triangleTopRight">
                           <div className="section-parallax">
                              <video className="lazy modulesGif" autoPlay loop muted playsInline poster={sliderImg2}>
                                 <source data-src={gif2Webm} type="video/webm" />
                                 <source data-src={gif2} type="video/mp4" />
                              </video>
                           </div>
                        </Card.Header>
                        <Card.Body className="cardBody">
                           <div className="cardBodyWrap">
                              {/*<span className="numbersStroke numbersStroke2">02</span>*/}
                              <img src={img02} className="img-fluid" alt="02" />
                              <h5 className="cardTitle">Какие продукты следует есть, чтобы быть в форме и чувствовать себя здоровой</h5>
                              <p className="cardText">Ты научишься правильно питаться, не прибегая к разным сомнительным диетам не испытывая голода.</p>
                           </div>
                        </Card.Body>
                     </div>
                  </Card>

                  <Card className="cardStyle cardConfig2">
                     <div>
                        <Card.Header className="cardHeader triangleTopRight">
                           <div className="section-parallax">
                              <video className="lazy modulesGif" autoPlay loop muted playsInline poster={sliderImg3}>
                                 <source data-src={gif3Webm} type="video/webm" />
                                 <source data-src={gif3} type="video/mp4" />
                              </video>
                           </div>
                        </Card.Header>
                        <Card.Body className="cardBody">
                           <div className="cardBodyWrap">
                              {/*<span className="numbersStroke numbersStroke3">03</span>*/}
                              <img src={img03} className="img-fluid" alt="03" />
                              <h5 className="cardTitle">
                                 Пошаговое знакомство
                                 <br />с йогой
                              </h5>
                              <p className="cardText">Ты будешь поддерживать свое внутреннее спокойствие и сможешь приобрести здоровые привычки, с помощью которых ты за короткое время заметишь изменения.</p>
                           </div>
                        </Card.Body>
                     </div>
                  </Card>
               </CardDeck>
            </Col>
         </Row>
         <Row className="modulesRow modulesRowButton">
            <Col xs={12} className="modulesCol gs_reveal gs_reveal_scale">
               <Button
                  bsPrefix
                  className="buttonStyle buttonText button--calypso button--calypso-primary"
                  onClick={() => {
                     getScroll().scrollTo(document.querySelector("#payment"), {
                        offset: -72,
                     });
                  }}
               >
                  <span>НАЧАТЬ ТРАНСФОРМАЦИЮ</span>
               </Button>
            </Col>
         </Row>
      </Row>
   );
};

export default ModulesSection;
