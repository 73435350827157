import React, { useContext, useState } from 'react';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import CustomToggle from './components/CustomToggle';
import textBackground from '../../../../assets/imgs/icons/textBackgroundSecondary.svg';
import { ScrollContext } from '../../../../layout/Layout';

const FaqSection = () => {
  const [activeEventKey, setActiveEventKey] = useState('0');
  const getScroll = useContext(ScrollContext);

  const updateActiveEventKey = (newEventKey) => {
    setActiveEventKey(newEventKey);
  };
  return (
    <Row className="rowSection" id="faq">
      <Col xs={12} lg={12} className="faqCol faqColTitle">
        <h2 className="textBackgroundWrap gs_reveal gs_reveal_fromLeft">
          <img src={textBackground} alt="" className="textBackground" />
          Часто задаваемые <br className="d-block d-lg-none" />
          вопросы
        </h2>
      </Col>
      <Accordion defaultActiveKey="0" bsPrefix className="accordionFaq row">
        <Col xs={12} lg={6} className="faqCol">
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="0"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={'Если мне не нравится курс?'} /* TITLE */
            />
            <Accordion.Collapse eventKey="0">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Если по каким-то причинам ты останешься недовольна курсом
                    или увидишь, что он тебе не подходит, то в течение 15 дней
                    ты можешь потребовать возврата денег. Напоминаем, что
                    потребовать возврат денежных средств можно лишь в том
                    случае, если Вы просмотрели не более 20% от всех материалов
                    курса.
                    <br />
                    <a href="mailto:dudascursokat@suprive.com">
                      dudascursokat@suprive.com
                    </a>
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="1"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={'Как я могу зайти на онлайн-курс?'} /* TITLE */
            />
            <Accordion.Collapse eventKey="1">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Чтобы зайти на курс, тебе надо нажать на «Мои курсы» в
                    правом верхнем углу. И ты окажешься на странице, где
                    необходимо будет ввести имя пользователя и пароль.
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          {/* <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="2"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={'Курс содержит информацию о питании?'} 
            />
            <Accordion.Collapse eventKey="2">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Да, в курсе есть целый раздел, посвящённый питанию.
                    Используя список полезных продуктов, рекомендованных моим
                    нутрициологом, ты сможешь сама составлять меню по своему
                    желанию и ориентируясь на свои потребности.
                  </p>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card> */}
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="3"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={
                'Могу ли я просматривать видео несколько раз?'
              } /* TITLE */
            />
            <Accordion.Collapse eventKey="3">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Да, у тебя будет неограниченный доступ ко всему содержанию
                    курса. Ты сможешь просматривать курс столько раз, сколько
                    тебе это будет необходимо.
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="4"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={
                'Могу ли я поделиться курсом Кати с моими подругами?'
              } /* TITLE */
            />
            <Accordion.Collapse eventKey="4">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    К сожалению, нет. Данный курс предназначен только для
                    индивидульного использования. Запрещено делиться курсом,
                    распространять его или использовать в коммерческих целях. В
                    противном случае мы вынуждены будем вынуждены применить
                    санкции к твоему счёту.
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="9"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={'Могу ли я заниматься дома?'} /* TITLE */
            />
            <Accordion.Collapse eventKey="9">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Да, можешь. Курс выстроен таким образом, что ты можешь
                    заниматься как в спортивном зале, так и дома.
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Col>
        <Col xs={12} lg={6} className="faqCol">
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="5"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={
                'Что мне понадобится, чтобы пройти курс и увидеть результаты?'
              } /* TITLE */
            />
            <Accordion.Collapse eventKey="5">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Всё, что тебе необходимо для доступа к курсу, - это
                    мобильный телефон или компьютер и выход в интернет. Кроме
                    того, ты можешь заниматься и дома, и на свежем воздухе, и в
                    спортзале.
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="6"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={'Как можно оплатить курс?'} /* TITLE */
            />
            <Accordion.Collapse eventKey="6">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Курс можно оплатить кредитной или дебетовой картой VISA,
                    MASTERCARD, AMERICAN EXPRESS или через PAYPAL.
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="7"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={'Курс оплачивается только один раз?'} /* TITLE */
            />
            <Accordion.Collapse eventKey="7">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Да, ты оплачиваешь онлайн-курс один раз и получаешь
                    пожизненный доступ к материалам курса.
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="8"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={
                'После того как я оплачу курс, я получу пожизненный доступ к материалам курса?'
              } /* TITLE */
            />
            <Accordion.Collapse eventKey="8">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Да, оплатив курс один раз, он будет доступен для тебя в
                    течение всей жизни.
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="10"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={'Есть ли доступ к курсу в моей стране?'} /* TITLE */
            />
            <Accordion.Collapse eventKey="10">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Да, ведь речь идёт об онлайн-курсе, доступ к которому ты
                    получаешь через интернет. Так что курс доступен во всех
                    странах мира.
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_scale">
            <Button
              bsPrefix
              className="buttonStyle buttonText button--calypso button--calypso-primary"
              onClick={() => {
                getScroll().scrollTo(document.querySelector('#payment'), {
                  offset: -72,
                });
              }}
            >
              <span>НАЧАТЬ ТРАНСФОРМАЦИЮ</span>
            </Button>
          </Card>
        </Col>
      </Accordion>
      {/* <Col xs={12} className="faqColBtn">
        <Button
          bsPrefix
          className="buttonStyle buttonText button--calypso button--calypso-primary"
          onClick={() => {
            getScroll().scrollTo(document.querySelector('#payment'), {
              offset: -72,
            });
          }}
        >
          <span>НАЧАТЬ ТРАНСФОРМАЦИЮ</span>
        </Button>
      </Col> */}
    </Row>
  );
};

export default FaqSection;
