import React, { useContext, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import heroTitle from "../../../../assets/imgs/home/heroTitleEs.svg";
import downArrow from "../../../../assets/imgs/icons/downArrow.svg";
import gsap from "gsap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { PlayerTogglerContext, ScrollContext, isPromoActive, isBlackFriday } from "../../../../layout/Layout";
import logoBGes from '../../../../assets/imgs/blackfriday/logoBGes.png';

const HomeSection = () => {
   const getScroll = useContext(ScrollContext);
   const PlayerToggler = useContext(PlayerTogglerContext);

   useEffect(() => {
      gsap.fromTo(
         ".downArrow",
         {
            y: 0,
         },
         {
            y: -30,
            duration: 2,
            ease: "easeInOut",
            yoyo: true,
            repeat: -1,
         }
      );
   }, []);
   return (
      <Row className="rowSection">
         <Col xs={12} lg={6} className="homeCol homeColTitle gs_reveal gs_reveal_fromLeft">
            <div className="homeTitleDivWrap">
               {/* <h3 className="titleHero">KAT SAFAROVA</h3>
          <h1>метод</h1> */}
               <div>
                  <img src={(isPromoActive() && isBlackFriday) ? logoBGes : heroTitle} alt="" className="homeTitle" />
               </div>
            </div>
         </Col>
         <Col xs={12} lg={6} className="row align-items-center justify-content-center">
            <div className="col-12 mb-4" style={{display: (isPromoActive() && isBlackFriday) ? 'none' : ''}}>
               <h3 className="text-uppercase homeTextGolden">Actualización 2.0</h3>
               <h4 className="text-uppercase text-white">Oferta Flash 72H + Descargables</h4>
            </div>

            <div className="buttonGroupWrap gs_reveal gs_reveal_fromRight col-12">
               <Button
                  bsPrefix
                  className="buttonStyle buttonText button--calypso button--calypso-primary mr-2"
                  onClick={() => {
                     getScroll().scrollTo(document.querySelector("#payment"), {
                        offset: -72,
                     });
                  }}
               >
                  <span>
                     INSCRIBETE A UN
                     <br />
                     PRECIO EXCLUSIVO
                  </span>
               </Button>
               <Button
                  bsPrefix
                  className="buttonStyle buttonText button--calypso button--calypso-secondary ml-2"
                  onClick={() => {
                     PlayerToggler.setToggler({
                        opened: !PlayerToggler.toggler.opened,
                        src: "https://player.vimeo.com/video/568819547?autoplay=1",
                     });
                  }}
               >
                  <FontAwesomeIcon icon={faPlay} />
                  <span>VER TRAILER</span>
               </Button>
            </div>
         </Col>
         <div className="downArrowWrap">
            <img
               src={downArrow}
               alt=""
               className="downArrow"
               onClick={() => {
                  getScroll().scrollTo(document.querySelector("#aboutMe"));
               }}
            />
         </div>
      </Row>
   );
};

export default HomeSection;
