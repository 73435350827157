import React, { useContext } from 'react';
import { Button, Card, CardDeck, Col, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import CustomPrevArrow from '../../../../components/customSlickArrows/CustomArrowsSlickLeft';
import CustomNextArrow from '../../../../components/customSlickArrows/CustomArrowsSlickRight';
import sliderImg1 from '../../../../assets/imgs/modules/1.jpg';
import sliderImg2 from '../../../../assets/imgs/modules/2.jpg';
import sliderImg3 from '../../../../assets/imgs/modules/3.jpg';
import { ScrollContext } from '../../../../layout/Layout';

const ModulesSection = () => {
  const getScroll = useContext(ScrollContext);

  return (
    <Row className="rowSection">
      <Row className="modulesRow modulesRowTitle">
        <Col xs={12} className="modulesCol gs_reveal gs_reveal_direction">
          <h2>
            «Три кита» <span className="featuredText">курса</span>
          </h2>
          <h3>Кати Сафаровой</h3>
        </Col>
      </Row>
      <Row className="modulesRow modulesRowSlider">
        <Col xs={12} className="modulesCol gs_reveal gs_reveal_direction">
          <CardDeck>
            {/* <Slider {...settings}>
            <div className="item"> */}
            <Card className="cardStyle cardConfig2">
              <div>
                <Card.Header className="cardHeader triangleTopRight">
                  <div className="section-parallax">
                    <img src={sliderImg1} alt="" className="sliderImg" />
                  </div>
                </Card.Header>
                <Card.Body className="cardBody">
                  <div className="cardBodyWrap">
                    <span className="numbersStroke numbersStroke1">01</span>
                    <h5 className="cardTitle">
                      Комплекс упражнений
                      <br />
                      от Кати Сафаровой
                    </h5>
                    <p className="cardText">
                      Мой «секретный» комплекс упражнений эффективной проработки
                      всего тела.
                    </p>
                  </div>
                </Card.Body>
              </div>
            </Card>
            {/* </div>
            <div className="item"> */}
            <Card className="cardStyle cardConfig2">
              <div>
                <Card.Header className="cardHeader triangleTopRight">
                  <div className="section-parallax">
                    <img src={sliderImg2} alt="" className="sliderImg" />
                  </div>
                </Card.Header>
                <Card.Body className="cardBody">
                  <div className="cardBodyWrap">
                    <span className="numbersStroke numbersStroke2">02</span>
                    <h5 className="cardTitle">
                      Какие продукты входят в мой ежедневный рацион
                    </h5>
                    <p className="cardText">
                      Ты научишься правильно питаться и поддерживать здоровый
                      образ жизни.
                    </p>
                  </div>
                </Card.Body>
              </div>
            </Card>
            {/* </div>
            <div className="item"> */}
            <Card className="cardStyle cardConfig2">
              <div>
                <Card.Header className="cardHeader triangleTopRight">
                  <div className="section-parallax">
                    <img src={sliderImg3} alt="" className="sliderImg" />
                  </div>
                </Card.Header>
                <Card.Body className="cardBody">
                  <div className="cardBodyWrap">
                    <span className="numbersStroke numbersStroke3">03</span>
                    <h5 className="cardTitle">
                      Пошаговое знакомство
                      <br />с йогой
                    </h5>
                    <p className="cardText">
                      Ты научишься быть в гармонии с собой и будешь готова к
                      перейти к здоровому образу жизни. Уверяю тебя, что ты с
                      первого дня заметишь положительные изменения.
                    </p>
                  </div>
                </Card.Body>
              </div>
            </Card>
            {/* </div>
          </Slider> */}
          </CardDeck>
        </Col>
      </Row>
      <Row className="modulesRow modulesRowButton">
        <Col xs={12} className="modulesCol gs_reveal gs_reveal_scale">
          <Button
            bsPrefix
            className="buttonStyle buttonText button--calypso button--calypso-primary"
            onClick={() => {
              getScroll().scrollTo(document.querySelector('#payment'), {
                offset: -72,
              });
            }}
          >
            <span>НАЧАТЬ ТРАНСФОРМАЦИЮ</span>
          </Button>
        </Col>
      </Row>
    </Row>
  );
};

export default ModulesSection;
