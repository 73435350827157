import React from "react";
import { Col, Row } from "react-bootstrap";
import aboutMeImg from "../../../../assets/imgs/aboutMe/aboutMeImg.jpg";
const AboutMeSection = () => {
   return (
      <Row className="rowSection">
         <Col className="aboutMeCol aboutMeColImg gs_reveal gs_reveal_fromRight" xs={12} lg={6}>
            <div className="aboutMeImg triangleTopLeft triangleBgColor2">
               <div className="section-parallax">
                  <img src={aboutMeImg} alt="" className="cropBorderLeft" />
               </div>
            </div>
         </Col>
         <Col className="aboutMeCol" xs={12} lg={6}>
            <div className="aboutMeContent">
               <div className="aboutMeTitleWrap gs_reveal gs_reveal_fromLeft">
                  <h2>
                     В чём <br className="d-block d-lg-none" />
                     состоит <span className="featuredText">курс</span>
                  </h2>
                  <h3>Кати Сафаровой?</h3>
               </div>
               <div className="aboutMeTextWrap gs_reveal gs_reveal_direction">
                  <div>
                     <p>Это метод, который я применяю в своей повседневной жизни. Он был разработан моим тренером и диетологом, чтобы ты всегда была в гармонии и чувствовала себя хорошо внутри и снаружи.</p>
                     <p className="d-none d-lg-block">Кроме того, с Обновлением 2.0 ты получишь скачиваемые файлы, которые дополняют курс и которые ты сможешь взять с собой куда угодно. (выделите цвет в дизайне)</p>
                     <p className="d-none d-lg-block">Ты обретешь свой внутренний мир и гармонию, делая себя сильнее, укрепляя ягодицы, пресс, ноги и руки, и наслаждаясьрезультатами, которые это приносит.</p>
                     <p className="d-none d-lg-block">Я уверяю тебя, если ты будешь регулярно выполнять эти упражнения, тебе вряд ли понадобится много времени, чтобы увидеть первые результаты.</p>
                     <p className="d-none d-lg-block">Независимо от твоего возраста или уровня,с которого ты начинаешь, здесь есть место для всех, важно желание стать женщиной, которой вы хотите быть.</p>
                  </div>
               </div>
            </div>
         </Col>
         <Col className="aboutMeCol aboutMeColMobileText d-flex d-lg-none gs_reveal gs_reveal_direction" xs={12} lg={6}>
            <div className="aboutMeContent">
               <div className="aboutMeTextWrap">
                  <div>
                     {/* <div className="h3sWrap">
                       <h3>распорядки</h3>
                        <h3>суточные</h3>
                        <h3>Онлайн-формат</h3>
                     </div>*/}
                     <p>Я уверяю тебя, если ты будешь регулярно выполнять эти упражнения, тебе вряд ли понадобится много времени, чтобы увидеть первые результаты.</p>
                     <p>Независимо от твоего возраста или уровня,с которого ты начинаешь, здесь есть место для всех, важно желание стать женщиной, которой вы хотите быть.</p>
                  </div>
               </div>
            </div>
         </Col>
      </Row>
   );
};

export default AboutMeSection;
