import React from 'react';
import { Row } from 'react-bootstrap';
import TipsSection from '../../sections_Ads/tipsSection/TipsSection';

const Tips = () => {
  return (
    <Row
      className="RootTips RootContainers sectionsPadding sectionPolygon sectionPolygonBottomCenter"
      id="advantages"
    >
      <div className="sectionPolygon1 spb" />
      <div className="sectionPolygon2 spb" />
      <TipsSection />
    </Row>
  );
};

export default Tips;
