import React, { useContext } from 'react';
import { Accordion, Card, useAccordionToggle } from 'react-bootstrap';
// FONT AWESOME
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//ICON
import {
  faPlus,
  faMinus,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import arrowUp from '../../../../../assets/imgs/icons/upArrow.svg';
import arrowDown from '../../../../../assets/imgs/icons/downArrow.svg';
import arrowUp2 from '../../../../../assets/imgs/icons/upArrow2.svg';
import arrowDown2 from '../../../../../assets/imgs/icons/downArrow2.svg';
import { ScrollContext } from '../../../../../layout/Layout';
import ScrollTrigger from 'gsap/ScrollTrigger';
export const CustomToggle = (props) => {
  const getScroll = useContext(ScrollContext);

  const { eventKey, activeEventKey, updateEventKey } = props;
  const toggleAction = useAccordionToggle(eventKey, () => {
    setTimeout(() => {
      getScroll().update();
      ScrollTrigger.refresh();
    }, 300);
    if (activeEventKey === eventKey) {
      updateEventKey(null);
    } else {
      updateEventKey(eventKey);
    }
  });

  return (
    <Accordion.Toggle
      as={Card.Header}
      className="accordionToggle hoverLinks"
      eventKey={eventKey}
      onClick={toggleAction}
    >
      <div className="accordionToggleDiv">
        {eventKey === activeEventKey ? (
          <span className="accordionToggleIcon">
            {/* <FontAwesomeIcon icon={faChevronUp} /> */}
            <img src={arrowUp} className="d-none d-lg-flex" alt="" />
            <img src={arrowUp2} className="d-flex d-lg-none" alt="" />
          </span>
        ) : (
          <span className="accordionToggleIcon">
            {/* <FontAwesomeIcon icon={faChevronDown} /> */}
            <img src={arrowDown} className="d-none d-lg-flex" alt="" />
            <img src={arrowDown2} className="d-flex d-lg-none" alt="" />
          </span>
        )}
        <h5 className="cardTitle">{props.faqTitle}</h5>
      </div>
    </Accordion.Toggle>
  );
};

export default CustomToggle;
