import React from 'react';
import { Row } from 'react-bootstrap';
import AboutMeSection from '../../sections/aboutMeSection/AboutMeSection';

const AboutMe = () => {
  return (
    <Row
      className="RootAboutMe RootContainers sectionsPadding sectionPolygon sectionPolygonBottomCenter"
      id="aboutMe"
    >
      <div className="sectionPolygon1 spb" />
      <div className="sectionPolygon2 spb" />
      <AboutMeSection />
    </Row>
  );
};

export default AboutMe;
