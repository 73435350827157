import React from 'react';
import { Col, Row } from 'react-bootstrap';
import katImg from '../../../../assets/imgs/members/katImg.jpg';

import dobleQuoteGroup from '../../../../assets/imgs/icons/dobleQuoteGroup.svg';
import ginnaImg from '../../../../assets/imgs/members/ginnaImg.jpg';
import soniaImg from '../../../../assets/imgs/members/soniaImg.jpg';
const MembersSection = () => {
  return (
    <Row className="rowSection" id="colaboracion">
      <Row className="membersRow">
        <Col xs={12} lg={6} className="membersCol gs_reveal gs_reveal_fromLeft">
          <div className="titleWrap d-block d-lg-none">
            <h3 className="membersTitle">КАТЕРИНА САФАРОВА</h3>
            <h3 className="membersTitle textStroke">КАТЕРИНА САФАРОВА</h3>
            <h3 className="membersTitle textStroke">КАТЕРИНА САФАРОВА</h3>
          </div>
          <div className="triangleTopRight">
            <div className="section-parallax">
              <img src={katImg} alt="" className="membersImg" />
            </div>
          </div>
        </Col>
        <Col
          xs={12}
          lg={6}
          className="membersCol d-flex d-lg-none gs_reveal gs_reveal_fromRight"
        >
          <div className="contentWrap">
            <div>
              <div className="textWrap">
                <ul className="customList">
                  <li>Профессиональная актриса и модель</li>
                  <li>Создательница курса Кати Сафаровой®</li>
                  <li>Сотни довольных учениц</li>
                </ul>
              </div>
            </div>
          </div>
        </Col>
        <Col
          xs={12}
          lg={3}
          className="membersCol membersColQuotes d-none d-lg-block gs_reveal gs_reveal_direction"
        >
          <div>
            {/* <img
              src={dobleQuoteFill}
              alt=""
              className="membersImg membersImgQuoteFill"
            />
            <img
              src={dobleQuoteBorder}
              alt=""
              className="membersImg membersImgQuoteBorder"
            /> */}
            <img
              src={dobleQuoteGroup}
              alt=""
              className="membersImg membersImgQuoteBorder"
            />
          </div>
        </Col>
        <Col
          xs={12}
          lg={3}
          className="membersCol d-none d-lg-block gs_reveal gs_reveal_fromRight"
        >
          <div className="titleWrap d-flex d-lg-none">
            <h3 className="membersTitle">Соня Руис</h3>
          </div>
          <div className="triangleTopRight">
            <div className="section-parallax">
              <img src={soniaImg} alt="" className="membersImg" />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="membersRow">
        <Col
          xs={12}
          lg={6}
          className="membersCol d-none d-lg-block gs_reveal gs_reveal_fromLeft"
        >
          <div className="contentWrap">
            <div>
              <div className="titleWrap">
                <h3 className="membersTitle">КАТЕРИНА САФАРОВА</h3>
                <h3 className="membersTitle textStroke">КАТЕРИНА САФАРОВА</h3>
                <h3 className="membersTitle textStroke">КАТЕРИНА САФАРОВА</h3>
              </div>
              <div className="textWrap">
                <ul className="customList">
                  <li>Профессиональная актриса и модель</li>
                  <li>Создательница курса Кати Сафаровой®</li>
                  <li>Сотни довольных учениц</li>
                </ul>
              </div>
            </div>
          </div>
        </Col>
        <Col
          xs={12}
          lg={3}
          className="membersCol membersColGinna gs_reveal gs_reveal_direction"
        >
          <div className="titleWrap d-block d-lg-none">
            <h3 className="membersTitle">Жинна Педрос</h3>
          </div>
          <div className="triangleBottomRight">
            <div className="section-parallax">
              <img src={ginnaImg} alt="" className="membersImg" />
            </div>
          </div>
          <div className="contentWrap">
            <div>
              <div className="titleWrap d-none d-lg-block">
                <h3 className="membersTitle">Жинна Педрос</h3>
              </div>
              <div className="textWrap d-none d-lg-block">
                <ul className="customList">
                  <li>
                    Персональным тренером Кати с <br />
                    более чем 9-летним стажем.
                  </li>
                </ul>
              </div>
              <div className="textWrap d-block d-lg-none">
                <ul className="customList">
                  <li>Нутрициологом с более чем 13-летним стажем.</li>
                </ul>
              </div>
            </div>
          </div>
        </Col>
        <Col
          xs={12}
          lg={3}
          className="membersCol membersColGinna d-flex d-lg-none gs_reveal gs_reveal_direction"
        >
          <div className="titleWrap d-block d-lg-none">
            <h3 className="membersTitle">Соня Руис</h3>
          </div>
          <div className="triangleBottomRight">
            <div className="section-parallax">
              <img src={soniaImg} alt="" className="membersImg" />
            </div>
          </div>
          <div className="contentWrap">
            <div>
              <div className="textWrap">
                <ul className="customList">
                  <li>
                    Персональным тренером Кати с более чем 9-летним стажем.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Col>
        <Col
          xs={12}
          lg={3}
          className="membersCol membersColSonia d-none d-lg-block gs_reveal gs_reveal_fromRight"
        >
          <div className="contentWrap">
            <div>
              <div className="titleWrap">
                <h3 className="membersTitle">Соня Руис</h3>
              </div>
              <div className="textWrap">
                <ul className="customList">
                  <li>Нутрициологом с более чем 13-летним стажем.</li>
                </ul>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Row>
  );
};

export default MembersSection;
