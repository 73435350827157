import React from "react";
import { Col, Row } from "react-bootstrap";
import tipsImg from "../../../../assets/imgs/tips/tipsImg.jpg";
const TipsSection = () => {
   return (
      <Row className="rowSection longLanding">
         <Col xs={12} lg={6} className="tipsCol tipsColTitle d-flex d-lg-none gs_reveal gs_reveal_direction">
            <div>
               <h2>
                  VENTAJAS DEL <span className="featuredText">MÉTODO</span>
               </h2>
               <h3>KAT SAFAROVA</h3>
            </div>
         </Col>
         <Col xs={12} lg={6} className="tipsCol tipsColImg gs_reveal gs_reveal_fromLeft">
            <div className="triangleTopLeft triangleBgColor2">
               <div className="section-parallax">
                  <img src={tipsImg} alt="" className="tipsImg cropBorderLeft" />
               </div>
            </div>
         </Col>
         <Col xs={12} lg={6} className="tipsCol gs_reveal gs_reveal_fromRight">
            <div>
               <h2 className="d-none d-lg-block">
                  VENTAJAS DEL <span className="featuredText">MÉTODO</span>
               </h2>
               <h3 className="d-none d-lg-block">KAT SAFAROVA</h3>

               <ul className="customList withMarginLeft">
                  <li>Opciones para entrenar desde casa o desde el gimnasio.</li>
                  <li>Adáptate a cualquiera de los 3 niveles de entreno (fácil, medio y difícil) y obtén resultados.</li>
                  <li>Formato online. Accede desde el ordenador, móvil o tablet, sin horarios ni presión.</li>
                  <li>Acceso de por vida, con un único pago, y soporte prioritario por email.</li>
                  <li>Te daremos instrucciones concretas para entrenar y alimentarte de forma eficaz sinperjudicar tu salud.</li>
               </ul>
            </div>
         </Col>
      </Row>
   );
};

export default TipsSection;
