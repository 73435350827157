import React from "react";
import { Row } from "react-bootstrap";
import HomeSection from "../../sections/homeSection/HomeSection";
import bgGif from "../../../../assets/imgs/home/hero.mp4";
import bgGifWebm from "../../../../assets/imgs/home/hero.webm";
//import bgGifBF from "../../../../assets/imgs/home/blackfriday_es.mp4";
//import bgGifWebmBF from "../../../../assets/imgs/home/blackfriday_es.webm";
import heroPoster from "../../../../assets/imgs/home/heroPoster2.jpg";
import { isPromoActive, isBlackFriday } from "../../../../layout/Layout";

export const Home = () => {
   return (
      <Row className={isPromoActive() ? "RootHome RootHomePromo RootContainers sectionPolygon sectionPolygonBottomExtern" : "RootHome RootContainers sectionPolygon sectionPolygonBottomExtern"}>
         <div className="sectionPolygon1 spb" />
         <div className="sectionPolygon2 spb" />
         <HomeSection />
         <video className="lazy bgImg" autoPlay loop muted playsInline poster={heroPoster}>
            {/*<source data-src={(isPromoActive() && isBlackFriday) ? bgGifWebmBF : bgGifWebm} type="video/webm" />*/}
            <source data-src={(isPromoActive() && isBlackFriday) ? bgGif : bgGif} type="video/mp4" />
         </video>
         {/* <img src={bgImg} alt="" className="bgImg" /> */}
      </Row>
   );
};

export default Home;
