import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Nav, Button, Dropdown } from 'react-bootstrap';
// import { isPromoActive, ScrollContext } from '../../layout/Layout';

import navLogo from '../../assets/imgs/brand/navLogoRu.svg';
import { isPromoActive, ScrollContext } from '../../layout/Layout';
import ruFlag from '../../assets/imgs/navbar/ru.svg';
import spFlag from '../../assets/imgs/navbar/sp.svg';
import checkPaths from '../../_helpers/checkPaths';
export const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua);
};

export const CustomNavbar = (props) => {
  const getScroll = useContext(ScrollContext);
  const [navExpanded, setNavExpanded] = useState(false);
  const [dropShow, setDropShow] = useState(false);

  const [navColor, setNavColor] = useState('transparent');
  // const [navLogo, setNavLogo] = useState(logo2);

  useEffect(() => {
    // setTimeout(
    //   () =>
    window.addEventListener('DOMContentLoaded', (event) => {
      getScroll().on('scroll', (instance) => {
        if (instance.direction) {
          setNavExpanded(false);
          setDropShow(false);

          // setTimeout(() => {
          //   document.querySelector('#navbar').style.mixBlendMode = 'difference';
          // }, 300);
        }
        if (instance.scroll.y < 50) {
          document.querySelector('#navbar').classList.remove('navbarScrolled');
          setTimeout(() => {
            document.querySelector('.navBrand').style.mixBlendMode = 'unset';
            document.querySelector('.navbar-toggler').style.mixBlendMode =
              'unset';
          }, 300);
        } else {
          document.querySelector('#navbar').classList.add('navbarScrolled');
        }
      });
    });
    //   100
    // );
  }, []);

  const collapsetoggle = () => {
    setNavExpanded(!navExpanded);

    if (!navExpanded) {
      document.querySelector('.navBrand').style.mixBlendMode = 'difference';
      document.querySelector('.navbar-toggler').style.mixBlendMode =
        'difference';
    } else {
      setTimeout(() => {
        document.querySelector('.navBrand').style.mixBlendMode = 'unset';
        document.querySelector('.navbar-toggler').style.mixBlendMode = 'unset';
      }, 300);
    }
  };

  return (
    <Navbar
      id="navbar"
      collapseOnSelect
      expand="lg"
      className={
        isPromoActive() ? 'nav-color-scroll nav-promo' : 'nav-color-scroll'
      }
      variant="light"
      fixed="top"
      expanded={navExpanded}
      onToggle={collapsetoggle}
      // data-scroll
      // data-scroll-sticky
      // data-scroll-target="#inicio"
      // style={isMobile() ? { background: navColor } : ''}
    >
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        id="containerNavToggle"
        style={{ outline: 'none' }}
      />
      <Navbar.Brand
        className="navBrand d-flex d-lg-none"
        id="navbarBrandContainer"
        href="/"
      >
        {/* <img alt="" src={navLogo} className="navbarLogo" /> */}
        <img alt="" src={navLogo} className="navbarLogo" />
      </Navbar.Brand>

      <Navbar.Text className="d-flex d-lg-none">
        <Dropdown
          className="flagsDropDown"
          show={dropShow}
          onToggle={() => {
            setDropShow(!dropShow);
          }}
        >
          <Dropdown.Toggle className="flagsDropDownToggle">
            <img src={ruFlag} alt="" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="flagsDropDownMenu">
            <Dropdown.Item
              onClick={() =>
                (window.location.pathname = window.location.pathname.replace(
                  'ru',
                  'es'
                ))
              }
              className="flagsDropDownItem"
            >
              <img src={spFlag} alt="" />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar.Text>

      <Navbar.Collapse id="responsive-navbar-nav" className="navCollapse">
        <div className="collapseNavHeight d-block d-lg-none" />
        <Nav className="navContent " id="navLinksBlack">
          <Nav.Link
            onClick={() => {
              getScroll().scrollTo('top');
            }}
            className="linksNav"
          >
            <span>Главная страница</span>
          </Nav.Link>
          <svg
            width="4"
            height="4"
            viewBox="0 0 4 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="#F3F3F3" />
          </svg>

          <Nav.Link
            onClick={() => {
              getScroll().scrollTo(document.querySelector('#beneficios'), {
                offset: -72,
              });
            }}
            className="linksNav"
          >
            <span>Преимущества</span>
          </Nav.Link>
          <svg
            width="4"
            height="4"
            viewBox="0 0 4 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="#F3F3F3" />
          </svg>

          <Nav.Link
            onClick={() => {
              getScroll().scrollTo(document.querySelector('#colaboracion'), {
                offset: -72,
              });
            }}
            className="linksNav"
          >
            <span>Сотрудничество</span>
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              if (checkPaths(window.location.pathname).status) {
                window.location.pathname = checkPaths(
                  window.location.pathname
                ).path;
              } else {
                getScroll().scrollTo('top');
              }
            }}
            className="linksNav d-none d-lg-flex"
          >
            <img alt="" src={navLogo} className="navbarLogo" />
          </Nav.Link>
          <svg
            width="4"
            height="4"
            viewBox="0 0 4 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="#F3F3F3" />
          </svg>

          <Nav.Link
            onClick={() => {
              getScroll().scrollTo(document.querySelector('#programa'), {
                offset: -72,
              });
            }}
            className="linksNav"
          >
            <span>Программа</span>
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              getScroll().scrollTo(document.querySelector('#faq'), {
                offset: -72,
              });
            }}
            className="linksNav"
          >
            <span>Часто задаваемые вопросы</span>
          </Nav.Link>
          <svg
            width="4"
            height="4"
            viewBox="0 0 4 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="#F3F3F3" />
          </svg>

          <Nav.Link
            href="https://sso.teachable.com/secure/998051/users/sign_in"
            target="_blank"
            rel="noopener noreferrer"
            className="linksNav"
          >
            <span>Мои курсы</span>
          </Nav.Link>
          <svg
            width="4"
            height="4"
            viewBox="0 0 4 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="#F3F3F3" />
          </svg>
          <Nav.Link className="linksNav linksNavDropDown">
            <Dropdown
              className="flagsDropDown"
              // drop={'right'}
              show={dropShow}
              onToggle={() => {
                setDropShow(!dropShow);
              }}
            >
              <Dropdown.Toggle className="flagsDropDownToggle">
                <img src={ruFlag} alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="flagsDropDownMenu">
                <Dropdown.Item
                  onClick={() =>
                    (window.location.pathname =
                      window.location.pathname.replace('ru', 'es'))
                  }
                  className="flagsDropDownItem"
                >
                  <img src={spFlag} alt="" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Link>
        </Nav>
        {/* <Nav className="d-none d-lg-flex">
          <Nav.Link href="#inscribirse" className="curseLink">
            <span>Acceder al Curso</span>
          </Nav.Link>
        </Nav> */}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
