import React from 'react';
import Footer from '../../../components/footer/Footer';
import Layout from '../../../layout/Layout';

export const ExclusionEn = () => {
  return (
    <Layout>
      <div className="RootLegals">
        <div className="first-section privacityContainer">
          <div className="container privacityContent">
            <div>
              <header>
                <h2>EXCLUSION OF LIABILITY</h2>
              </header>
              <div>
                <div>
                  <p>In 4 areas:</p>
                  <ul>
                    <li>General</li>
                    <li>Medical/Health</li>
                    <li>Personal</li>
                    <li>Performance</li>
                  </ul>
                  <h4>General:</h4>
                  <p>
                    By browsing this website, purchasing our courses, using our
                    platform, emails, programmes, services and/or products, you
                    agree to all parts of this disclaimer.
                  </p>
                  <p>
                    We are not responsible for the information you request or
                    receive through our website, our platform, emails,
                    programmes, services and/or products. In no event will we be
                    liable for any direct or indirect damages that your use of
                    or reliance on this website, our platform, emails, programs,
                    services and/or products may cause you. We shall not be
                    liable for any loss you may suffer, nor shall we be liable
                    for any personal injury, accidents you may suffer by
                    performing the proposed exercises.
                  </p>
                  <h4>Medical/Health:</h4>
                  <p>
                    SUPRIVE recommends that you consult with your physician
                    before beginning any exercise program in order to have
                    him/her certify that you are able to perform the intended
                    exercises and that you have the physical condition to
                    perform the exercises. SUPRIVE does not have a medical team
                    and is not qualified to perform any diagnosis, examination
                    or treatment of medical conditions of any kind, or in
                    determining the effect of any specific exercise on a medical
                    condition.
                  </p>
                  <p>
                    You assume that, by participating in any exercise or
                    exercise programme, there is a possibility that you may
                    sustain physical injury. If you participate in this exercise
                    or exercise program, you do so at your own risk and assume
                    all risk of injury and hold SUPRIVE harmless.
                  </p>
                  <p>
                    The information contained on the website is generic and in
                    no way replaces the advice of a medical professional, it is
                    not intended as a diagnosis or treatment. You should never
                    disregard medical advice or delay in seeking it.
                  </p>
                  <p>
                    SUPRIVE is not responsible for any advice, diagnosis or
                    treatment. If you have any doubts, you should consult your
                    doctor regarding the information contained in this website.
                  </p>
                  <p>
                    Remember that there may be risks to your health in the
                    incorrect execution of the technique proposed by Katerina
                    Safarova, as well as injury if you perform the exercises
                    incorrectly or perform more repetitions than recommended.
                  </p>
                  <p>
                    If you do not adapt the level of effort to your physical
                    capacity and do not respect the times and days of rest, you
                    could cause injuries for which we cannot be held
                    responsible.
                  </p>
                  <p>
                    Under no circumstances should you use more weight than
                    recommended and this must be appropriate to your physical
                    condition, otherwise it could cause injury.
                  </p>
                  <p>
                    Under no circumstances should you train with a previous
                    injury as this could be aggravated and we cannot be held
                    responsible for this.
                  </p>
                  <h4>Personal:</h4>
                  <p>
                    KATERINA SAFAROVA is not a medical doctor. The information
                    you provide is based on your personal experience.
                  </p>
                  <p>
                    The information contained on the website, our platform, our
                    guest platforms, emails, programmes, services and/or
                    products is for informational purposes only, and is
                    available to you as tools for your own use. While Katerina
                    Safarova only relies on her professional experience, not on
                    qualified training in this regard.
                  </p>
                  <p>
                    Remember that Katerina Safarova is not a medical health
                    professional, nor a mental health provider. Her sole purpose
                    in serving as a coach is to help you achieve your own health
                    and wellness goals.
                  </p>
                  <p>
                    You voluntarily participate in the use of our website and
                    are solely responsible for your results. You assume full
                    responsibility for your health, life and well-being.
                  </p>
                  <p>
                    In the event that you use the information provided through
                    our website, we assume no liability.
                  </p>
                  <p>
                    Katerina Safarova makes every effort to ensure the accuracy
                    of the information published on our website however, the
                    information may contain inaccuracies or typographical
                    errors.
                  </p>
                  <h4>Results:</h4>
                  <p>
                    We do our best to guarantee the services provided, but the
                    result depends on muscle development, your physical
                    condition without losing sight of the fact that fat loss
                    results differ from individual to individual.
                  </p>
                  <p>
                    We do not guarantee the level of success you may experience,
                    and you accept that results will be different for each
                    individual. The testimonials and examples provided are
                    exceptional results, which may not apply to the average
                    purchaser, and are not intended to represent or guarantee
                    that anyone will achieve the same or similar results.
                  </p>
                  <p>
                    The success of each individual's health, fitness and
                    nutrition depends on their background, dedication, desire
                    and motivation. As with any health-related programme or
                    service, your results may vary and will be based on many
                    variables, including, but not limited to, your individual
                    ability, life experience, genetic and health profile,
                    starting point, experience and level of commitment.
                  </p>
                  <p>
                    There is no guarantee that examples of past fitness,
                    competition, muscle building and/or fat burning results will
                    be duplicated in the future. We cannot guarantee your future
                    results and/or success. Nor can we guarantee that you will
                    maintain the results you experience if you do not continue
                    to follow the programme.
                  </p>
                  <p>
                    Your use of our information, products and services must be
                    based on your own due diligence and you agree that our
                    company is not responsible for the success or failure of
                    your physical condition and results.
                  </p>
                  <p>
                    The experiences presented are based on real people in the
                    real world for illustrative purposes only.
                  </p>
                  <p>
                    The testimonials, examples and photos used are from real
                    clients and results they personally achieved. Each client
                    has approved these testimonials, examples and photos for use
                    in materials to speak to our program, service and/or product
                    capabilities, but are not intended to represent or guarantee
                    that current or future clients will achieve the same or
                    similar results. The customer stories represent what is
                    possible with our programmes, services and/or products.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer polygons={true} />
    </Layout>
  );
};

export default ExclusionEn;
