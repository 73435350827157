import React from 'react';
import { Row } from 'react-bootstrap';
import ModulesSection from '../../sections_Ads/modulesSection/ModulesSection';

const Modules = () => {
  return (
    <Row className="RootModules RootContainers sectionsPadding sectionPolygon sectionPolygonBottomExtern">
      <div className="sectionPolygon1 spb" />
      <div className="sectionPolygon2 spb" />
      <ModulesSection />
    </Row>
  );
};

export default Modules;
