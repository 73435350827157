import React, { useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import infoImg1 from "../../../../assets/imgs/info/1.jpg";
import infoImg2 from "../../../../assets/imgs/info/2.jpg";
import { ScrollContext } from "../../../../layout/Layout";
const InfoSection = () => {
   const getScroll = useContext(ScrollContext);

   return (
      <Row className="rowSection" id="beneficios">
         <Row className="infoRow d-none d-lg-flex">
            <Col xs={12} lg={6} className="infoCol gs_reveal gs_reveal_fromLeft">
               <div>
                  <h2>
                     ESTO ES LO QUE <br />
                     CONSEGUIRÁS INSCRIBIÉNDOTE <br />A MI <span className="featuredText">MÉTODO</span>
                     {/* <span className="featuredText">метод</span> */}
                  </h2>
                  <ul className="customList withMarginLeft">
                     <li>Glúteos firmes, piernas estilizadas, vientre plano y brazos tonificados.</li>
                     <li>Establecer una rutina que te permita tener cuerpo y mente alineados a través de la alimentación, el deporte y el yoga.</li>
                     <li>Tips para ganar músculo y perder grasa.</li>
                  </ul>
               </div>
            </Col>
            <Col xs={12} lg={6} className="infoCol infoColImg gs_reveal gs_reveal_direction">
               <div className="triangleTopRight triangleBgColor2">
                  <div className="section-parallax">
                     <img src={infoImg1} alt="" className="infoImg" />
                  </div>
               </div>
            </Col>
            <Col xs={12} lg={6} className="infoCol infoColImg gs_reveal gs_reveal_direction">
               <div className="triangleTopLeft triangleBgColor2">
                  <div className="section-parallax">
                     <img src={infoImg2} alt="" className="infoImg cropBorderLeft" />
                  </div>
               </div>
            </Col>
            <Col xs={12} lg={6} className="infoCol gs_reveal gs_reveal_fromRight">
               <div>
                  <ul className="customList">
                     <li>Las rutinas de entrenamiento que sigo y que nunca he desvelado</li>
                     <li>Cómo alimentarte para conseguir el cuerpo que deseas, sin pasar hambre y con comida que todas tenemos en la nevera.</li>
                     <li>Entrenos divertidos para todos los niveles (fácil, medio y difícil)</li>
                     <li>Las pautas que debes seguir para mantenerte en paz y así alcanzar la felicidad</li>
                     <li>Mejorar tu salud y, por lo tanto, aumentar tu energía y bienestar general.</li>
                  </ul>

                  <Button
                     bsPrefix
                     className="buttonStyle buttonText button--calypso button--calypso-primary"
                     onClick={() => {
                        getScroll().scrollTo(document.querySelector("#payment"), {
                           offset: -72,
                        });
                     }}
                  >
                     <span>EMPEZAR TRANSFORMACIÓN</span>
                  </Button>
               </div>
            </Col>
         </Row>
         <Row className="infoRow d-flex d-lg-none">
            <Col xs={12} lg={6} className="infoCol gs_reveal gs_reveal_direction">
               <div>
                  <h2>
                     ESTO ES LO QUE CONSEGUIRÁS INSCRIBIÉNDOTE A MI <span className="featuredText">MÉTODO</span>
                  </h2>
               </div>
            </Col>
            <Col xs={12} lg={6} className="infoCol infoColImg d-flex d-lg-none gs_reveal gs_reveal_direction">
               <div className="triangleTopLeft triangleBgColor2">
                  <div className="section-parallax">
                     <img src={infoImg2} alt="" className="infoImg cropBorderLeft" />
                  </div>
               </div>
            </Col>
            <Col xs={12} lg={6} className="infoCol gs_reveal gs_reveal_direction">
               <div>
                  <ul className="customList withMarginLeft">
                     <li>Glúteos firmes, piernas estilizadas, vientre plano y brazos tonificados.</li>
                     <li>Establecer una rutina que te permita tener cuerpo y mente alineados a través de la alimentación, el deporte y el yoga.</li>
                     <li>Tips para ganar músculo y perder grasa.</li>
                     <li>Las rutinas de entrenamiento que sigo y que nunca he desvelado.</li>
                  </ul>
               </div>
            </Col>
            <Col xs={12} lg={6} className="infoCol infoColImg gs_reveal gs_reveal_direction">
               <div className="triangleTopRight triangleBgColor2">
                  <div className="section-parallax">
                     <img src={infoImg1} alt="" className="infoImg" />
                  </div>
               </div>
            </Col>
            <Col xs={12} lg={6} className="infoCol infoColImg d-none d-lg-flex gs_reveal gs_reveal_direction">
               <div className="triangleTopLeft triangleBgColor2">
                  <div className="section-parallax">
                     <img src={infoImg2} alt="" className="infoImg" />
                  </div>
               </div>
            </Col>
            <Col xs={12} lg={6} className="infoCol gs_reveal gs_reveal_direction">
               <div>
                  <ul className="customList">
                     <li>Cómo alimentarte para conseguir el cuerpo que deseas, sin pasar hambre y con comida que todas tenemos en la nevera.</li>
                     <li>Entrenos divertidos para todos los niveles (fácil, medio y difícil).</li>
                     <li>Las pautas que debes seguir para mantenerte en paz y así alcanzar la felicidad</li>
                     <li>Mejorar tu salud y, por lo tanto, aumentar tu energía y bienestar general.</li>
                  </ul>

                  <Button
                     bsPrefix
                     className="buttonStyle buttonText button--calypso button--calypso-primary d-none d-lg-inline-block"
                     onClick={() => {
                        getScroll().scrollTo(document.querySelector("#payment"), {
                           offset: -72,
                        });
                     }}
                  >
                     <span>EMPEZAR TRANSFORMACIÓN</span>
                  </Button>
               </div>
            </Col>
            <Col xs={12} lg={6} className="infoCol infoColButton d-flex d-lg-none">
               <div>
                  <Button
                     bsPrefix
                     className="buttonStyle buttonText button--calypso button--calypso-primary"
                     onClick={() => {
                        getScroll().scrollTo(document.querySelector("#payment"), {
                           offset: -72,
                        });
                     }}
                  >
                     <span>EMPEZAR TRANSFORMACIÓN</span>
                  </Button>
               </div>
            </Col>
         </Row>
      </Row>
   );
};

export default InfoSection;
