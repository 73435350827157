import React from 'react';
import { Table } from 'react-bootstrap';
import Footer from '../../../components/footer/Footer';
import Layout from '../../../layout/Layout';

export const PoliticaDePrivacidadEn = () => {
  return (
    <Layout>
      <div className="RootLegals">
        <div className="first-section privacityContainer">
          <div className="container privacityContent">
            <div>
              <header>
                <h2>PRIVACY AND DATA PROTECTION POLICY</h2>
              </header>
              <p>
                At SUPRIVE, we are committed to protecting your privacy.
                Therefore, we treat your personal data and information in
                accordance with this Privacy Policy and in accordance with
                Regulation (EU) 2016/679 of the European Parliament and of the
                Council of 27 April 2016 (General Data Protection Regulation or
                "GDPR"), and with the Organic Law 3/2018 of 5 December on the
                Protection of Personal Data and Guarantee of Digital Rights
                ("LOPD-GDD") and other implementing regulations.
              </p>
              <p>
                This Privacy Policy applies to all activities carried out by
                SUPRIVE.
              </p>
              <p>
                By providing us with your personal data and using our website,
                we understand that you have read and understood the terms
                related to personal data protection information set forth
                herein.
              </p>
              <h4>1. Responsible for the processing of personal data</h4>
              <Table striped bordered hover>
                <tbody>
                  <tr>
                    <td>
                      <p>Identity</p>
                    </td>
                    <td>
                      <p>SUPRIVE MEDIA S.L</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Address</p>
                    </td>
                    <td>
                      <p>
                        Gran V&iacute;a Carlos III, 124 ent.1&ordm;, 08034
                        Barcelona, Spain
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>TAX ID</p>
                    </td>
                    <td>
                      <p>B67480699</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Phone</p>
                    </td>
                    <td>
                      <p>+34 935 211 147</p>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <p>
                Data Protection Officer (DPO) contact:{' '}
                <a href="mailto:dpo@suprive.com">dpo@suprive.com</a>
              </p>
              <h4>2. Personal data and special categories of personal data</h4>
              <p>
                Personal data is any information about an identified or
                identifiable natural person (the "data subject"), such as name,
                address, telephone number or e-mail address.
              </p>
              <p>
                Personal data (or data) is any information relating to an
                identified or identifiable natural person, even indirectly, by
                reference to any other information, including a personal
                identification number.
              </p>
              <h4>3. Personal data we collect and purposes of processing</h4>
              <p>3.1 Request for personal data</p>
              <p>
                The personal data we may request from you includes your contact
                details (e.g., name, address, telephone number, e-mail address,
                etc.), identification data (e.g., national identity card, NIE or
                passport number, etc.), or any other data that is necessary to
                fulfill the purposes set out in this Privacy Policy. If we
                collect special category personal data about you, we will inform
                you about it, ask for your express consent to the processing
                when necessary in accordance with the applicable regulations and
                inform you of its purposes.
              </p>
              <p>
                Other personal data may be collected in the course of your
                relationship with SUPRIVE, in which case we will ask for your
                consent and inform you, among other things, of the processing to
                be carried out, the purpose, the legitimacy of the processing
                and your rights in relation to your personal data.
              </p>
              <p>
                Other personal data collected may be indicated in other sections
                of this Privacy Policy or by means of informative texts
                displayed at the time of collection of this data.
              </p>
              <p>
                Personal data may be provided directly by the user or collected
                automatically during the use of the website.
              </p>
              <p>3.2 Purposes of processing</p>
              <p>
                SUPRIVE collects your personal data for various purposes,
                depending on the use and origin of such personal data (students,
                customers, suppliers, employees or third parties, etc.).
              </p>
              <p>
                Students' personal data are processed for the performance of
                SUPRIVE's own functions. The use of personal data of students
                and customers is authorized by the prior, express and specific
                consent of their owners or legal representatives, which is given
                when contracting or browsing the site. According to the
                regulations in force, the personal data that we may request for
                the fulfillment of the aforementioned tasks are those that are
                necessary for the training and contracting of the service.
              </p>
              <p>
                In the case of personal data of its customers/students,
                potential customers, students or suppliers, SUPRIVE collects and
                processes them in order to manage the service offers and the
                commercial or service relationship with them.
              </p>
              <p>
                In the case of personal data of SUPRIVE employees, the
                collection and processing of data is done in order to fulfill
                and manage the employment relationship and to attend SUPRIVE's
                activities, including personnel administration, occupational
                risk prevention, sending general or personal communications,
                including those related to information, advertising campaigns or
                courses that SUPRIVE may provide or promote. In the case of
                curricula, for the admission and management of students or
                applications and selection processes and, where appropriate,
                subsequent hiring.
              </p>
              <p>
                In all cases, the personal data collected will be used in order
                to comply with legal obligations and those arising from the
                execution of commercial relations and academic, commercial,
                administrative contracts, etc., to which SUPRIVE is a party, as
                well as to manage any complaint or legal, labor or commercial
                claim.
              </p>
              <p>
                Likewise, they may be used to send you surveys, questionnaires
                and request evaluations of our services, since such information
                is very useful to us for quality control.
              </p>
              <p>
                We will treat your contact data collected through your emails,
                or forms on this website, for the management of your requests
                and queries.
              </p>
              <p>
                Cookie data: any use of cookies - or other tracking tools - by
                the website or by external service providers used by this
                website, unless otherwise specified, is for the purpose of
                identifying the user and recording their preferences for
                commercial purposes related to the provision of the service
                requested by the user, and are processed at all times in
                accordance with our Cookie Policy.
              </p>
              <p>
                SUPRIVE may also use your data to provide you with information
                about our courses, services, products or information from
                SUPRIVE. You may at any time request that we do not contact you
                or inform you about our courses or services, or disclose your
                information to third parties for such purposes, either by
                contacting us directly or, where appropriate, by clicking the
                "unsubscribe" button in promotional emails.
              </p>
              <p>3.3 Data Collection Methods</p>
              <p>
                We generally collect personal data directly from you. We collect
                information and personal data that you provide to us
                electronically, including information provided through the
                website, web chat, social networks, email, through the provision
                of our services and through forms or any other written or
                electronic means that you complete.
              </p>
              <p>
                By browsing the website and providing personal data, you
                authorize the collection of this personal data and consent to
                such processing.
              </p>
              <p>
                If we receive information or personal data about you that we
                have not requested or that is not relevant to the purposes
                stated above, we will destroy or anonymize the information,
                unless we are required to retain it in accordance with
                applicable law.
              </p>
              <p>
                3.4 What happens if you do not provide your personal information
                and data or do not consent to its processing
              </p>
              <p>
                If you are a student, customer or supplier, you have the right
                not to provide us with personal information or data, although
                this may make it impossible to enter into any type of contract
                or to provide services.
              </p>
              <p>
                If you are an employee and do not provide information or
                personal data that is necessary for the performance of
                obligations and the exercise of legal or regulatory rights, or
                for the performance of a contract of employment or the provision
                of services by SUPRIVE to its students, or for the satisfaction
                of legitimate interests, your employment relationship with
                SUPRIVE may be impeded or otherwise affected.
              </p>
              <p>
                In the case of special category data, your explicit consent will
                always be requested, unless the processing is necessary for the
                performance of obligations and the exercise of specific rights
                of the data controller or the data subject in the field of labor
                law and social security and protection: whether it is necessary
                for the protection of vital interests or relates to data which
                you have manifestly made public; for the defense of claims or
                for reasons of essential public interest; or for the purposes of
                preventive or occupational medicine, assessment of your ability
                to work, medical diagnosis, provision of health or social care
                or treatment.
              </p>
              <h4>4. Legitimacy for the processing of personal data</h4>
              <p>
                The legal basis for the processing of your data for the
                above-mentioned purposes will always be one of those provided
                for by Article 6 of the GDPR, i.e:
              </p>
              <p>- Consent of the data subject.</p>
              <p>- Execution of a contract.</p>
              <p>
                - Fulfillment of a legal obligation applicable to the
                controller.
              </p>
              <p>
                - Protection of vital interests of the data subject or of
                another natural person.
              </p>
              <p>
                - Satisfaction of legitimate interests pursued by the controller
                or by a third party.
              </p>
              <p>
                - If the processing relates to special category personal data,
                it shall always be based on one of the legal grounds of Article
                9 of the GDPR.
              </p>
              <p>
                If the user is a minor, he/she is required to have the prior
                consent of his/her parents or guardians before proceeding to the
                inclusion of his/her personal data in the web forms. SUPRIVE
                disclaims any liability for failure to comply with this
                requirement.
              </p>
              <h4>5. Duration of the processing of personal data</h4>
              <p>
                In general, the personal data provided will be kept for the time
                necessary to fulfill the purposes described in this Privacy
                Policy or to meet any request from the data subjects, unless a
                longer retention period is legally permitted or required or as
                long as their deletion is not requested by the data subject.
              </p>
              <p>
                When SUPRIVE is considered the data controller, it shall ensure
                that the data undergoing processing:
              </p>
              <p>
                - Are suitable for the purpose for which they were collected.
              </p>
              <p>
                - Are not used for purposes other than and/or incompatible with
                those that justified their collection and processing.
              </p>
              <p>- Are not kept indefinitely without justification.</p>
              <p>
                - Are cancelled when they are no longer necessary for the
                purpose for which they were collected and processed.
              </p>
              <p>
                It is understood that the conservation of the data is justified
                when:
              </p>
              <p>
                - A prejudice could be caused to the legitimate interests of the
                data owner or third parties.
              </p>
              <p>
                - A regulation imposes the obligation to keep the data for a
                specific period of time.
              </p>
              <p>
                - The data and documentation serve as proof of an activity or
                service rendered, during the statute of limitations of civil,
                criminal, administrative or any other type of actions that may
                arise from the activity or service rendered.
              </p>
              <p>
                - A longer period of conservation has been agreed upon by the
                interested parties.
              </p>
              <p>
                In cases where there is a legal obligation to retain data for a
                specific period of time and/or during the statute of limitations
                for actions that may arise from the activity or service
                provided, SUPRIVE will block the data during the aforementioned
                periods. The blocked data will only remain at the disposal of
                public administrations, judges and courts, for the attention of
                possible liabilities arising from the processing, during the
                period of limitation of these and/or during the legal periods
                established for this purpose. Once the aforementioned periods
                have expired, the blocked data shall be deleted.
              </p>
              <p>
                When SUPRIVE is considered as the data processor, in accordance
                with the GDPR and once the contractual provision that gave rise
                to the processing assignment has been fulfilled, the personal
                data must be destroyed or returned to the data controller, as
                well as any support or document containing any personal data
                subject to processing.
              </p>
              <p>
                As in the previous case, the deletion of the data by the data
                processor will not proceed when there is a legal provision
                requiring their conservation, in which case the data must be
                returned and the data controller must guarantee said
                conservation. It is also established that the data processor
                must keep the blocked data for as long as liabilities may arise
                from its relationship with the data controller.
              </p>
              <p>
                In accordance with these requirements, the following are
                established
              </p>
              <p>Time periods for the conservation of personal data</p>
              <h4>
                In the case of applications for admission to courses given,
                managed or advertised on the SUPRIVE website:
              </h4>
              <p>
                Term: In the case of not formalizing the registration, your
                personal data will be retained for the sole purpose of
                historical or statistical research; and as evidence in the
                quality procedures in which SUPRIVE participates.
              </p>
              <h4>
                Regarding registration for training, entertainment and other
                courses:
              </h4>
              <p>
                Term: the personal data included in the registration formalized
                to in SUPRIVE that are necessary for the identification of the
                student/client and those derived from the file of the persons
                who have formalized a course with SUPRIVE, will be retained, in
                compliance with legal obligations, indefinitely.
              </p>
              <h4>Regarding academic data:</h4>
              <p>
                Term: By legal imperative, the term of conservation of the data
                related to the student's academic record is indefinite.
              </p>
              <h4>Regarding alumni/alumnae:</h4>
              <p>
                Term: The alumni status is for life, so that personal data
                provided voluntarily to SUPRIVE will remain in the system
                indefinitely as long as the person concerned does not request
                its deletion.
              </p>
              <p>
                Normative reference: art. 6.1.a) and 6.1.b) of the General Data
                Protection Regulation.
              </p>
              <h4>Regarding data related to SUPRIVE personnel:</h4>
              <p>
                Term: They will be kept for the time necessary to fulfill the
                purpose for which they were collected and to determine the
                possible responsibilities that may arise from that purpose and
                the processing of the data. The provisions of the archives and
                documentation regulations shall apply.
              </p>
              <p>
                Regulatory reference: art. 21.1 RDL 5/2004 on offences and
                penalties in the social order and arts. 6.1.b) and 6.1.c) of the
                General Data Protection Regulation.
              </p>
              <p>
                In the specific case of job applications and curricula, they
                will be kept for a period of one year, unless otherwise
                indicated by the interested party.
              </p>
              <h4>In matters of occupational risk prevention:</h4>
              <p>
                Term: They will be kept for the time necessary to fulfill the
                purpose for which they were collected and to determine the
                possible responsibilities that may arise from that purpose and
                the processing of the data. The provisions of the archives and
                documentation regulations shall apply.
              </p>
              <p>
                Regulatory reference: Art. 4 RDL 5/2004 on offenses and
                penalties in the social order and arts. 6.1.b) and 6.1.c) of the
                General Data Protection Regulation.
              </p>
              <h4>
                Regarding the processing of personal data for advertising and
                promotional purposes:
              </h4>
              <p>
                Term: the personal data of persons interested in receiving
                advertising and promotional information from SUPRIVE will be
                kept in the system indefinitely as long as the data subject does
                not request its deletion.
              </p>
              <p>
                Normative reference: art. 6.1.a) of the General Data Protection
                Regulation.
              </p>
              <h4>Regarding video surveillance:</h4>
              <p>Time limit: 1 month</p>
              <p>Normative reference: Article 22.3 of the LOPD-GDD.</p>
              <h4>
                Regarding access control to buildings: applicable to SUPRIVE
                administration and services personnel who must sign in:
              </h4>
              <p>
                Deadline: Three months from the collection of personal
                information.
              </p>
              <p>
                <br />
                <br />
                <br />
              </p>
              <h4>Regarding accounting and tax documentation:</h4>
              <p>
                Term: They shall be kept for the time necessary to fulfill the
                purpose for which they were collected and to determine the
                possible liabilities that may arise from such purpose and the
                processing of the data, in accordance with Law 58/2003, of
                December 17, General Taxation, in addition to the periods
                established in the regulations on archives and documentation.
              </p>
              <p>
                Normative reference: General period for the prescription of
                personal actions in civil legislation (art. 1964 CC and 121-20
                and ss CCCat), art. 6.1.c) of the General Data Protection
                Regulation, Law 38/2003, of 17 November, General Subsidies and
                Law 58/2003, of 17 December, General Taxation.
              </p>
              <h4>
                For the purpose of justifying national or foreign public
                subsidies and aid:
              </h4>
              <p>
                Deadline: until the end of the statute of limitations period for
                the obligation to justify the expenses paid with charge to the
                same.
              </p>
              <p>
                Normative reference: Law 38/2003, of November 17, 2003, General
                Law on Subsidies.
              </p>
              <p>
                If you would like more information about the conservation time
                of any of your personal data, you can contact us at the e-mail
                addresses included in this Privacy Policy.
              </p>
              <h4>Transfer of Personal Information to Third Parties</h4>
              <p>
                We may share or disclose your personal information to third
                parties for any of the reasons listed above. Such third parties
                may include educational institutions, universities, as well as
                their customers, external consultants, suppliers or
                subcontractors (e.g. postal service, support services, etc.), in
                compliance with contractual relationships entered into with such
                third parties, both within and outside the EU/EEA. Your data may
                also be disclosed to third parties as a result of legal
                obligations or requirements, to courts, tribunals and public
                administrations if necessary, or to any other third party,
                person or entity to whom you have authorized us.
              </p>
              <p>
                Where we are required to transfer or disclose your data to third
                parties, we will maintain our commitment to safeguard your
                personal data in accordance with the provisions of this Privacy
                Policy and will take all reasonable steps and precautions to
                ensure that the third parties maintain confidentiality and
                secrecy commitments in relation to the protection of your
                personal data. The transfer or communication of your personal
                data will always be carried out in compliance with legal
                obligations and on the basis of personal data processing
                agreements, in order to ensure that your data is not processed
                for any other purpose than those clearly stated and that the
                necessary security measures are taken.
              </p>
              <p>
                You consent that we may transfer your personal data to third
                parties located outside the country that are considered
                international transfers.
              </p>
              <p>
                We will not share special categories of personal data relating
                to you that have not been transferred by you with any person or
                entity other than SUPRIVE, or group companies, our employees,
                SUPRIVE collaborators, government agencies and others in
                compliance with legal obligations or requirements, courts,
                tribunals and public administrations, or any other third party
                authorized by law.
              </p>
              <h4>Storage and security of your personal data</h4>
              <p>
                We store your personal data in paper or electronic files. We
                have implemented safeguards as required by applicable law to
                protect the personal data we store from misuse, interference and
                loss, unauthorized access, modification or disclosure.
              </p>
              <p>
                SUPRIVE applies technical security measures such as encryption
                where reasonably possible. Likewise, different systems such as
                secure archiving of original documents or limiting access to
                personal data to those persons appropriately authorized to do so
                provide security to the processing of your personal data.
              </p>
              <p>
                In addition, SUPRIVE takes reasonable steps to permanently
                destroy all personal data at the end of its term of use in
                accordance with this Privacy Policy.
              </p>
              <h4>Rights relating to your personal data</h4>
              <p>
                SUPRIVE strives to ensure that the information it holds about
                you is accurate, up to date, complete and relevant. However, you
                have the right to access this information and correct it if it
                is incorrect, inaccurate, outdated or incomplete. You may also
                withdraw your consent to the processing of your personal data.
              </p>
              <p>
                In addition, if you have any questions about this Privacy Policy
                or wish to access or otherwise modify or rectify your personal
                data, you may contact our Data Protection Officer (DPO), by
                sending your communication to Gran V&iacute;a Carlos III, 124
                entr 1&ordm; 08034 Barcelona or by sending an e-mail to
                dpo@suprive.com.
              </p>
              <p>
                What rights do you have in relation to the processing of your
                data (ARSOPOL RIGHT)?
              </p>
              <p>- The right to request access to your personal data.</p>
              <p>- The right to request its rectification or deletion.</p>
              <p>- The right to request the limitation of their processing.</p>
              <p>- Right to object to the processing.</p>
              <p>- Right to data portability.</p>
              <p>- Right to withdraw the consent given.</p>
              <p>
                Access/correction and deletion: the persons concerned have the
                right to access their personal data, as well as to request the
                rectification of inaccurate data or, where appropriate, to
                request its deletion when, among other reasons, the data is no
                longer necessary for the performance of the contract.
              </p>
              <p>
                Limitation: in certain circumstances, interested parties may
                request the limitation of the processing of their data, in which
                case we will only keep them for the exercise or defense of
                claims.
              </p>
              <p>
                Opposition: data subjects may object to the processing of their
                data. SUPRIVE will stop processing the data, except for
                legitimate, compelling reasons, or the exercise or defense of
                possible claims, and we will keep them duly blocked for the
                corresponding period while the legal obligations persist.
              </p>
              <p>
                Likewise, the interested party has the right to oppose the
                adoption of automated individual decisions that could produce
                legal effects or significantly affect him/her.
              </p>
              <p>
                Portability: data subjects may request and receive the data
                concerning them that they have provided to us or request that we
                send it to another data controller of their choice, in a
                structured, commonly used and machine-readable format.
              </p>
              <p>When will we respond to your request?</p>
              <p>
                We will respond to your requests as soon as possible and in any
                event within one month of receiving your request. This period
                may be extended by a further two months if necessary, taking
                into account the complexity and number of requests. The data
                controller will inform the data subject of the extension within
                one month of the request.
              </p>
              <p>Do you have the right to withdraw your consent?</p>
              <p>
                The data subject has the right to withdraw the consent given at
                any time, without affecting the lawfulness of the processing
                based on the consent given at the time of subscriptions or
                provision of data.
              </p>
              <p>Where should you go to exercise your rights?</p>
              <p>
                To exercise your rights, the interested party may send a letter
                with all your data, including a photocopy of your ID card or
                passport and indicating the right being exercised, to SUPRIVE at
                Gran V&iacute;a Carlos III, 124, 1st floor, 08034 Barcelona, or
                via dpo@suprive.com.
              </p>
              <p>Do you have the right to make a claim?</p>
              <p>
                We inform you that if you submit a request for the exercise of
                these rights and consider that it has not been adequately
                addressed by our institution, you can file a complaint with the
                Spanish Data Protection Agency.
              </p>
              <h4>Electronic Communications</h4>
              <p>
                In compliance with Law 34/2002, of July 11, 2002, on Information
                Society Services and Electronic Commerce, we inform you that
                e-mail addresses may be used to send information about SUPRIVE.
                If you do not wish to receive information or want to revoke the
                consent given for the processing of your data please do so in
                the manner indicated above or by contacting the following email
                address dpo@suprive.com.
              </p>
              <h4>Updating our Privacy Policy</h4>
              <p>
                This Policy may be updated, revised or modified at any time, as
                well as the way in which we process your personal data. We will
                always have the most current version of the Privacy Policy
                available on our website at www.suprive.com. Any updates will be
                effective upon posting.
              </p>
              <h4>Additional Information</h4>
              <p>
                Upon request, in addition to the information contained in this
                Privacy Policy, the user may be provided with additional
                information about specific treatments, or the collection and
                processing of personal data.
              </p>
              <p>
                For more information on privacy in general, you can visit the
                website of the Spanish Data Protection Agency (AEPD) at
                www.aepd.es and of the Catalan Data Protection Authority
                (APDCAT) at www.apdcat.gencat.cat.
              </p>
              <p>REVISION AUGUST 2020</p>
            </div>
          </div>
        </div>
      </div>
      <Footer polygons={true} />
    </Layout>
  );
};

export default PoliticaDePrivacidadEn;
