import React from "react";
import Countdown from "react-countdown";
import { PromoDates, isBlackFriday } from "../../layout/Layout";
import party2 from "../../assets/imgs/blackfriday/party2.png";

const CountDownPromo = ({ lang }) => {
   const renderer = ({ formatted: { hours, minutes, seconds }, completed }) => {
      if (completed) {
         // Render a completed state
         // window.location.pathname = '/nutricion';
         return (
            <h2 className="text">
               {hours + "h "}
               {minutes + "m "}:{seconds + "s"}
            </h2>
         );
      } else {
         // Render a countdown
         let days = 0;
         if (hours > 24) {
           days = parseInt(hours / 24, 10);
           hours = (hours - (days * 24)) % 24;
         } 
         return (
            <h2 className="text" style={{fontSize: (isBlackFriday) ? '1.7em' : '', color: (isBlackFriday) ? '#fff' : ''}}>
               {lang === "es" && (
                  <>
                     {(days > 0) ? days + 'D : ' : ''}{hours + "h "}: {minutes + "m "}: {seconds + "s"}
                  </>
               )}
               {lang === "ru" && (
                  <>
                     {(days > 0) ? days + 'д : ' : ''}{hours + "ч "}: {minutes + "м "}: {seconds + "с"}
                  </>
               )}
               <img src={party2}  style={{display: (isBlackFriday) ? '' : 'none'}} width="20" hspace="6" className='mobn' />
            </h2>
         );
      }
   };

   const dateExpire = PromoDates.dateExpire;
   console.log(dateExpire);
   return (
      <Countdown
         // autoStart={false}
         date={dateExpire.getTime()}
         daysInHours
         // onComplete={(e) => window.location.reload()}
         renderer={renderer}
      />
   );
};

export default CountDownPromo;
