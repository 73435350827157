import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import MainEs from '../viewsEs/MainEs';

import MainRu from '../viewsRu/MainRu';
import MainRuAds from '../viewsRu/MainRu_Ads';

import PoliticaDeCookies from '../viewsEs/pages/cookies/PoliticaDeCookies';
import PoliticaDePrivacidad from '../viewsEs/pages/privacity/PoliticaDePrivacidad';
import TerminosYCondiciones from '../viewsEs/pages/terms/TerminosYCondiciones';
import Exclusion from '../viewsEs/pages/exclusion/ExclusionDeResponsabilidad';

import PoliticaDeCookiesEn from '../viewsRu/pages/cookies/PoliticaDeCookiesEn';
import PoliticaDePrivacidadEn from '../viewsRu/pages/privacity/PoliticaDePrivacidadEn';
import TerminosYCondicionesEn from '../viewsRu/pages/terms/TerminosYCondicionesEn';
import ExclusionEn from '../viewsRu/pages/exclusion/ExclusionDeResponsabilidadEn';

import ThankPage from '../viewsRu/thankPage/ThankPage';
import ThankPageEs from '../viewsEs/thankPage/ThankPage';

// LONG LANDINGS
// import MainEsLong from '../viewsEs/MainEsLong';
// import MainRuLong from '../viewsRu/MainRuLong';
// import MainRuLongAds from '../viewsRu/MainRuLong_Ads';

export const Router = () => {
  let pathEs = 'es';
  let pathRu = 'ru';
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={`/${pathEs}`} component={MainEs} />
        <Route exact path={`/${pathEs}-`} component={MainEs} />
        <Route exact path={`/${pathRu}`} component={MainRu} />
        <Route exact path={`/${pathRu}-`} component={MainRu} />
        <Route exact path={`/${pathRu}_`} component={MainRuAds} /> {/* ADS */}
        {/* LONG LANDINGS ROUTES */}
        {/* <Route exact path={`/-${pathEs}`} component={MainEsLong} /> */}
        {/* <Route exact path={`/-${pathRu}`} component={MainRuLong} /> */}
        {/* <Route exact path={`/-${pathRu}_`} component={MainRuLongAds} /> */}{' '}
        {/* ADS */}
        <Route
          exact
          path={`/${pathEs}/k4t-s4f4r0v4-th4nk-y0u`}
          component={ThankPageEs}
        />
        <Route
          exact
          path={`/${pathRu}/k4t-s4f4r0v4-th4nk-y0u`}
          component={ThankPage}
        />
        {/* LEGAL PAGES */}
        {/* ES */}
        <Route
          path={`/${pathEs}/politica-de-cookies`}
          exact
          render={(props) => <PoliticaDeCookies {...props} />}
        />
        <Route
          path={`/${pathEs}/politica-de-privacidad`}
          exact
          render={(props) => <PoliticaDePrivacidad {...props} />}
        />
        <Route
          path={`/${pathEs}/terminos-y-condiciones`}
          exact
          render={(props) => <TerminosYCondiciones {...props} />}
        />
        <Route
          path={`/${pathEs}/exclusion-de-responsabilidad`}
          exact
          render={(props) => <Exclusion {...props} />}
        />
        {/* EN */}
        <Route
          path={`/${pathRu}/cookies-policy`}
          exact
          render={(props) => <PoliticaDeCookiesEn {...props} />}
        />
        <Route
          path={`/${pathRu}/privacy-policy`}
          exact
          render={(props) => <PoliticaDePrivacidadEn {...props} />}
        />
        <Route
          path={`/${pathRu}/terms-of-use`}
          exact
          render={(props) => <TerminosYCondicionesEn {...props} />}
        />
        <Route
          path={`/${pathRu}/liability-exclusion`}
          exact
          render={(props) => <ExclusionEn {...props} />}
        />
        <Redirect to={`/${pathRu}`} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
