import gsap from 'gsap';

const MagneticArea = () => {
  let mWrap = document.querySelectorAll('.magnetic-wrap');

  function parallaxIt(e, wrap, movement = 0.05) {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let boundingRect = wrap.mArea.getBoundingClientRect();
    // let halfDiff = Math.abs(boundingRect.width - boundingRect.height) / 2;
    let relX = e.pageX - boundingRect.left;
    let relY = e.pageY - boundingRect.top;

    gsap.to(wrap.mContent, {
      x: (relX - boundingRect.width / 2) * movement,
      y: (relY - boundingRect.height / 2 - scrollTop) * movement,
      ease: 'power1',
      duration: 1,
    });
  }

  mWrap.forEach(function (wrap) {
    wrap.mContent = wrap.querySelector('.js-magnetic-content');
    wrap.mArea = wrap.querySelector('.js-magnetic-area');

    wrap.mArea.addEventListener('mousemove', function (e) {
      parallaxIt(e, wrap);
    });

    wrap.mArea.addEventListener('mouseleave', function (e) {
      gsap.to(wrap.mContent, {
        x: 0,
        y: 0,
        ease: 'power3',
        duration: 1,
      });
    });
  });
};

export default MagneticArea;
