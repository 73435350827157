import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Nav, Button, Dropdown } from 'react-bootstrap';
// import { isPromoActive, ScrollContext } from '../../layout/Layout';

import { ScrollContext } from '../../../layout/Layout';

export const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua);
};

export const CustomNavbarThank = (props) => {
  const getScroll = useContext(ScrollContext);
  const [navExpanded, setNavExpanded] = useState(false);
  const [dropShow, setDropShow] = useState(false);

  const [navColor, setNavColor] = useState('transparent');
  // const [navLogo, setNavLogo] = useState(logo2);

  useEffect(() => {
    // setTimeout(
    //   () =>
    window.addEventListener('DOMContentLoaded', (event) => {
      getScroll().on('scroll', (instance) => {
        if (instance.direction) {
          setNavExpanded(false);
          setDropShow(false);
        }
        if (instance.scroll.y < 50) {
          document.querySelector('#navbar').classList.remove('navbarScrolled');
          setTimeout(() => {
            // document.querySelector('.navBrand').style.mixBlendMode = 'unset';
          }, 300);
        } else {
          document.querySelector('#navbar').classList.add('navbarScrolled');
        }
      });
    });
    //   100
    // );
  }, []);

  const collapsetoggle = () => {
    setNavExpanded(!navExpanded);

    if (!navExpanded) {
      document.querySelector('.navBrand').style.mixBlendMode = 'difference';
      document.querySelector('.navbar-toggler').style.mixBlendMode =
        'difference';
    } else {
      setTimeout(() => {
        document.querySelector('.navBrand').style.mixBlendMode = 'unset';
        document.querySelector('.navbar-toggler').style.mixBlendMode = 'unset';
      }, 300);
    }
  };

  return (
    <Navbar
      id="navbar"
      collapseOnSelect
      expand="lg"
      className={
        // isPromoActive().isActive
        //   ? 'nav-color-scroll nav-promo'
        // :
        'nav-color-scroll navbarThank'
      }
      variant="light"
      fixed="top"
      expanded={navExpanded}
      onToggle={collapsetoggle}
      // data-scroll
      // data-scroll-sticky
      // data-scroll-target="#inicio"
      // style={isMobile() ? { background: navColor } : ''}
    >
      <Navbar.Brand className="navBrand" id="navbarBrandContainer">
        {/* <img alt="" src={navLogo} className="navbarLogo" /> */}
        <svg
          width="314"
          height="37"
          viewBox="0 0 314 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="navbarLogo"
        >
          <path
            d="M22.1512 28.3888H14.4035L6.8811 15.4196V28.3888H0V2.80038H6.8811V15.1921L14.1262 2.80038H21.6486L13.8315 15.017L22.1512 28.3888Z"
            fill="#F3F3F3"
          />
          <path
            d="M37.1614 28.3888L35.8615 25.7635H35.7228C34.8099 26.907 33.8797 27.6887 32.9322 28.1088C31.9847 28.5289 30.7598 28.7389 29.2577 28.7389C27.4088 28.7389 25.9529 28.1788 24.8898 27.0587C23.8267 25.9385 23.2952 24.3633 23.2952 22.333C23.2952 20.2211 24.0232 18.6517 25.4791 17.6249C26.9351 16.5864 29.0439 16.003 31.8056 15.8746L35.0815 15.7696V15.4896C35.0815 13.856 34.2842 13.0393 32.6896 13.0393C31.2567 13.0393 29.4368 13.5293 27.2297 14.5095L25.2711 9.99385C27.5591 8.80369 30.4479 8.20861 33.9375 8.20861C36.4565 8.20861 38.4036 8.83869 39.7786 10.0989C41.1653 11.359 41.8586 13.1209 41.8586 15.3846V28.3888H37.1614ZM32.1349 23.9082C32.9553 23.9082 33.6544 23.6457 34.2322 23.1206C34.8215 22.5956 35.1161 21.913 35.1161 21.0728V19.5326L33.5562 19.6026C31.326 19.6843 30.211 20.5128 30.211 22.088C30.211 23.3015 30.8523 23.9082 32.1349 23.9082Z"
            fill="#F3F3F3"
          />
          <path
            d="M64.6858 13.6518H58.2207V28.3888H51.4436V13.6518H44.9785V8.55866H64.6858V13.6518Z"
            fill="#F3F3F3"
          />
          <path
            d="M73.9242 8.55866V13.5468C73.9242 13.8269 73.9184 14.1302 73.9068 14.457C73.9068 14.7837 73.8317 16.8606 73.6815 20.6878L81.5332 8.55866H89.021V28.3888H82.9892V23.0156C82.9892 21.137 83.0701 18.9142 83.2318 16.3472L75.4148 28.3888H67.8924V8.55866H73.9242Z"
            fill="#F3F3F3"
          />
          <path
            d="M114.621 8.10359C112.992 8.10359 111.721 8.78035 110.808 10.1339C109.895 11.4757 109.439 13.331 109.439 15.6996C109.439 20.6236 111.294 23.0856 115.003 23.0856C116.124 23.0856 117.21 22.9281 118.261 22.6131C119.313 22.298 120.37 21.9188 121.433 21.4754V27.3212C119.319 28.2663 116.927 28.7389 114.257 28.7389C110.433 28.7389 107.498 27.6187 105.452 25.3784C103.419 23.1381 102.402 19.9002 102.402 15.6646C102.402 13.0159 102.893 10.6881 103.875 8.68117C104.869 6.67424 106.29 5.13403 108.139 4.06055C109.999 2.9754 112.183 2.43283 114.691 2.43283C117.429 2.43283 120.047 3.03374 122.543 4.23557L120.445 9.67881C119.509 9.23541 118.573 8.86203 117.637 8.55866C116.701 8.25528 115.696 8.10359 114.621 8.10359Z"
            fill="#F3F3F3"
          />
          <path
            d="M138.801 28.3888L137.501 25.7635H137.362C136.449 26.907 135.519 27.6887 134.571 28.1088C133.624 28.5289 132.399 28.7389 130.897 28.7389C129.048 28.7389 127.592 28.1788 126.529 27.0587C125.466 25.9385 124.934 24.3633 124.934 22.333C124.934 20.2211 125.662 18.6517 127.118 17.6249C128.574 16.5864 130.683 16.003 133.445 15.8746L136.721 15.7696V15.4896C136.721 13.856 135.923 13.0393 134.329 13.0393C132.896 13.0393 131.076 13.5293 128.869 14.5095L126.91 9.99385C129.198 8.80369 132.087 8.20861 135.577 8.20861C138.096 8.20861 140.043 8.83869 141.418 10.0989C142.804 11.359 143.498 13.1209 143.498 15.3846V28.3888H138.801ZM133.774 23.9082C134.595 23.9082 135.294 23.6457 135.871 23.1206C136.461 22.5956 136.755 21.913 136.755 21.0728V19.5326L135.195 19.6026C132.965 19.6843 131.85 20.5128 131.85 22.088C131.85 23.3015 132.492 23.9082 133.774 23.9082Z"
            fill="#F3F3F3"
          />
          <path
            d="M175.026 18.5C175.026 21.3704 174.102 23.6632 172.253 25.3784C170.404 27.0937 167.775 28.0971 164.366 28.3888V37H158.023V28.3888C154.672 28.0388 152.037 26.9945 150.119 25.2559C148.212 23.5057 147.259 21.2537 147.259 18.5C147.259 15.4896 148.16 13.1384 149.963 11.4465C151.766 9.74298 154.423 8.78035 157.936 8.55866V1.15516H164.366V8.55866C167.752 8.86203 170.375 9.88884 172.235 11.6391C174.096 13.3893 175.026 15.6763 175.026 18.5ZM153.967 18.5C153.967 19.8652 154.342 21.0028 155.093 21.913C155.856 22.8114 156.804 23.2723 157.936 23.2956V13.6518C156.85 13.6985 155.914 14.1944 155.128 15.1395C154.354 16.0847 153.967 17.2048 153.967 18.5ZM168.266 18.5C168.266 17.1698 167.891 16.0438 167.14 15.122C166.4 14.1886 165.476 13.6985 164.366 13.6518V23.2956C165.499 23.2723 166.429 22.8172 167.157 21.9305C167.897 21.032 168.266 19.8885 168.266 18.5Z"
            fill="#F3F3F3"
          />
          <path
            d="M191.665 28.3888L190.366 25.7635H190.227C189.314 26.907 188.384 27.6887 187.436 28.1088C186.489 28.5289 185.264 28.7389 183.762 28.7389C181.913 28.7389 180.457 28.1788 179.394 27.0587C178.331 25.9385 177.799 24.3633 177.799 22.333C177.799 20.2211 178.527 18.6517 179.983 17.6249C181.439 16.5864 183.548 16.003 186.31 15.8746L189.586 15.7696V15.4896C189.586 13.856 188.788 13.0393 187.194 13.0393C185.761 13.0393 183.941 13.5293 181.734 14.5095L179.775 9.99385C182.063 8.80369 184.952 8.20861 188.442 8.20861C190.961 8.20861 192.908 8.83869 194.283 10.0989C195.669 11.359 196.363 13.1209 196.363 15.3846V28.3888H191.665ZM186.639 23.9082C187.459 23.9082 188.158 23.6457 188.736 23.1206C189.326 22.5956 189.62 21.913 189.62 21.0728V19.5326L188.06 19.6026C185.83 19.6843 184.715 20.5128 184.715 22.088C184.715 23.3015 185.356 23.9082 186.639 23.9082Z"
            fill="#F3F3F3"
          />
          <path
            d="M212.759 28.7389C211.766 28.7389 210.876 28.5755 210.09 28.2488C209.304 27.9104 208.524 27.3095 207.75 26.4461H207.438C207.646 27.8346 207.75 28.698 207.75 29.0364V37H200.973V8.55866H206.485L207.438 11.079H207.75C209.01 9.16541 210.749 8.20861 212.967 8.20861C215.14 8.20861 216.844 9.11873 218.081 10.939C219.317 12.7592 219.935 15.2562 219.935 18.43C219.935 21.6271 219.288 24.1474 217.994 25.991C216.711 27.8229 214.966 28.7389 212.759 28.7389ZM210.489 13.5818C209.495 13.5818 208.796 13.9436 208.392 14.667C207.999 15.3787 207.785 16.4406 207.75 17.8524V18.395C207.75 20.0985 207.976 21.3295 208.426 22.088C208.877 22.8464 209.588 23.2256 210.558 23.2256C211.425 23.2256 212.055 22.8523 212.447 22.1055C212.84 21.3471 213.037 20.0985 213.037 18.36C213.037 16.6564 212.84 15.4371 212.447 14.702C212.055 13.9552 211.402 13.5818 210.489 13.5818Z"
            fill="#F3F3F3"
          />
          <path
            d="M242.572 18.43C242.572 21.6738 241.705 24.2058 239.972 26.026C238.25 27.8346 235.835 28.7389 232.727 28.7389C229.746 28.7389 227.371 27.8113 225.603 25.956C223.847 24.1008 222.968 21.5921 222.968 18.43C222.968 15.1979 223.829 12.6892 225.551 10.904C227.284 9.10706 229.711 8.20861 232.831 8.20861C234.76 8.20861 236.465 8.62283 237.944 9.45128C239.423 10.2797 240.561 11.4699 241.358 13.0218C242.167 14.562 242.572 16.3647 242.572 18.43ZM229.85 18.43C229.85 20.1336 230.075 21.4346 230.526 22.333C230.976 23.2198 231.733 23.6632 232.796 23.6632C233.848 23.6632 234.587 23.2198 235.015 22.333C235.454 21.4346 235.673 20.1336 235.673 18.43C235.673 16.7381 235.454 15.4604 235.015 14.597C234.576 13.7335 233.825 13.3018 232.761 13.3018C231.721 13.3018 230.976 13.7335 230.526 14.597C230.075 15.4488 229.85 16.7264 229.85 18.43Z"
            fill="#F3F3F3"
          />
          <path
            d="M265.694 13.6868C265.694 14.842 265.359 15.7988 264.688 16.5572C264.018 17.3157 263.1 17.7941 261.932 17.9924V18.1325C263.342 18.2958 264.423 18.7859 265.174 19.6026C265.925 20.4078 266.3 21.4462 266.3 22.7181C266.3 26.4986 263.25 28.3888 257.149 28.3888H246.454V8.55866H257.183C260.037 8.55866 262.169 8.97871 263.579 9.81883C264.989 10.6589 265.694 11.9483 265.694 13.6868ZM259.419 22.053C259.419 21.4346 259.211 20.9912 258.795 20.7228C258.379 20.4544 257.778 20.3202 256.993 20.3202H253.231V23.9082H256.923C258.587 23.9082 259.419 23.2898 259.419 22.053ZM258.951 14.597C258.951 13.5585 258.327 13.0393 257.079 13.0393H253.231V16.0147H256.819C257.536 16.0147 258.067 15.8805 258.414 15.6121C258.772 15.3437 258.951 15.0054 258.951 14.597Z"
            fill="#F3F3F3"
          />
          <path
            d="M288.989 18.43C288.989 21.6738 288.122 24.2058 286.389 26.026C284.667 27.8346 282.252 28.7389 279.144 28.7389C276.163 28.7389 273.788 27.8113 272.02 25.956C270.264 24.1008 269.386 21.5921 269.386 18.43C269.386 15.1979 270.246 12.6892 271.968 10.904C273.701 9.10706 276.128 8.20861 279.248 8.20861C281.178 8.20861 282.882 8.62283 284.361 9.45128C285.84 10.2797 286.978 11.4699 287.776 13.0218C288.584 14.562 288.989 16.3647 288.989 18.43ZM276.267 18.43C276.267 20.1336 276.492 21.4346 276.943 22.333C277.393 23.2198 278.15 23.6632 279.213 23.6632C280.265 23.6632 281.004 23.2198 281.432 22.333C281.871 21.4346 282.09 20.1336 282.09 18.43C282.09 16.7381 281.871 15.4604 281.432 14.597C280.993 13.7335 280.242 13.3018 279.179 13.3018C278.139 13.3018 277.393 13.7335 276.943 14.597C276.492 15.4488 276.267 16.7264 276.267 18.43Z"
            fill="#F3F3F3"
          />
          <path
            d="M298.903 8.55866V13.5468C298.903 13.8269 298.897 14.1302 298.886 14.457C298.886 14.7837 298.811 16.8606 298.661 20.6878L306.512 8.55866H314V28.3888H307.968V23.0156C307.968 21.137 308.049 18.9142 308.211 16.3472L300.394 28.3888H292.871V8.55866H298.903ZM303.67 6.6684C300.908 6.6684 298.84 6.12583 297.465 5.04068C296.101 3.95553 295.338 2.27531 295.177 0H300.827C300.885 1.14349 301.133 1.97193 301.572 2.48534C302.012 2.99874 302.711 3.25544 303.67 3.25544C305.403 3.25544 306.35 2.17029 306.512 0H312.249C311.776 4.4456 308.916 6.6684 303.67 6.6684Z"
            fill="#F3F3F3"
          />
        </svg>
      </Navbar.Brand>
    </Navbar>
  );
};

export default CustomNavbarThank;
