import React from 'react';
import { Row } from 'react-bootstrap';
import InfoSection from '../../sections/infoSection/InfoSection';

const Info = () => {
  return (
    <Row
      className="RootInfo RootContainers sectionsPadding sectionPolygon sectionPolygonBottomCenter"
      id="info"
    >
      <div className="sectionPolygon1 spb" />
      <div className="sectionPolygon2 spb" />
      <InfoSection />
    </Row>
  );
};

export default Info;
