import React from 'react';
import { Table } from 'react-bootstrap';
import Footer from '../../../components/footer/Footer';
import Layout from '../../../layout/Layout';

export const PoliticaDeCookiesEn = () => {
  return (
    <Layout>
      <div className="RootLegals">
        <div className="first-section cookiesContainer">
          <div className="container cookiesContent">
            <div>
              <header>
                <h2>Cookie Policy</h2>
              </header>
              <div>
                <div>
                  <p>
                    This website uses third party and own cookies to give you a
                    better experience. to give you a better browsing experience
                    browsing experience, you can share content on social
                    networks social networks and so that we can obtain
                    statistics of users.
                  </p>
                  <p>
                    You can prevent the downloading of cookies through your
                    browser settings by browser settings, preventing cookies
                    from being stored on your device. cookies from being stored
                    on your device.
                  </p>
                  <p>
                    As the owner of this web site, we do not use any personal
                    information from we do not use any personal information from
                    cookies, we only use statistics cookies, we only make
                    general statistics of visits that do not general statistics
                    of visits that do not involve any personal information.
                  </p>
                  <p>
                    It is very important that you read this cookie policy and
                    understand that, if you continue to cookies and understand
                    that, if you continue browsing, we will consider that you
                    accept its use.
                  </p>
                  <p>
                    According to the terms included in article 22.2 of the
                    Article 22.2 of Law 34/2002 on Information Society Services
                    and Commerce Information Society and Electronic Commerce
                    Services Electronic, if you continue browsing, you will be
                    giving your consent to the use of the aforementioned the
                    aforementioned mechanisms.
                  </p>
                  <h4>Responsible Entity</h4>
                  <p>
                    The entity responsible for the collection, processing and
                    use of your personal data, in the sense established by the
                    Personal Data Protection Act is the website{' '}
                    <a href="https://katsafarova.com/">
                      https://katsafarova.com/
                    </a>
                    , owned by Suprive Media SL - Av. Carles III, 124 Entr.
                    1era, 08034, Barcelona, Spain.
                  </p>
                  <h4>What are cookies?</h4>
                  <p>
                    Cookies are a set of data that a server places on a user's
                    browser to server deposits in the user's browser to collect
                    the Internet's standard standard Internet log information
                    and visitor behavior information. information about visitor
                    behavior on a website. on a website. In other words, they
                    are small text files that are stored on a website. text
                    files that are stored on the computer's hard disk and are
                    used to identify and that serve to identify the user when
                    he/she connects again to the when he/she connects to the
                    website again. Their purpose is to record the user's visit
                    and store certain information information. Its use is common
                    and frequent on the web as it allows the web since it allows
                    the pages to work more efficiently. more efficiently and
                    achieve greater personalization. personalization and
                    analysis of user behavior. user behavior.
                  </p>
                  <h4>What types of cookies are there?</h4>
                  <p>
                    The cookies used on our website, are session and third party
                    cookies, and allow us to store and access information
                    related to language, type of browser used, and other general
                    characteristics predefined by the user, as well as to track
                    and analyze the activity carried out, in order to make
                    improvements and provide our services in a more efficient
                    and personalized way.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    Cookies, depending on their permanence, can be divided into
                    session or permanent cookies. divided into session or
                    permanent cookies. Those that expire when the user closes
                    the browser. Those that expire depending on when the purpose
                    for which they are used (for example for which they are
                    intended (for example, to keep the user identified in the
                    user to remain identified in the services of Suprive Media
                    SL) or when they are manually deleted.
                  </p>
                  <Table striped bordered hover>
                    <tbody>
                      <tr>
                        <td>
                          <p>Name</p>
                        </td>
                        <td>
                          <p>Type</p>
                        </td>
                        <td>
                          <p>Caducity</p>
                        </td>
                        <td>
                          <p>Purpose</p>
                        </td>
                        <td>
                          <p>Class</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>__utma</p>
                        </td>
                        <td>
                          <p>From Third Parties (Google Analytics)</p>
                        </td>
                        <td>
                          <p>2 years</p>
                        </td>
                        <td>
                          <p>Used to distinguish users and sessions</p>
                        </td>
                        <td>
                          <p>Not Exempt</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>__utmb</p>
                        </td>
                        <td>
                          <p>From Third Parties (Google Analytics)</p>
                        </td>
                        <td>
                          <p>30 minutes</p>
                        </td>
                        <td>
                          <p>Used to determine new sessions or visits</p>
                        </td>
                        <td>
                          <p>Not Exempt</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>__utmc</p>
                        </td>
                        <td>
                          <p>From Third Parties (Google Analytics)</p>
                        </td>
                        <td>
                          <p>At the end of the session</p>
                        </td>
                        <td>
                          <p>It is configured for use with Urchin</p>
                        </td>
                        <td>
                          <p>Not Exempt</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>__utmz</p>
                        </td>
                        <td>
                          <p>From Third Parties (Google Analytics)</p>
                        </td>
                        <td>
                          <p>6 months</p>
                        </td>
                        <td>
                          <p>
                            Stores the source or the campaign that explains how
                            the user got to the website. web page
                          </p>
                        </td>
                        <td>
                          <p>Not Exempt</p>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <p>
                    Additionally, depending on their purpose, cookies can be
                    classified as follows:
                  </p>
                  <h4>Performance cookies</h4>
                  <p>
                    This type of Cookie remembers your preferences for the tools
                    found in the services, so you don't have to reconfigure the
                    you do not have to reconfigure the service each time you
                    visit. you visit. By way of example, in this typology
                    include. include: Volume settings of video or audio players
                    Volume settings of video or sound players. Video or audio
                    player volume settings. Video streaming speeds that are
                    compatible with your browser. The items stored in the
                    "shopping cart"; in e-commerce services such as stores.
                  </p>
                  <h4>Geo-location cookies</h4>
                  <p>
                    These cookies are used to find out in which country you are
                    located; country you are in when you request a service. This
                    cookie is completely anonymous, and is only used to help
                    target content. only used to help target content to your
                    location. location.
                  </p>
                  <h4>Registration cookies</h4>
                  <p>
                    Registration cookies are generated once the user has
                    registered or subsequently logged in has registered or
                    subsequently logged in, and are used to identify you in the
                    services for the following purposes. following purposes:
                  </p>
                  <p>
                    To keep the user identified so that, if you close a service,
                    browser or computer service, the navigator or the computer
                    and at another time or another day another day re-enters the
                    service, remain identified, thus facilitating the user's
                    navigation without having to log in again. This
                    functionality can be removed if the user clicks on the
                    [logout functionality [close session], so that this cookie
                    is deleted and the cookie is deleted and the next time the
                    user logs on to the service the user will have to log in to
                    be identified. to be identified.
                  </p>
                  <p>
                    Check whether the user is authorized to access certain
                    services, e.g., for certain services, for example, to
                    participate in a contest. contest.
                  </p>
                  <p>
                    Additionally, some services may use connectors with social
                    networks such as Facebook or Twitter. When the user
                    registers in a service with credentials from a social
                    network, he/she authorizes the social network to social
                    network, authorizes the social network to store a persistent
                    Cookie that remembers your that remembers your identity and
                    grants you access to the services until it expires. to the
                    services until it expires. The user can delete this Cookie
                    and revoke access to the services via social networks by
                    social networks by updating your preferences on the social
                    network social network you specify.
                  </p>
                  <h4>Analytics cookies</h4>
                  <p>
                    Each time a user visits a service, a tool from a third-party
                    from a third-party vendor generates an analytics cookie on
                    the user's on the user's computer. This cookie, which is
                    only generated on the visit only generated during the visit,
                    will be used for future visits to Suprive Media's visits to
                    Suprive Media SL services in order to anonymously identify
                    anonymously identify the visitor. The main objectives The
                    main objectives pursued are:
                  </p>
                  <p>
                    To allow the anonymous identification of the browsing users
                    through the users browsers through the cookie (identifies
                    browsers and devices browsers and devices, not people) and
                    therefore the accounting approximate counting of the number
                    of visitors and their trend over time. visitors and their
                    trend over time.
                  </p>
                  <p>
                    Identify anonymously the most visited contents. most visited
                    and therefore more attractive to users. to users Know if the
                    user who is accessing the site is new or a repeat visitor;
                    accessing is new or repeating visit.
                  </p>
                  <p>
                    Important: Unless the user chooses to register with a
                    service of Suprive Media SL, the cookie will never be
                    associated with any user; associated with any personal data
                    that can identify you. that can identify you. These cookies
                    will only be used by Suprive Media SL. will only be used for
                    statistical purposes that are statistical purposes to help
                    optimize the user experience on the site. the user
                    experience on the site.
                  </p>
                  <h4>Advertising cookies</h4>
                  <p>
                    This type of cookies allow you to expand the information of
                    the advertisements displayed to each anonymous user in the
                    the services of Suprive Media SL. Among others, it stores
                    the duration or frequency of visualization of advertising
                    positions, interaction advertising positions, the
                    interaction with them, or browsing patterns or browsing
                    patterns and / or user behaviors as they help behavior of
                    the user, as they help to form a profile of the user's
                    profile of advertising interest. In this way, thus make it
                    possible to offer advertising tailored to the user's
                    interests. user's interests.
                  </p>
                  <h4>Third-party advertising cookies</h4>
                  <p>
                    In addition to the advertising managed by the websites of
                    Suprive Media SL in its services, the Suprive Media SL
                    websites offer advertisers the SL offer their advertisers
                    the option to serve ads through advertisements through third
                    parties ("Ad-Servers"). In this way, these third parties may
                    store cookies sent from the services of Suprive Media S.L.
                    services from users' browsers, as well as browsers, as well
                    as access the data stored in them. are stored.
                  </p>
                  <p>
                    The companies that generate these cookies have their own
                    privacy policies. privacy policies. At present, the websites
                    websites of Suprive Media SL use the Doubleclick platform
                    (Google) to manage these services. For more for more
                    information, go to
                  </p>
                  <p>
                    <a href="http://www.google.es/policies/privacy/ads/#toc-doubleclick">
                      http://www.google.es/policies/privacy/ads/#toc-doubleclick{' '}
                    </a>
                    y a{' '}
                    <a href="http://www.google.es/policies/privacy/ads/">
                      http://www.google.es/policies/privacy/ads/
                    </a>
                    .
                  </p>
                  <h4>How can I disable cookies in my browser? browser?</h4>
                  <p>
                    Different browsers can be configured to warn the user of the
                    receipt of cookies the user of the reception of cookies and,
                    if you wish, prevent their installation on the computer.
                    Likewise, the user can user can check in his browser which
                    cookies he has installed installed and what is the expiry
                    date of the same, being able to delete them. the same, being
                    able to eliminate them.
                  </p>
                  <p>
                    For more information on this information, please consult the
                    instructions and manuals of your browser:
                  </p>
                  <p>
                    For more information about the management of cookies in
                    Google Chrome:
                    <br />
                    <a href="https://support.google.com/chrome/answer/95647?hl=es">
                      https://support.google.com/chrome/answer/95647?hl=es
                    </a>
                  </p>
                  <p>
                    For more information about cookie management in Internet
                    Explorer
                    <br />
                    <a href="http://windows.microsoft.com/es-es/windows-vista/cookies-frequently-asked-questions">
                      http://windows.microsoft.com/es-es/windows-vista/cookies-frequently-asked-questions
                    </a>
                  </p>
                  <p>
                    For more information about cookie management in Mozilla
                    Firefox:
                    <br />
                    <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we%20">
                      http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we
                    </a>
                  </p>
                  <p>
                    For more information about cookie management in Safari:
                    <br />
                    <a href="http://www.apple.com/es/privacy/use-of-cookies/%20">
                      http://www.apple.com/es/privacy/use-of-cookies/
                    </a>
                  </p>
                  <p>
                    For more information about cookie management in Opera:
                    <br />
                    <a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">
                      http://help.opera.com/Windows/11.50/es-ES/cookies.html
                    </a>
                  </p>
                  <p>
                    If you wish to stop being tracked by Google Analytics visit:
                    <br />
                    <a href="http://tools.google.com/dlpage/gaoptout">
                      http://tools.google.com/dlpage/gaoptout
                    </a>
                  </p>
                  <h4>To learn more about cookies</h4>
                  <p>
                    You can find out more about online behavioral online
                    behavioral advertising and online privacy online privacy at
                    the following link:
                    <br />
                    <a href="http://www.youronlinechoices.com/es/">
                      http://www.youronlinechoices.com/es/
                    </a>
                  </p>
                  <p>
                    Google Analytics Data Protection:
                    <br />
                    <a href="http://www.google.com/analytics/learn/privacy.html">
                      http://www.google.com/analytics/learn/privacy.html
                    </a>
                  </p>
                  <p>
                    How Google Analytics uses cookies:
                    <br />
                    <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es#analyticsjs">
                      https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es#analyticsjs
                    </a>
                  </p>
                  <h4>
                    Updates and changes to the privacy/cookies policy
                    privacy/cookies
                  </h4>
                  <p>
                    Suprive Media SL websites may modify this Cookies Policy
                    according to legislative requirements, regulations, or in
                    order to adapt this policy to the instructions issued by the
                    Spanish Data Protection Agency, so users are advised to
                    visit it periodically.
                  </p>
                  <p>
                    When significant changes are made to this Cookie Policy
                    Cookies Policy, these will be communicated to users either
                    through the website or through users either through the web
                    or via e-mail to registered users. email to registered
                    users.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer polygons={true} />
    </Layout>
  );
};

export default PoliticaDeCookiesEn;
