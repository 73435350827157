import React from 'react';
import Footer from '../../../components/footer/Footer';
import Layout from '../../../layout/Layout';

export const TerminosYCondicionesEn = () => {
  return (
    <Layout>
      <div className="RootLegals">
        <div className="first-section termsContainer">
          <div className="container termsContent">
            <div>
              <h2>LEGAL NOTICE - TERMS OF USE - CONTRACTING CONDITIONS</h2>

              <p>
                This section includes information on the conditions of access
                and use of this website that must be known by the user. General
                information on www.suprive.com , for the purposes provided for
                in Law 34/2002 of Services of the Information Society and
                Electronic Commerce.
              </p>

              <p>Legal Notice</p>

              <p>
                SUPRIVE MEDIA SL, is domiciled at Gran V&iacute;a Carlos III,
                124 entr 1&ordm; 08034 Barcelona with CIF B67480699.
              </p>

              <p>The website is the property of SUPRIVE MEDIA SL.</p>

              <p>
                Use of the website confers the status of user and implies
                acceptance of all the conditions included in this legal notice.
                Accessing the website and using the materials it contains
                implies that the user has read and accepts, without reservation,
                these conditions:
              </p>

              <h4>1. Terms of use</h4>

              <p>
                1.1. The website contains material prepared by Suprive for
                information purposes only. The user must take into account that
                this material may not reflect the most recent status on the
                issues analyzed. Furthermore, this material may be modified,
                developed or updated without prior notification by Suprive.
              </p>

              <p>
                1.2. The purpose of the material contained on the Website is to
                publicize the activities and services provided by Suprive. We
                work to ensure that the information and services offered comply
                with the necessary completeness, accuracy, updating,
                accessibility and usability. In this regard, when consulting the
                website, the date of updating shown in each case must be borne
                in mind. The information that can be offered through this
                website does not replace in any case the publicity of laws,
                general provisions and administrative acts that must be
                published in newspapers, official gazettes or in the system
                determined by Suprive.
              </p>

              <p>
                1.3. The links contained on the Website may lead the user to
                other websites, web pages and/or applications managed by third
                parties, over which Suprive exercises no control whatsoever.
                Suprive is not responsible for the content or the status of such
                sites, web pages and applications, and accessing them through
                the website or the application does not imply that Suprive
                recommends or approves of their content.
              </p>

              <p>
                With regard to the images of persons appearing on the Website,
                Suprive publishes them with respect for the right to one's own
                image. These images are used exclusively for the purpose of
                composing graphic files or videos produced to inform and
                publicize Suprive's activities. They may be transferred to third
                parties.
              </p>

              <p>
                1.4. Suprive has implemented appropriate security measures to
                keep the Website and its servers free of any malware. Secure
                access to the website (as in the case of intranets) is via the
                HTTPS protocol.
              </p>

              <p>
                1.5. Suprive, from its website, will display a hyperlink to its
                profiles on Facebook, Twitter, YouTube, LinkedIn, Instagram,
                etc., with the aim of publicizing the social networks on which
                it is present. Once access is gained to any of these social
                networks, the user is subject to the policies established by
                each service.
              </p>

              <p>
                1.6. Suprive makes it possible to receive news published on its
                website via social networks.
              </p>

              <p>LIABILITY ARISING FROM THE USE OF THE WEBSITE</p>

              <p>
                SUPRIVE shall not be liable, in any case, for damages of any
                nature that may cause, but not limited to: errors or omissions
                in the content, lack of availability of the portal or the
                transmission of viruses or malicious or harmful programs in the
                content, despite having taken all the necessary technological
                measures to prevent it.
              </p>

              <p>
                From the client's website it is possible that it may redirect to
                contents of third party websites. Since the provider can not
                always control the content posted by third parties on their
                websites, it does not assume any responsibility for such
                content. In any case, the provider states that it will proceed
                to the immediate removal of any content that could violate
                national or international law, morality or public order,
                proceeding to the immediate withdrawal of the redirection to
                that website, informing the competent authorities of the content
                in question.
              </p>

              <p>
                SUPRIVE is not responsible for the information and content
                stored, including but not limited to, forums, chats, blogs,
                comments, social networks or any other means that allows third
                parties to independently publish content on the Web. However,
                SUPRIVE is available to all users, authorities and security
                forces, and actively collaborating in the removal or blocking of
                all content that could affect or contravene national or
                international law, third party rights or morality and public
                order. In the event that the user considers that there is on the
                Web any content that could be susceptible to this
                classification, please notify immediately to the administrator
                of the Web.
              </p>

              <p>
                The Web has been reviewed and tested so that it works correctly.
                In principle, correct operation can be guaranteed 24 hours a
                day, 7 days a week, all year round. However, SUPRIVE does not
                rule out the possibility of certain programming errors, or the
                occurrence of force majeure, health pandemics, natural
                disasters, strikes, or similar circumstances that make it
                impossible to access the Website.
              </p>

              <p>
                It is forbidden to introduce hyperlinks for commercial purposes
                on websites outside SUPRIVE that allow access to the Web,
                without the prior consent of SUPRIVE. SUPRIVE is not responsible
                for the use or content of third party websites that may link to
                its site.
              </p>

              <h4>
                2. Limitation of liability arising from use of the Website.
              </h4>

              <p>
                2.1 SUPRIVE shall not be liable under any circumstances for
                damages of any kind that may cause, but not limited to: errors
                or omissions in the content, lack of availability of the portal
                or the transmission of viruses or malicious or harmful programs
                in the content, despite having taken all necessary technological
                measures to prevent it.
              </p>

              <p>
                From the client's website it is possible that it may redirect to
                contents of third party websites. Since the provider can not
                always control the content posted by third parties on their
                websites, it does not assume any responsibility for such
                content. In any case, the provider states that it will
                immediately remove any content that could violate national or
                international law, morality or public order, proceeding to the
                immediate withdrawal of the redirection to that website,
                informing the competent authorities of the content in question.
              </p>

              <p>
                SUPRIVE is not responsible for the information and content
                stored, including but not limited to, forums, chats, blogs,
                comments, social networks or any other means that allows third
                parties to publish content independently on the Web. However,
                SUPRIVE is available to all users, authorities and security
                forces, and actively collaborating in the removal or blocking of
                all content that could affect or contravene national or
                international law, third party rights or morality and public
                order. In the event that the user considers that there is on the
                Web any content that could be susceptible to this
                classification, please notify immediately to the administrator
                of the Web.
              </p>

              <p>
                The Web has been reviewed and tested so that it works correctly.
                In principle, correct operation can be guaranteed 24 hours a
                day, 7 days a week, all year round. However, SUPRIVE does not
                rule out the possibility of certain programming errors, or the
                occurrence of force majeure, health pandemics, natural
                disasters, strikes, or similar circumstances that make it
                impossible to access the Website.
              </p>

              <p>
                It is forbidden to introduce hyperlinks for commercial purposes
                on websites outside SUPRIVE that allow access to the Web,
                without the prior consent of SUPRIVE. SUPRIVE is not responsible
                for the use or content of third party websites that may link to
                its site.
              </p>

              <p>
                2.2. Anyone using the Website does so at his or her own risk.
                Suprive shall not be held liable for any errors or omissions
                that may affect the content of this or any other content that
                may be accessed through this website. Nor can Suprive be held
                liable for any damages arising from the use of the website, nor
                for any action taken on the basis of the information provided
                therein.
              </p>

              <h4>3. Data protection</h4>

              <p>3.1. Data collected through forms</p>
              <ul>
                <li>
                  The Suprive website contains various forms which may be used
                  to collect personal data. All of these forms contain the
                  respective information clauses that comply with the provisions
                  of the data protection regulations.
                </li>

                <li>
                  Likewise, and when required by data protection regulations,
                  the consent of the person concerned is also requested to
                  process or transfer their personal data. For these purposes,
                  what is indicated in each clause will be taken into account.
                </li>

                <li>
                  In any case, Suprive only processes the personal data of the
                  data subjects that are adequate, pertinent and not excessive
                  for the specific, explicit and legitimate purposes for which
                  they were obtained. Likewise, it guarantees the security of
                  the personal data of website users through the application of
                  different types of technical and organizational security
                  measures.
                </li>

                <li>
                  As provided by data protection regulations, in the case of
                  minors, only minors over 14 years of age may provide personal
                  data without the consent of parents or guardians. In the case
                  of minors under 14 years of age, the consent of parents or
                  guardians is required. Suprive assumes no responsibility for
                  personal data provided by minors under the age of 14 without
                  the consent of parents or guardians.
                </li>
              </ul>

              <p>3.2. Transmission data</p>

              <p>
                Transmission data is implicit in the use of Internet
                communication protocols. Data generated by browsing the Suprive
                website will only be processed for strictly technical purposes
                and by personnel bound by a duty of confidentiality.
              </p>

              <p>3.3. Cookies</p>

              <p>
                In accordance with the provisions of Article 22 of the LSSI (Law
                34/2002 of 11 July 2002 on information society services and
                electronic commerce), Suprive may use data storage and retrieval
                devices (cookies) on the terminal equipment of the recipients,
                on condition that they have given their consent after having
                been provided with clear and complete information on their use.
                Users may obtain full information on the purpose of the
                installation of cookies and the uses to which they will be put
                in the Suprive cookie policy.
              </p>

              <p>
                Suprive only uses cookies that allow the user of the website to
                navigate in a more fluid and individualized manner. (See cookie
                policy).
              </p>

              <p>
                The above does not prevent the possible storage or access of a
                technical nature for the sole purpose of transmitting a
                communication over an electronic communications network or, to
                the extent strictly necessary, to provide an information society
                service expressly requested by the recipient.
              </p>

              <p>
                When personal data are requested from the user for the provision
                of services, the user will be informed of the necessary nature
                of those considered essential. If such mandatory data is not
                provided, it will not be possible to provide such services. (See
                cookie policy.)
              </p>

              <p>
                3.4. Access, rectification, cancellation and opposition rights
              </p>

              <p>
                The user may exercise the rights of access, rectification,
                cancellation and opposition by sending a request to the address
                dpo@suprive.com or by mail to the address shown at the beginning
                of this document. The request must indicate the name and surname
                of the user, the address for the purpose of notifications and
                the right being exercised, in addition to attaching a photocopy
                of the identity card or passport.
              </p>

              <h4>4. Intellectual Property Rights</h4>

              <p>
                4.1. All the contents of the Website, unless otherwise
                indicated, are the exclusive property of Suprive or third
                parties, including but not limited to: graphic design, source
                code, logos, text, graphics, illustrations, photographs and
                other elements appearing on the Website. For this purpose, a
                website is understood to be all those creations incorporated and
                expressed by any tangible or intangible means or support, known
                or to be known, which are protected by the current regulations
                on intellectual or industrial property.
              </p>

              <p>
                4.2. The mere visualization, printing, downloading or temporary
                storage, either in whole or in part, of the contents and
                elements inserted in the website is authorized exclusively for
                personal, private and non-profit use by the user, provided that
                in any case the origin and the author of the same are indicated
                and that, where appropriate, the copyright symbol appears.
              </p>

              <p>
                4.3. In no case does access to the website imply any kind of
                permission, waiver, transfer, license or total or partial
                transfer of such rights by their owners, unless expressly stated
                otherwise. Likewise, it does not grant users any other right of
                use, alteration, exploitation, reproduction, distribution or
                public communication of the website or its contents other than
                those expressly provided herein.
              </p>

              <h4>5. Return and Refund Policy</h4>

              <p>
                The website allows the Student/User to request a refund for any
                of the contracted courses within 15 calendar days after the
                purchase as long as no more than 20% of the course has been
                completed. Once the refund has been made, the Student/User will
                no longer be able to access the course content.
              </p>

              <h4>6. Applicable Law and Jurisdiction</h4>

              <p>
                In the event of any discrepancy or dispute arising from the
                interpretation or application of this legal notice, Suprive and
                the user shall be governed by Spanish law and shall submit to
                the jurisdiction of the courts and tribunals of Barcelona.
              </p>

              <p>Exclusion of Liability</p>

              <p>In 4 areas:</p>

              <p>- General</p>

              <p>- Medical/Health</p>

              <p>- Personal</p>

              <p>- Results</p>

              <p>General:</p>
              <p>
                By browsing this website, purchasing our courses, using our
                platform, emails, programs, services and/or products, you agree
                to all parts of this disclaimer.
              </p>

              <p>
                We are not responsible for the information you request or
                receive through our website, our platform, emails, programs,
                services and/or products. In no event shall we be liable for any
                direct or indirect damages that your use of or reliance on this
                website, our platform, emails, programs, services and/or
                products may cause you. We shall not be liable for any loss you
                may suffer, neither shall we be liable for any personal
                injuries, accidents you may suffer by performing the proposed
                exercises.
              </p>

              <p>Medical/Health:</p>

              <p>
                SUPRIVE recommends that you consult with your physician before
                beginning any exercise program in order to have him/her certify
                that you are able to perform the intended exercises and that you
                have the physical condition to perform the exercises. SUPRIVE
                does not have a medical team and is not qualified to perform any
                diagnosis, examination or treatment of medical conditions of any
                kind, or in determining the effect of any specific exercise on a
                medical condition.
              </p>

              <p>
                You assume that, by participating in any exercise or exercise
                program, there is a possibility that you may sustain physical
                injury. If you participate in this exercise or exercise program,
                you do so at your own risk and assume all risk of injury and
                hold SUPRIVE harmless.
              </p>

              <p>
                The information contained on the website is generic and in no
                case replaces the advice of a medical professional, it is not
                intended to make a diagnosis or treatment. You should never
                disregard medical advice, nor delay in seeking it.
              </p>

              <p>
                SUPRIVE is not responsible for any advice, diagnosis or
                treatment. If in doubt, you should consult your physician
                regarding the information contained in this website.
              </p>

              <p>
                Remember that there may be risks to your health in the incorrect
                execution of the technique proposed by Katerina Safarova, as
                well as injuries if you perform the exercises incorrectly or
                perform more repetitions than recommended.
              </p>

              <p>
                If you do not adapt the degree of effort to your physical
                capacity and do not respect the times and days of rest, you
                could cause injuries for which we are not responsible.
              </p>

              <p>
                In no case should you use more weight than recommended and this
                must be appropriate to your physical condition otherwise it
                could cause injury.
              </p>

              <p>
                In no case should you train with a previous injury because it
                could be aggravated and in no case we are not responsible.
              </p>

              <p>Personal:</p>

              <p>
                KATERINA SAFAROVA is not a medical doctor. The information you
                provide is based on your personal experience.
              </p>

              <p>
                The information contained in web, our platform, our guest
                platforms, emails, programs, services and/or products is for
                informational purposes only, and is available to you as tools
                for your own use. While Katerina Safarova relies only on her
                professional experience, not on qualified training in this
                regard.
              </p>

              <p>
                Remember that Katerina Safarova is not a medical health
                professional, nor a mental health provider. Her sole purpose in
                serving as a coach is to help you achieve your own health and
                wellness goals.
              </p>

              <p>
                You voluntarily participate in the use of our website and are
                solely responsible for your results. You assume full
                responsibility for your health, life and well-being.
              </p>

              <p>
                In the event that you use the information provided through our
                website, we assume no liability.
              </p>

              <p>
                Katerina Safarova makes every effort to ensure the accuracy of
                the information published on our website however, the
                information may contain inaccuracies or typographical errors.
              </p>

              <p>Results:</p>

              <p>
                We do our best to guarantee the services provided, but the
                result depends on muscle development, your physical condition
                without losing sight of the fact that fat loss results differ
                from individual to individual.
              </p>

              <p>
                We do not guarantee the level of success you may experience, and
                you accept that results will be different for each individual.
                The testimonials and examples provided are exceptional results,
                which may not apply to the average buyer, and are not intended
                to represent or guarantee that anyone will achieve the same or
                similar results.
              </p>

              <p>
                Each individual's health, fitness and nutrition success depends
                on their background, dedication, desire and motivation. As with
                any health-related program or service, your results may vary and
                will be based on many variables, including, but not limited to,
                your individual ability, life experience, genetic and health
                profile, starting point, experience and level of commitment.
              </p>

              <p>
                There is no guarantee that examples of past fitness,
                competition, muscle building and/or fat burning results can be
                duplicated in the future. We cannot guarantee your future
                results and/or success. Nor can we guarantee that you will
                maintain the results you experience if you do not continue to
                follow the program.
              </p>

              <p>
                Your use of our information, products and services must be based
                on your own diligence and you agree that our company is not
                responsible for the success or failure of your physical
                condition and results.
              </p>

              <p>
                The experiences we present are based on real people in the real
                world for illustrative purposes only.
              </p>

              <p>
                The testimonials, examples and photos used are from actual
                customers and results they personally achieved. Each client has
                approved these testimonials, examples and photos for use in
                materials to speak to our program, service and/or product
                capabilities, but are not intended to represent or guarantee
                that current or future clients will achieve the same or similar
                results. The customer stories represent what is possible with
                our programs, services and/or products.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer polygons={true} />
    </Layout>
  );
};

export default TerminosYCondicionesEn;
