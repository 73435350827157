import React from 'react';
import { Button, Card, CardDeck, Col, Row } from 'react-bootstrap';

const PaymentSection = (props) => {
  const { discount } = props;

  return (
    <Row className="rowSection" id="payment">
      <Row className="paymentRow paymentRowTitle">
        <Col xs={12} className="paymentCol gs_reveal gs_reveal_direction">
          <h2>выберите способ оплаты</h2>
        </Col>
      </Row>
      <Row className="paymentRow paymentRowPrice">
        <div className="fullWidthBackgroundTextWrap">
          <div className="gs_reveal gs_reveal_fromLeft">
            <p className="fullWidthBackgroundTextStroke">метод</p>
            <p className="fullWidthBackgroundTextStroke">метод</p>
          </div>
          <div className="gs_reveal gs_reveal_fromRight">
            <p className="fullWidthBackgroundTextStroke katName">Кати</p>
            <p className="fullWidthBackgroundTextStroke katName">САФАРОВОЙ</p>
          </div>
        </div>
        <Col xs={12} className="paymentCol">
          <CardDeck>
            <Card className="cardStyle cardStyleSingle cardConfig gs_reveal gs_reveal_fromLeft">
              <Card.Header className="cardHeader triangleTopLeft">
                <h5 className="cardTitle">кредитная карта</h5>
                <p className="cardText">единовременный платеж</p>
              </Card.Header>
              <Card.Body className="cardBody">
                <span className="priceText">{discount.price_ru} ₽</span>
              </Card.Body>
              <Card.Footer>
                <Button
                  bsPrefix
                  className="buttonStyle buttonPaymentText button--calypso button--calypso-primary"
                  href={discount.ru_url}
                >
                  <span>платить</span>
                </Button>
              </Card.Footer>
            </Card>
            <Card className="cardStyle cardStyleSingle cardStyleSecondary cardConfig gs_reveal gs_reveal_fromRight">
              <Card.Header className="cardHeader triangleTopLeft">
                <h5 className="cardTitle">PayPal</h5>
                <p className="cardText">единовременная выплата (USD)</p>
              </Card.Header>
              <Card.Body className="cardBody">
                <span className="priceText">${discount.price_usd}</span>
              </Card.Body>
              <Card.Footer className="cardFooter">
                <Button
                  bsPrefix
                  className="buttonStyle buttonPaymentText button--calypso button--calypso-secondary-payment"
                  href={discount.usd_url}
                >
                  <span>платить</span>
                </Button>
              </Card.Footer>
            </Card>
          </CardDeck>
        </Col>
      </Row>
    </Row>
  );
};

export default PaymentSection;
