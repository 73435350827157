import React from "react";
import { Button, Card, CardDeck, Col, Row } from "react-bootstrap";
import { isPromoFinish } from "../../../../layout/Layout"

const PaymentSection = (props) => {
   const { discount } = props;

   if (isPromoFinish()) {
      return (
         <Row className="rowSection" id="payment">
            <Row className="paymentRow paymentRowTitle">
               <Col xs={12} className="paymentCol gs_reveal gs_reveal_direction">
                  <h2>выберите способ оплаты</h2>
               </Col>
            </Row>
            <Row className="paymentRow paymentRowPrice">
               <div className="fullWidthBackgroundTextWrap">
                  <div className="gs_reveal gs_reveal_fromLeft">
                     <p className="fullWidthBackgroundTextStroke">метод</p>
                     <p className="fullWidthBackgroundTextStroke">метод</p>
                  </div>
                  <div className="gs_reveal gs_reveal_fromRight">
                     <p className="fullWidthBackgroundTextStroke katName">Кати</p>
                     <p className="fullWidthBackgroundTextStroke katName">САФАРОВОЙ</p>
                  </div>
               </div>
               <Col xs={12} className="paymentCol">
                  <CardDeck>
                     <Card className="cardStyle cardStyleSingle cardConfig cardStylePayV gs_reveal gs_reveal_fromLeft">
                        <Card.Header className="cardHeaderPay triangleTopLeftPay">
                           <form method="POST" action="https://suprive40873.activehosted.com/proc.php" id="_form_6184179A6BAA4_" className="_form _form_223 _inline-form  _dark" noValidate="">
                              <input type="hidden" name="u" value="6184179A6BAA4" data-name="u" />
                              <input type="hidden" name="f" value="223" data-name="f" />
                              <input type="hidden" name="s" data-name="s" />
                              <input type="hidden" name="c" value="0" data-name="c" />
                              <input type="hidden" name="m" value="0" data-name="m" />
                              <input type="hidden" name="act" value="sub" data-name="act" />
                              <input type="hidden" name="v" value="2" data-name="v" />
                              <input type="hidden" name="or" value="71c05299fac1392aa87d855d18000ec4" data-name="or" />
                              <h5 className="cardTitle">Мест больше нет</h5>
                              <p className="cardText" style={{textAlign: 'left'}}>Ты можешь оставить свою почту и мы сообщим, когда на курсе откроются новые места</p><br />
                              <div style={{width: '100%'}}>
                                 <input type="text" name="fullname" required placeholder="имя*" style={{border: "2px solid #ffca64", width: '90%', padding: '10px', color: '#fff', background: 'transparent'}} />
                              </div><br />
                              <div style={{width: '100%'}}>
                                 <input type="email" name="email" required placeholder="Эл. адрес*" style={{border: "2px solid #ffca64", width: '90%', padding: '10px', color: '#fff', background: 'transparent'}} />
                              </div><br />
                              <div style={{width: '100%', lineHeight: '1em', textAlign: 'left'}}>
                                 <input type="checkbox" id="chk" name="field[11][]" style={{fontSize: '20px', width: '20px', height: '20px', border: "2px solid #ffca64", background: 'transparent'}} /><label htmlFor="chk" style={{color: '#fff', paddingLeft: '10px'}}>*Я согласен на обработку моих персональных данных в<br />соответствии с Условиями</label>
                              </div><br />
                              <Button
                                 bsPrefix
                                 className="buttonStyle buttonPaymentText button--calypso button--calypso-primary"
                                 type="submit"
                                 >
                                 <span>отправить</span>
                              </Button>
                           </form>
                        </Card.Header>
                     </Card>
                     <Card className="cardStyle cardStyleSingle cardStyleSecondary cardConfig cardStylePayY gs_reveal gs_reveal_fromRight" style={{filter: 'grayscale(100%)'}}>
                        <Card.Header className="cardHeader triangleTopLeftPay">
                           <h5 className="cardTitle">кредитная</h5>
                           <p className="cardText" style={{color: '#666'}}>единовременный платеж</p>
                           <div>
                              <span className="priceText">6980 ₽</span>
                           </div>
                        </Card.Header>
                        <Card.Header className="cardHeader triangleTopLeft">
                           <h5 className="cardTitle">PayPal</h5>
                           <p className="cardText" style={{color: '#666'}}>единовременная выплата (USD)</p>
                           <div>
                              <span className="priceText">$94</span>
                           </div>
                        </Card.Header>
                        <Card.Footer className="cardFooter">
                           <Button
                              bsPrefix
                              className="buttonStyle buttonPaymentText button--calypso button--calypso-secondary-payment"
                              href={discount.usd_url}
                              style={{pointerEvents: 'none'}}
                              >
                              <span>недоступно</span>
                           </Button>
                        </Card.Footer>
                     </Card>
                  </CardDeck>
               </Col>
            </Row>
         </Row>
      );
   }

   return (
      <Row className="rowSection" id="payment">
         <Row className="paymentRow paymentRowTitle">
            <Col xs={12} className="paymentCol gs_reveal gs_reveal_direction">
               <h2>выберите способ оплаты</h2>
            </Col>
         </Row>
         <Row className="paymentRow paymentRowPrice">
            <div className="fullWidthBackgroundTextWrap">
               <div className="gs_reveal gs_reveal_fromLeft">
                  <p className="fullWidthBackgroundTextStroke">метод</p>
                  <p className="fullWidthBackgroundTextStroke">метод</p>
               </div>
               <div className="gs_reveal gs_reveal_fromRight">
                  <p className="fullWidthBackgroundTextStroke katName">Кати</p>
                  <p className="fullWidthBackgroundTextStroke katName">САФАРОВОЙ</p>
               </div>
            </div>
            <Col xs={12} className="paymentCol">
               <CardDeck style={{width: '100%'}}>
                  <Card className="cardStyle cardStyleSingle cardConfig cardStylePayV gs_reveal gs_reveal_fromLeft">
                     <Card.Header className="cardHeader triangleTopLeftPay">
                        <h5 className="cardTitle">кредитная карта</h5>
                        <p className="cardText">единовременный платеж</p>
                     </Card.Header>
                     <Card.Body className="cardBody">
                        <div>
                           <div>
                              <span className={discount.price_ru < 3490 ? "priceText beforePrice" : "d-none"}>3490 ₽</span>
                           </div>
                           <br />
                           <span className="priceText">{discount.price_ru} ₽</span>
                        </div>
                     </Card.Body>
                     <Card.Footer>
                        <Button
                           bsPrefix
                           className="buttonStyle buttonPaymentText button--calypso button--calypso-primary"
                           href={discount.ru_url}
                           >
                           <span>платить</span>
                        </Button>
                     </Card.Footer>
                  </Card>
                  <Card className="cardStyle cardStyleSingle cardStyleSecondary cardConfig cardStylePayY gs_reveal gs_reveal_fromRight">
                     <Card.Header className="cardHeader triangleTopLeftPay">
                        <h5 className="cardTitle">PayPal</h5>
                        <p className="cardText">единовременная выплата (USD)</p>
                     </Card.Header>
                     <Card.Body className="cardBody">
                        <div>
                           <div>
                              <span className={discount.price_usd < 47 ? "priceText beforePrice" : "d-none"}>$47</span>
                           </div>
                           <div>
                              <span className="priceText">${discount.price_usd}</span>
                           </div>
                        </div>
                     </Card.Body>
                     <Card.Footer className="cardFooter">
                        <Button
                           bsPrefix
                           className="buttonStyle buttonPaymentText button--calypso button--calypso-secondary-payment"
                           href={discount.usd_url}
                           >
                           <span>платить</span>
                        </Button>
                     </Card.Footer>
                  </Card>
               </CardDeck>
            </Col>
         </Row>
      </Row>
   );
};

export default PaymentSection;
