import React from 'react';
import { Table } from 'react-bootstrap';
import FooterEs from '../../../components/footer/FooterEs';
import Layout from '../../../layout/Layout';

export const PoliticaDePrivacidad = () => {
  return (
    <Layout>
      <div className="RootLegals">
        <div className="first-section privacityContainer">
          <div className="container privacityContent">
            <div>
              <header>
                <h2>POLITICA PRIVACIDAD Y PROTECCI&Oacute;N DE DATOS</h2>
              </header>
              <div>
                <div>
                  <p>
                    En SUPRIVE, estamos comprometidos con la protecci&oacute;n
                    de su privacidad. Por ello, tratamos sus datos e
                    informaci&oacute;n personal de acuerdo con esta
                    Pol&iacute;tica de privacidad y de conformidad con el
                    Reglamento (UE) 2016/679 del Parlamento Europeo y del
                    Consejo, de 27 de abril de 2016 (Reglamento General de
                    Protecci&oacute;n de Datos o &ldquo;RGPD&rdquo;), y con la
                    Ley Org&aacute;nica 3/2018, de 5 de diciembre, de
                    Protecci&oacute;n de Datos Personales y garant&iacute;a de
                    los derechos digitales (&ldquo;LOPD-GDD&rdquo;) y resto de
                    normativa de desarrollo.
                  </p>
                  <p>
                    Esta Pol&iacute;tica de privacidad se aplica a todas las
                    actividades realizadas por SUPRIVE.
                  </p>
                  <p>
                    Al facilitarnos sus datos personales y utilizar nuestro
                    sitio web, entendemos que ha le&iacute;do y comprendido los
                    t&eacute;rminos relacionados con la informaci&oacute;n de
                    protecci&oacute;n de datos de car&aacute;cter personal que
                    se exponen.
                  </p>{' '}
                  <h4>1. Responsable del tratamiento de datos personales</h4>
                  <Table striped bordered hover>
                    <tbody>
                      <tr>
                        <td>
                          <p>Identidad</p>
                        </td>
                        <td>
                          <p>SUPRIVE MEDIA S.L</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Direcci&oacute;n</p>
                        </td>
                        <td>
                          <p>
                            Gran V&iacute;a Carlos III, 124 ent.1&ordm;, 08034
                            Barcelona
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>CIF</p>
                        </td>
                        <td>
                          <p>B67480699</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Tel&eacute;fono</p>
                        </td>
                        <td>
                          <p>+34 935 211 147</p>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <p>
                    Contacto del delegado de protecci&oacute;n de datos
                    (DPO):&nbsp;
                    <a href="mailto:dpo@suprive.com">dpo@suprive.com</a>
                  </p>{' '}
                  <h4>
                    2. Datos personales y categor&iacute;as especiales de datos
                    personales
                  </h4>
                  <p>
                    Los datos personales son toda informaci&oacute;n sobre una
                    persona f&iacute;sica identificada o identificable (el
                    &ldquo;interesado&rdquo;), como por ejemplo el nombre, la
                    direcci&oacute;n, el n&uacute;mero de tel&eacute;fono o la
                    direcci&oacute;n de correo electr&oacute;nico.
                  </p>
                  <p>
                    Los datos personales (o datos) constituyen cualquier
                    informaci&oacute;n relacionada con una persona
                    f&iacute;sica, identificada o identificable, incluso de
                    forma indirecta, por referencia a cualquier otra
                    informaci&oacute;n, incluido un n&uacute;mero de
                    identificaci&oacute;n personal.
                  </p>{' '}
                  <h4>
                    3. Datos personales que recogemos y finalidades del
                    tratamiento
                  </h4>
                  <h4>3.1 Solicitud de datos personales</h4>
                  <p>
                    Los datos personales que podr&iacute;amos solicitarle
                    comprenden sus datos de contacto (por ejemplo, nombre,
                    direcci&oacute;n, n&uacute;mero de tel&eacute;fono,
                    direcci&oacute;n de correo electr&oacute;nico, entre otros),
                    datos identificativos (por ejemplo, n&uacute;mero de
                    documento nacional de identidad, NIE o pasaporte, entre
                    otros), o cualesquiera otros que sean necesarios para
                    cumplir las finalidades expuestas en esta Pol&iacute;tica de
                    privacidad. Si recopilamos datos personales de
                    categor&iacute;a especial sobre usted, le informaremos de
                    ello, le solicitaremos su consentimiento expreso para el
                    tratamiento cuando sea necesario de conformidad con la
                    normativa de aplicaci&oacute;n y le informaremos de sus
                    finalidades.
                  </p>
                  <p>
                    Es posible que durante la relaci&oacute;n que mantenga con
                    SUPRIVE se recopilen otros datos de car&aacute;cter
                    personal, en cuyo caso se le solicitar&aacute; su
                    consentimiento y se le informar&aacute;, entre otros, del
                    tratamiento a efectuar, la finalidad, la legitimaci&oacute;n
                    del tratamiento y de los derechos que le asisten en
                    relaci&oacute;n con sus datos personales.
                  </p>
                  <p>
                    Otros datos personales recopilados pueden estar indicados en
                    otras secciones de esta Pol&iacute;tica de privacidad&nbsp;o
                    mediante textos informativos que se muestran en el momento
                    de la recogida de estos datos.
                  </p>
                  <p>
                    Los datos personales pueden ser facilitados directamente por
                    el usuario o recopilados autom&aacute;ticamente durante el
                    uso de la web.
                  </p>
                  <h4>3.2 Finalidades del tratamiento</h4>
                  <p>
                    SUPRIVE recopila sus datos personales con diversas
                    finalidades, seg&uacute;n el uso y el origen de dichos datos
                    personales (alumnos/as, clientes, proveedores, empleados/as
                    o terceros, etc).
                  </p>
                  <p>
                    Los datos personales de los alumnos/as son tratados para el
                    desarrollo de las funciones propias de SUPRIVE. El uso de
                    los datos personales de los alumnos/as y clientes viene
                    autorizado por el consentimiento previo, expreso y
                    espec&iacute;fico de sus titulares o representantes legales
                    que se da al contratar o iniciar la navegaci&oacute;n en la
                    p&aacute;gina. Seg&uacute;n la normativa vigente, los datos
                    personales que podemos solicitar para el cumplimiento de las
                    labores anteriormente indicadas son aquellos que sean
                    necesarios&nbsp;para la formaci&oacute;n y
                    contrataci&oacute;n del servicio.
                  </p>
                  <p>
                    En el caso de datos personales de sus clientes/alumnos/as,
                    potenciales clientes alumnos/as&nbsp;o proveedores, SUPRIVE
                    los recoge y trata con la finalidad de gestionar las ofertas
                    de servicios y la relaci&oacute;n comercial o de servicios
                    con ellos.
                  </p>
                  <p>
                    En el caso de los datos personales de empleados/as de
                    SUPRIVE, la recogida y tratamiento de los datos se realiza
                    con la finalidad de dar cumplimiento y gestionar la
                    relaci&oacute;n laboral y de atender las actividades de
                    SUPRIVE, incluyendo la administraci&oacute;n
                    del&nbsp;personal, la prevenci&oacute;n de riesgos
                    laborales, el env&iacute;o de comunicaciones generales o
                    personales, incluidas las relacionadas con las
                    informaciones, campa&ntilde;as publicitarias o cursos de
                    SUPRIVE pueda impartir o promocionar. En el caso de
                    curr&iacute;culos, para la admisi&oacute;n y gesti&oacute;n
                    del alumnado o de las candidaturas y los procesos de
                    selecci&oacute;n y, en su caso, posterior
                    contrataci&oacute;n.
                  </p>
                  <p>
                    En todos los casos, los datos personales recopilados
                    ser&aacute;n utilizados con el fin de dar cumplimiento a las
                    obligaciones legales y a aquellas derivadas de la
                    ejecuci&oacute;n de las relaciones comerciales y contratos
                    acad&eacute;micos, mercantiles, administrativos, etc., en
                    los que SUPRIVE sea parte, as&iacute; como de gestionar
                    cualquier queja o reclamaci&oacute;n legal, laboral o
                    comercial.
                  </p>
                  <p>
                    Asimismo, podr&aacute;n ser utilizados para remitirles
                    encuestas, cuestionarios y solicitarles valoraciones de
                    nuestros servicios, dado que tal informaci&oacute;n nos
                    resulta de gran utilidad para controlar la&nbsp;calidad.
                  </p>
                  <p>
                    Trataremos sus datos de contacto recogidos a trav&eacute;s
                    de sus correos electr&oacute;nicos, o formularios en este
                    web, para la gesti&oacute;n de sus solicitudes y consultas.
                  </p>
                  <p>
                    Datos de&nbsp;<em>cookies</em>: cualquier uso de&nbsp;
                    <em>cookies</em>&nbsp;-&nbsp;u otras herramientas de
                    seguimiento- por parte del web&nbsp;o por proveedores de
                    servicios externos utilizados por este&nbsp;web a menos que
                    se especifique lo contrario, tiene el prop&oacute;sito de
                    identificar al usuario y registrar sus preferencias para
                    fines comerciales relacionados con la prestaci&oacute;n del
                    servicio solicitado por el usuario, y son tratados en todo
                    momento seg&uacute;n nuestra&nbsp; Pol&iacute;tica de&nbsp;
                    <em>cookies</em>.
                  </p>
                  <p>
                    Asimismo, SUPRIVE podr&aacute; utilizar sus datos para
                    facilitarle informaci&oacute;n sobre nuestros cursos,
                    servicios, productos o informaci&oacute;n de SUPRIVE. En
                    cualquier momento puede solicitar que no le contactemos ni
                    informemos acerca de nuestros cursos o servicios, as&iacute;
                    como que no revelemos su informaci&oacute;n a terceros con
                    dicha finalidad, bien contact&aacute;ndonos directamente o
                    bien, en su caso, haciendo clic en el bot&oacute;n
                    &ldquo;darse de baja&rdquo; en los correos
                    electr&oacute;nicos promocionales.
                  </p>
                  <h4>3.3 Formas de recogida de los datos &nbsp;</h4>
                  <p>
                    Generalmente recopilamos los&nbsp;datos personales
                    directamente de usted. Recopilamos la informaci&oacute;n y
                    datos personales que usted nos facilita
                    electr&oacute;nicamente, incluyendo informaci&oacute;n
                    facilitada a trav&eacute;s de la p&aacute;gina web, chat en
                    la web, redes sociales, correo electr&oacute;nico, a
                    trav&eacute;s de la prestaci&oacute;n de nuestros servicios
                    y mediante formularios o cualquier otro medio escrito o
                    electr&oacute;nico que usted cumplimenta.
                  </p>
                  <p>
                    Usted al navegar por la web, y facilitar datos personales,
                    autoriza la recopilaci&oacute;n de estos datos de
                    car&aacute;cter personal y da su consentimiento para dicho
                    tratamiento.
                  </p>
                  <p>
                    Si recibimos informaci&oacute;n o datos personales sobre
                    usted que no hemos solicitado o que no es relevante para las
                    finalidades antes indicadas, destruiremos o anonimizaremos
                    la informaci&oacute;n, salvo que debamos conservarla de
                    conformidad con la normativa vigente.
                  </p>
                  <h4>
                    3.4 Qu&eacute; sucede si usted no facilita su
                    informaci&oacute;n y datos personales o no autoriza su
                    tratamiento
                  </h4>
                  <p>
                    Si usted es un alumno/a, cliente o un proveedor, est&aacute;
                    en su derecho de no facilitarnos informaci&oacute;n o datos
                    personales, si bien en ese caso podr&iacute;a devenir
                    imposible la suscripci&oacute;n de cualquier tipo de
                    contrato o la prestaci&oacute;n de los servicios.
                  </p>
                  <p>
                    Si usted es un&nbsp;empleado/a&nbsp;y no facilita
                    informaci&oacute;n o datos personales que son necesarios
                    para el cumplimiento de obligaciones y el ejercicio de
                    derechos legales o regulatorios, o bien para la
                    ejecuci&oacute;n de un contrato de trabajo o de
                    prestaci&oacute;n de servicios por SUPRIVE a sus alumnos/as,
                    o para la satisfacci&oacute;n de intereses
                    leg&iacute;timos,&nbsp;se podr&iacute;a ver impedida o
                    afectada de alg&uacute;n modo su relaci&oacute;n laboral con
                    SUPRIVE.
                  </p>
                  <p>
                    En el caso de datos de categor&iacute;a especial, siempre
                    ser&aacute; solicitado su consentimiento expl&iacute;cito,
                    salvo que el tratamiento sea necesario para el cumplimiento
                    de obligaciones y el ejercicio de derechos
                    espec&iacute;ficos del responsable del tratamiento o del
                    interesado en el &aacute;mbito del derecho laboral y de la
                    seguridad y protecci&oacute;n social: ya&nbsp;sea necesario
                    para proteger intereses vitales o se refiera a datos que
                    usted haya hecho manifiestamente p&uacute;blicos;&nbsp;para
                    la defensa frente a reclamaciones o por razones de
                    inter&eacute;s p&uacute;blico esencial;&nbsp;o bien para
                    fines de medicina preventiva o laboral, evaluaci&oacute;n de
                    su capacidad laboral, diagn&oacute;stico m&eacute;dico,
                    prestaci&oacute;n de asistencia o tratamiento de tipo
                    sanitario o social.
                  </p>{' '}
                  <h4>
                    4. Legitimaci&oacute;n para el tratamiento de datos
                    personales
                  </h4>
                  <p>
                    La base legal para el tratamiento de sus datos con las
                    finalidades antes mencionadas ser&aacute; siempre alguna de
                    las previstas por el art&iacute;culo 6 del RGPD, es decir:
                  </p>
                  <ul>
                    <li>Consentimiento del interesado.</li>
                    <li>Ejecuci&oacute;n de un contrato.</li>
                    <li>
                      Cumplimiento de una obligaci&oacute;n legal aplicable al
                      responsable del tratamiento.
                    </li>
                    <li>
                      Protecci&oacute;n de intereses vitales del interesado o de
                      otra persona f&iacute;sica.
                    </li>
                    <li>
                      Satisfacci&oacute;n de intereses leg&iacute;timos
                      perseguidos por el responsable del tratamiento o por un
                      tercero.
                    </li>
                    <li>
                      Si el tratamiento se refiriere a datos personales de
                      categor&iacute;a especial, estar&aacute; siempre
                      fundamentado en alguna de las bases legales del
                      art&iacute;culo 9 del RGPD.
                    </li>
                  </ul>
                  <p>
                    Si el usuario fuese menor de edad, se requiere que cuente
                    con el previo consentimiento de sus padres o tutores antes
                    de proceder a la inclusi&oacute;n de sus datos personales en
                    los formularios del web. SUPRIVE se exime de cualquier
                    responsabilidad por el incumplimiento de este
                    requisito.&nbsp;
                  </p>{' '}
                  <h4>
                    5. Duraci&oacute;n del tratamiento de los datos personales
                  </h4>
                  <p>
                    Con car&aacute;cter general, los datos personales
                    proporcionados se conservar&aacute;n durante el tiempo
                    necesario para cumplir las finalidades descritas en esta
                    Pol&iacute;tica de privacidad o para atender cualquier
                    solicitud de los interesados, a menos que legalmente se
                    permita o requiera un periodo de retenci&oacute;n m&aacute;s
                    largo o mientras no se solicite su supresi&oacute;n por el
                    interesado.
                  </p>
                  <p>
                    Cuando SUPRIVE tenga la consideraci&oacute;n de responsable
                    del tratamiento, velar&aacute; por que los datos objeto de
                    tratamiento:&nbsp;
                  </p>
                  <ul>
                    <li>
                      Se adecuen a la finalidad para la que fueron recabados.
                    </li>
                    <li>
                      No se utilicen para finalidades distintas y/o
                      incompatibles con las que justificaron su recogida y
                      tratamiento.
                    </li>
                    <li>
                      No sean mantenidos indefinidamente sin
                      justificaci&oacute;n.
                    </li>
                    <li>
                      Sean cancelados cuando hayan dejado de ser necesarios para
                      la finalidad que justific&oacute; su recogida y
                      tratamiento.&nbsp;
                    </li>
                  </ul>
                  <p>
                    Se entiende justificada la conservaci&oacute;n del dato
                    cuando:
                  </p>
                  <ul>
                    <li>
                      Se pudiese causar un perjuicio a intereses
                      leg&iacute;timos del titular de los datos o de terceros.
                    </li>
                    <li>
                      Una norma imponga la obligaci&oacute;n de conservar los
                      datos durante un periodo de tiempo determinado.
                    </li>
                    <li>
                      Los datos y documentaci&oacute;n sirvan como justificante
                      de una actividad o servicio prestado, durante los plazos
                      de prescripci&oacute;n de las acciones civiles, penales,
                      administrativas o de cualquier otro tipo que pudieran
                      derivarse de la actividad o servicio prestado.
                    </li>
                    <li>
                      Se haya acordado por las partes interesadas un plazo
                      superior de conservaci&oacute;n.
                    </li>
                  </ul>
                  <p>
                    En los casos en los que exista obligaci&oacute;n legal de
                    conservar los datos durante un periodo de tiempo determinado
                    y/o durante los plazos de prescripci&oacute;n de las
                    acciones que pudieran derivarse de la actividad o servicio
                    prestado, la SUPRIVE proceder&aacute; al bloqueo de los
                    datos durante los referidos plazos. Los datos bloqueados
                    quedar&aacute;n &uacute;nicamente a disposici&oacute;n de
                    las administraciones p&uacute;blicas, jueces y tribunales,
                    para la atenci&oacute;n de las posibles responsabilidades
                    nacidas del tratamiento, durante el plazo de
                    prescripci&oacute;n de estas y/o durante los plazos legales
                    establecidos al efecto. Cumplidos los indicados plazos,
                    deber&aacute; procederse a la supresi&oacute;n de los datos
                    bloqueados.&nbsp;
                  </p>
                  <p>
                    Cuando SUPRIVE tenga la consideraci&oacute;n
                    de&nbsp;encargado del tratamiento, de conformidad con el
                    RGPD y una vez cumplida la prestaci&oacute;n contractual que
                    dio lugar al encargo del tratamiento, los datos personales
                    deber&aacute;n ser destruidos o devueltos al responsable del
                    tratamiento, as&iacute; como cualquier soporte o documento
                    en los que conste alg&uacute;n dato de car&aacute;cter
                    personal objeto de tratamiento
                  </p>
                  <p>
                    Como en el supuesto anterior, no proceder&aacute; la
                    supresi&oacute;n de los datos por el encargado del
                    tratamiento cuando exista una previsi&oacute;n legal que
                    exija su conservaci&oacute;n, en cuyo caso deber&aacute;
                    procederse a la devoluci&oacute;n de los datos y el
                    responsable del tratamiento debe garantizar dicha
                    conservaci&oacute;n. Igualmente se establece que el
                    encargado de tratamiento conserve los datos bloqueados
                    mientras puedan derivarse responsabilidades de su
                    relaci&oacute;n con el responsable del tratamiento.
                  </p>
                  <p>
                    De conformidad con estas exigencias, se establecen los
                    siguientes
                  </p>
                  <p>
                    <span className="boldText">
                      Plazos de conservaci&oacute;n de datos de car&aacute;cter
                      personal
                    </span>
                  </p>
                  <ol>
                    <li>
                      En materia de solicitud de admisi&oacute;n a cursos
                      impartidos, gestionados o publicitados en la web de
                      SUPRIVE:
                    </li>
                  </ol>
                  <p>
                    Plazo: En el caso de no formalizar la inscripci&oacute;n,
                    sus datos personales se conservar&aacute;n a los solos
                    efectos de investigaci&oacute;n hist&oacute;rica o esta
                    d&iacute;stica; y como evidencias en los procedimientos de
                    calidad en los que la SUPRIVE participe.
                  </p>
                  <ol start="2">
                    <li>
                      En materia de&nbsp; inscripci&oacute;n a cursos de
                      formaci&oacute;n, entretenimiento y varios:
                      <br />
                      <br /> Plazo: los datos de car&aacute;cter personal
                      incluidos en la matr&iacute;cula formalizada para iniciar
                      la formaci&oacute;n en la SUPRIVE que resulten necesarios
                      para la identificaci&oacute;n del alumno/a/cliente y los
                      derivados del expediente de las personas que hayan
                      formalizado un curso con SUPRIVE, se conservar&aacute;n,
                      en cumplimiento de las obligaciones legales, de manera
                      indefinida.
                    </li>
                    <li>En materia de&nbsp; datos acad&eacute;micos:&nbsp;</li>
                  </ol>
                  <p>
                    <br /> Plazo: Por imperativo legal, el plazo de
                    conservaci&oacute;n de los datos relativos al expediente
                    acad&eacute;mico del alumno/a tiene car&aacute;cter
                    indefinido.
                  </p>
                  <ol start="4">
                    <li>En materia de&nbsp; antiguo alumno/a:</li>
                  </ol>
                  <p>
                    Plazo: La condici&oacute;n de alumni es vitalicia, por lo
                    que los datos personales facilitados voluntariamente a
                    SUPRIVE se mantendr&aacute;n en el sistema de forma
                    indefinida en tanto el interesado no solicite su
                    supresi&oacute;n.
                    <br /> Referencia normativa: art. 6.1.a) y 6.1.b) del
                    Reglamento General de Protecci&oacute;n de Datos.
                  </p>
                  <ol start="5">
                    <li>
                      En materia de datos relativos al&nbsp; personal de la
                      SUPRIVE:
                    </li>
                  </ol>
                  <p>
                    Plazo: Se conservar&aacute;n durante el tiempo necesario
                    para cumplir con la finalidad para la que se recabaron y
                    para determinar las posibles responsabilidades que se
                    pudieran derivar de dicha finalidad y del tratamiento de los
                    datos. Ser&aacute; de aplicaci&oacute;n lo dispuesto en la
                    normativa de archivos y documentaci&oacute;n.
                  </p>
                  <p>
                    Referencia normativa: art. 21.1 RDL 5/2004 de infracciones y
                    sanciones en el orden social y arts. 6.1.b) y 6.1.c) del
                    Reglamento General de Protecci&oacute;n de Datos.
                    <br /> En el caso concreto de solicitudes de empleo y
                    curr&iacute;culos, ser&aacute;n conservados por un periodo
                    de un a&ntilde;o, salvo indicaci&oacute;n en contrario por
                    parte del interesado.
                  </p>
                  <ol start="6">
                    <li>
                      En materia de&nbsp; prevenci&oacute;n de riesgos
                      laborales:
                    </li>
                  </ol>
                  <p>
                    Plazo: Se conservar&aacute;n durante el tiempo necesario
                    para cumplir con la finalidad para la que se recabaron y
                    para determinar las posibles responsabilidades que se
                    pudieran derivar de dicha finalidad y del tratamiento de los
                    datos. Ser&aacute; de aplicaci&oacute;n lo dispuesto en la
                    normativa de archivos y documentaci&oacute;n.
                    <br /> Referencia normativa: Art. 4 RDL 5/2004 de
                    infracciones y sanciones en el orden social y arts. 6.1.b) y
                    6.1.c) del Reglamento General de Protecci&oacute;n de Datos.
                  </p>
                  <ol start="7">
                    <li>
                      En materia de tratamiento de datos personales&nbsp; con
                      fines publicitarios y promocionales :&nbsp;
                    </li>
                  </ol>
                  <p>
                    Plazo: Los datos personales de las personas interesadas en
                    recibir informaci&oacute;n publicitaria y promocional de la
                    SUPRIVE se mantendr&aacute;n en el sistema de forma
                    indefinida en tanto el interesado no solicite su
                    supresi&oacute;n.
                  </p>
                  <p>
                    Referencia normativa: art. 6.1.a) del Reglamento General de
                    Protecci&oacute;n de Datos.&nbsp;
                  </p>
                  <ol start="8">
                    <li>En materia de&nbsp; videovigilancia:&nbsp;</li>
                  </ol>
                  <p>
                    Plazo: 1 mes
                    <br /> Referencia normativa: Art&iacute;culo 22.3 de la
                    LOPD-GDD.&nbsp;
                  </p>
                  <ol start="9">
                    <li>
                      En materia de&nbsp;control de accesos a edificios: de
                      aplicaci&oacute;n al personal de administraci&oacute;n y
                      servicios de la SUPRIVE que deben&nbsp;fichar:
                    </li>
                  </ol>
                  <p>
                    <br /> Plazo: Tres meses desde la recogida de
                    informaci&oacute;n de car&aacute;cter personal.&nbsp;
                  </p>
                  <ol start="10">
                    <li>
                      En materia de&nbsp;documentaci&oacute;n contable y fiscal:
                    </li>
                  </ol>
                  <p>
                    Plazo: Se conservar&aacute;n durante el tiempo necesario
                    para cumplir con la finalidad para la que se recabaron y
                    para determinar las posibles responsabilidades que se
                    pudieran derivar de dicha finalidad y del tratamiento de los
                    datos, conforme a la Ley 58/2003, de 17 de diciembre,
                    General Tributaria, adem&aacute;s de los periodos
                    establecidos en la normativa de archivos y
                    documentaci&oacute;n.
                  </p>
                  <p>
                    Referencia normativa: Plazo general para la
                    prescripci&oacute;n de las acciones personales en la
                    legislaci&oacute;n civil (art. 1964 CC y 121-20 y ss CCCat),
                    art. 6.1.c) del Reglamento General de Protecci&oacute;n de
                    Datos, Ley 38/2003, de 17 de noviembre, General de
                    Subvenciones y Ley 58/2003, de 17 de diciembre, General
                    Tributaria.
                  </p>
                  <ol start="11">
                    <li>
                      A efectos de justificar subvenciones y ayudas
                      p&uacute;blicas nacionales o extranjeras:
                    </li>
                  </ol>
                  <p>
                    Plazo: hasta la finalizaci&oacute;n del plazo de
                    prescripci&oacute;n de la obligaci&oacute;n de justificar
                    los gastos satisfechos con cargo a las mismas.
                  </p>
                  <p>
                    Referencia normativa: Ley 38/2003, de 17 de noviembre,
                    General de Subvenciones.
                  </p>
                  <p>
                    Si desea m&aacute;s informaci&oacute;n sobre el tiempo de
                    conservaci&oacute;n de alguno de sus datos personales, puede
                    ponerse en contacto con nosotros en las direcciones de
                    correo electr&oacute;nico incluidas en esta Pol&iacute;tica
                    de privacidad.
                  </p>{' '}
                  <h4>6. Transferencia de datos personales a terceros</h4>
                  <p>
                    Podr&iacute;amos compartir o revelar su informaci&oacute;n
                    personal a terceros por alguna de las razones antes
                    mencionadas. Tales terceros pueden ser centros de
                    ense&ntilde;anza, centros universitarios, as&iacute; como
                    sus clientes, asesores externos, proveedores o
                    subcontratistas (por ejemplo, servicio postal, servicios de
                    soporte, etc.), en cumplimiento de las relaciones
                    contractuales suscritas con tales terceros, tanto dentro
                    como fuera de la UE/EEE. Asimismo, se podr&aacute;n
                    comunicar sus datos a terceros como consecuencia de
                    obligaciones o requerimientos legales, a juzgados,
                    tribunales y administraciones p&uacute;blicas en caso
                    necesario, o a cualquier otro tercero, persona o entidad a
                    quien usted nos haya autorizado.
                  </p>
                  <p>
                    Cuando debamos transferir o comunicar sus datos a terceros,
                    mantendremos vigente nuestro compromiso de salvaguardar sus
                    datos personales de conformidad con lo establecido en esta
                    Pol&iacute;tica de privacidad y tomaremos todas las medidas
                    y precauciones razonables para asegurar que los terceros
                    mantienen compromisos de confidencialidad y secreto en
                    relaci&oacute;n con la protecci&oacute;n de sus datos
                    personales. La transferencia o comunicaci&oacute;n de sus
                    datos personales se realizar&aacute; siempre cumpliendo las
                    obligaciones legales y sobre la base de acuerdos de
                    tratamiento de datos personales, con el fin de asegurar que
                    sus datos no son tratados para ninguna otra finalidad de las
                    claramente establecidas y que se toman las medidas de
                    seguridad necesarias.
                  </p>
                  <p>
                    Usted, presta su consentimiento a fin de podamos transferir
                    sus datos personales a terceros localizados fuera del
                    pa&iacute;s que se consideran transferencias
                    internacionales.
                  </p>
                  <p>
                    No compartiremos categor&iacute;as especiales de datos
                    personales relativos a usted que no hayan sido cedidos por
                    usted con ninguna persona o entidad diferente de SUPRIVE, o
                    empresas del grupo, nuestros empleados, colaboradores de
                    SUPRIVE, organismos del Estado y otros en cumplimiento de
                    obligaciones o requerimientos legales, juzgados, tribunales
                    y administraciones p&uacute;blicas, o cualquier otro tercero
                    autorizado por ley.
                  </p>{' '}
                  <h4>7. Almacenamiento y seguridad de sus datos personales</h4>
                  <p>
                    Almacenamos sus datos personales en archivos en formato
                    papel o electr&oacute;nicos. Hemos puesto salvaguardas de
                    conformidad con lo requerido por la normativa vigente para
                    proteger los datos personales que almacenamos de usos
                    indebidos, interferencias y p&eacute;rdidas, accesos no
                    autorizados, modificaciones o divulgaci&oacute;n.&nbsp;
                  </p>
                  <p>
                    SUPRIVE aplica medidas de seguridad t&eacute;cnica tales
                    como la encriptaci&oacute;n en aquellos casos en los que
                    razonablemente es posible. Asimismo, diferentes sistemas
                    como el archivo seguro de documentos originales o la
                    limitaci&oacute;n de acceso a datos personales a las
                    personas adecuadamente autorizadas para ello permiten dotar
                    de seguridad al tratamiento de sus datos personales.
                  </p>
                  <p>
                    Adem&aacute;s, SUPRIVE adopta las medidas razonables para
                    destruir de forma permanente todos los datos personales una
                    vez finalizado su plazo de utilizaci&oacute;n de conformidad
                    con esta Pol&iacute;tica de privacidad.
                  </p>{' '}
                  <h4>8. Derechos relativos a sus datos personales</h4>
                  <p>
                    SUPRIVE procura que la informaci&oacute;n que conserva sobre
                    usted sea precisa, actualizada, completa y relevante. No
                    obstante, usted tiene derecho a acceder a dicha
                    informaci&oacute;n y corregirla si fuera incorrecta,
                    imprecisa, desactualizada o incompleta. Asimismo, puede
                    retirar su consentimiento al tratamiento de sus datos
                    personales.
                  </p>
                  <p>
                    Adem&aacute;s, si tiene alguna pregunta sobre esta
                    Pol&iacute;tica de privacidad o desea acceder o de cualquier
                    modo modificar o rectificar sus datos personales, puede
                    contactar con nuestro delegado de protecci&oacute;n de datos
                    (DPO), enviando su comunicaci&oacute;n a la calle Gran
                    V&iacute;a Carlos III, 124 entr 1&ordm; 08034 Barcelona o
                    bien enviando un correo electr&oacute;nico a{' '}
                    <a href="mailto:dpo@suprive.com">dpo@suprive.com</a>.
                  </p>
                  <p>
                    <span className="boldText">
                      &iquest;Qu&eacute; derechos le amparan con relaci&oacute;n
                      al tratamiento de sus datos (DERECHO ARSOPOL)?
                    </span>
                  </p>
                  <ul>
                    <li>
                      Derecho a solicitar el acceso a sus datos personales.
                    </li>
                    <li>
                      Derecho a solicitar su rectificaci&oacute;n o
                      supresi&oacute;n.
                    </li>
                    <li>
                      Derecho a solicitar la limitaci&oacute;n de su
                      tratamiento.
                    </li>
                    <li>Derecho a oponerse al tratamiento.</li>
                    <li>Derecho a la portabilidad de los datos.</li>
                    <li>Derecho a retirar el consentimiento prestado.&nbsp;</li>
                  </ul>
                  <p>
                    Acceso/rectificaci&oacute;n y supresi&oacute;n: las personas
                    interesadas tienen derecho a acceder a sus datos personales,
                    as&iacute; como a solicitar la rectificaci&oacute;n de los
                    datos inexactos o, en su caso, solicitar su supresi&oacute;n
                    cuando, entre otros motivos, los datos ya no sean necesarios
                    para la ejecuci&oacute;n del contrato.
                  </p>
                  <p>
                    <span className="boldText">Limitaci&oacute;n</span>: en
                    determinadas circunstancias, los interesados podr&aacute;n
                    solicitar la limitaci&oacute;n del tratamiento de sus datos,
                    en cuyo caso &uacute;nicamente los conservaremos para el
                    ejercicio o defensa de reclamaciones.
                  </p>
                  <p>
                    <span className="boldText">Oposici&oacute;n</span>: los
                    interesados podr&aacute;n oponerse al tratamiento de sus
                    datos. SUPRIVE dejar&aacute; de tratar los datos, salvo por
                    motivos leg&iacute;timos, imperiosos, o el ejercicio o
                    defensa de posibles reclamaciones, y los mantendremos
                    debidamente bloqueados durante el plazo correspondiente
                    mientras persistan las obligaciones legales.
                  </p>
                  <p>
                    Igualmente, el interesado tiene derecho a oponerse a la
                    adopci&oacute;n de decisiones individuales automatizadas que
                    pudieran producir efectos jur&iacute;dicos o afectarle
                    significativamente.
                  </p>
                  <p>
                    <span className="boldText">Portabilidad</span>: las personas
                    interesadas pueden solicitar y recibir los datos que le
                    incumban y que nos haya facilitado o solicitar que se los
                    enviemos a otro&nbsp;
                    <span className="boldText">
                      responsable del tratamiento
                    </span>
                    &nbsp;de su elecci&oacute;n, en un formato estructurado de
                    uso com&uacute;n y lectura mec&aacute;nica.
                  </p>
                  <p>
                    <span className="boldText">
                      &iquest;Cu&aacute;ndo responderemos a su solicitud?
                    </span>
                  </p>
                  <p>
                    Responderemos a sus peticiones a la mayor brevedad posible
                    y, en todo caso, en el plazo de un mes desde la
                    recepci&oacute;n de su solicitud. Dicho plazo podr&aacute;
                    prorrogarse otros dos meses en caso necesario, teniendo en
                    cuenta la complejidad y el n&uacute;mero de solicitudes. El
                    responsable informar&aacute; al interesado de la
                    pr&oacute;rroga dentro del primer mes desde la solicitud.
                  </p>
                  <p>
                    <span className="boldText">
                      &iquest;Usted tiene derecho a retirar su consentimiento?
                    </span>
                  </p>
                  <p>
                    El interesado tiene derecho a retirar en cualquier momento
                    el consentimiento prestado, sin que ello afecte a la licitud
                    del tratamiento basado en el consentimiento prestado en el
                    momento de sus suscripciones o aportaci&oacute;n de datos.
                  </p>
                  <p>
                    <span className="boldText">
                      &iquest;D&oacute;nde debe dirigirse para el ejercicio de
                      sus derechos?
                    </span>
                  </p>
                  <p>
                    Para el ejercicio de sus derechos, el interesado
                    podr&aacute; remitir carta con todos sus datos, incluyendo
                    fotocopia de DNI o pasaporte e indicaci&oacute;n del derecho
                    que se ejerce dirigi&eacute;ndose a SUPRIVE direcci&oacute;n
                    Gran V&iacute;a Carlos III, 124 entr 1&ordm; 08034
                    Barcelona, o a trav&eacute;s de{' '}
                    <a href="mailto:dpo@suprive.com">dpo@suprive.com</a>.
                  </p>
                  <p>
                    <span className="boldText">
                      &iquest;Usted tiene derecho a reclamar?
                    </span>
                  </p>
                  <p>
                    Le informamos que si usted presenta una solicitud para el
                    ejercicio de estos derechos y considera que no ha sido
                    atendida adecuadamente por nuestra instituci&oacute;n, puede
                    presentar una reclamaci&oacute;n ante la Agencia
                    Espa&ntilde;ola de Protecci&oacute;n de Datos.
                  </p>{' '}
                  <h4>9. Comunicaciones electr&oacute;nicas</h4>
                  <p>
                    En cumplimiento de la Ley 34/2002, de 11 de julio, de
                    Servicios de la Sociedad de la Informaci&oacute;n y Comercio
                    Electr&oacute;nico, le informamos que las direcciones de
                    correo electr&oacute;nico podr&aacute;n ser utilizadas para
                    el env&iacute;o de informaci&oacute;n sobre la SUPRIVE. Si
                    no desea recibir informaci&oacute;n o quiere revocar el
                    consentimiento otorgado para el tratamiento de sus datos
                    rogamos lo haga de la forma arriba indicada o
                    dirigi&eacute;ndose a la siguiente direcci&oacute;n de
                    correo electr&oacute;nico&nbsp;
                    <a href="mailto:dpo@suprive.com">dpo@suprive.com</a>.
                  </p>{' '}
                  <h4>
                    10. Actualizaci&oacute;n de nuestra Pol&iacute;tica de
                    privacidad
                  </h4>
                  <p>
                    Esta Pol&iacute;tica puede ser actualizada, revisada o
                    modificada en cualquier momento, as&iacute; como
                    tambi&eacute;n el modo en el cual procesamos sus datos
                    personales. Siempre dispondremos de la versi&oacute;n
                    m&aacute;s actualizada de la Pol&iacute;tica de privacidad
                    en nuestra p&aacute;gina web&nbsp;
                    <a href="http://www.suprive.com">www.suprive.com</a>
                    &nbsp;Cualquier actualizaci&oacute;n tendr&aacute; vigencia
                    desde su publicaci&oacute;n.
                  </p>
                  <h4>11. Informaci&oacute;n adicional</h4>
                  <p>
                    A petici&oacute;n del usuario, adem&aacute;s de la
                    informaci&oacute;n contenida en esta Pol&iacute;tica de
                    privacidad, se le podr&aacute; proporcionar
                    informaci&oacute;n adicional sobre tratamientos
                    espec&iacute;ficos, o la recopilaci&oacute;n y procesamiento
                    de datos personales.
                  </p>
                  <p>
                    Para m&aacute;s informaci&oacute;n sobre privacidad en
                    general, puede visitar la p&aacute;gina web de la Agencia
                    Espa&ntilde;ola de Protecci&oacute;n de Datos (AEPD)
                    en&nbsp;
                    <a href="http://www.aepd.es/">www.aepd.es</a>&nbsp;y de la
                    Autoridad Catalana de Protecci&oacute;n de Datos (APDCAT)
                    en&nbsp;
                    <a href="http://www.apdcat.gencat.cat/">
                      www.apdcat.gencat.cat
                    </a>
                    .
                  </p>
                  <p>REVISI&Oacute;N AGOSTO 2020</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterEs polygons={true} />
    </Layout>
  );
};

export default PoliticaDePrivacidad;
