import React from 'react';
import { Row } from 'react-bootstrap';
import InsideSection from '../../sections_Ads/insideSection/InsideSection';

const Inside = () => {
  return (
    <Row className="RootInside RootContainers sectionsPadding">
      <InsideSection />
    </Row>
  );
};

export default Inside;
