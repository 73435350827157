import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import updateImg from '../../../../assets/imgs/update/update-2.0-ru-min.jpg';
import updateImgWebp from '../../../../assets/imgs/update/update-2.0-ru-min.webp';
import percentOff from '../../../../assets/imgs/update/percentOff-min.png';
import percentOffWebp from '../../../../assets/imgs/update/percentOff-min.webp';
import updateImgMobile from '../../../../assets/imgs/update/update-2.0-ruMobile-min.jpg';
import updateImgMobileWebp from '../../../../assets/imgs/update/update-2.0-ruMobile-min.webp';

import { isPromoActive, ScrollContext } from '../../../../layout/Layout';

const Update2_0Section = () => {
  const getScroll = useContext(ScrollContext);

  return (
    <Row className="rowSection">
      <Col
        xs={12}
        lg={12}
        className="update2_0Col update2_0ColImgs gs_reveal gs_reveal_direction"
      >
        <picture>
          <source
            srcSet={updateImgMobileWebp}
            media="(max-width: 991px)"
            type="image/webp"
          />
          <source srcSet={updateImgMobile} media="(max-width: 991px)" />
          <source srcSet={updateImgWebp} type="image/webp" />
          <img src={updateImg} alt="" className="updateImg" />
        </picture>
        <picture className={isPromoActive() ? '' : 'd-none'}>
          <source srcSet={percentOffWebp} type="image/webp" />
          <img
            src={percentOff}
            alt=""
            className="percentOff"
            onClick={() => {
              getScroll().scrollTo(document.querySelector('#payment'), {
                offset: -72,
              });
            }}
          />
        </picture>
      </Col>
      <Col
        xs={12}
        lg={12}
        className="update2_0Col update2_0ColButton gs_reveal gs_reveal_direction"
      >
        <Button
          bsPrefix
          className="buttonStyle buttonText button--calypso button--calypso-primary"
          onClick={() => {
            getScroll().scrollTo(document.querySelector('#payment'), {
              offset: -72,
            });
          }}
        >
          <span>
            НАЧАТЬ
            <br />
            ТРАНСФОРМАЦИЮ
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default Update2_0Section;
