import React, { useContext, useState } from 'react';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import CustomToggle from './components/CustomToggle';
import textBackground from '../../../../assets/imgs/icons/textBackgroundSecondary.svg';
import { ScrollContext } from '../../../../layout/Layout';

const FaqSection = () => {
  const getScroll = useContext(ScrollContext);

  const [activeEventKey, setActiveEventKey] = useState('0');

  const updateActiveEventKey = (newEventKey) => {
    setActiveEventKey(newEventKey);
  };
  return (
    <Row className="rowSection" id="faq">
      <Col xs={12} lg={12} className="faqCol faqColTitle">
        <h2 className="textBackgroundWrap gs_reveal gs_reveal_fromLeft">
          <img src={textBackground} alt="" className="textBackground" />
          PREGUNTAS <br className="d-block d-lg-none" />
          FRECUENTES
        </h2>
      </Col>
      <Accordion defaultActiveKey="0" bsPrefix className="accordionFaq row">
        <Col xs={12} lg={6} className="faqCol">
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="0"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={'¿Y si no me gusta el curso?'} /* TITLE */
            />
            <Accordion.Collapse eventKey="0">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Si por cualquier motivo no quedas satisfecha con el curso o
                    ves que no es para tí, tienes 15 días para solicitar su
                    devolución. Recuerda que, para poder solicitar la
                    devolución, no debes haber superado el 20% del curso.
                    <br />
                    <a href="mailto:dudascursokat@suprive.com">
                      dudascursokat@suprive.com
                    </a>
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="1"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={'¿Cómo puedo entrar al curso online?'} /* TITLE */
            />
            <Accordion.Collapse eventKey="1">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Para entrar al curso tienes que hacer click arriba a la
                    derecha en “Mis Cursos” y te llevará a la página para poner
                    tu usuario y contraseña.
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          {/* <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="2"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={'¿Incluye algo sobre alimentación?'} 
            />
            <Accordion.Collapse eventKey="2">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Sí, hay un módulo entero dedicado exclusivamente a la
                    alimentación para que tú misma crees el menú que desees en
                    base a tus necesidades y siguiendo la lista de alimentos
                    saludable que te recomienda mi nutricionista.
                  </p>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card> */}
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="3"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={'¿Puedo ver los videos varias veces?'} /* TITLE */
            />
            <Accordion.Collapse eventKey="3">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Sí, tienes acceso ilimitado a todo el contenido del curso.
                    Puedes repetirlo tantas veces como quieras.
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="4"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={
                '¿Puedo compartir el curso de Kat con mis amigas?'
              } /* TITLE */
            />
            <Accordion.Collapse eventKey="4">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    No, lo siento mucho. El curso es de uso individual y está
                    prohibido compartir, distribuir o comercializar este curso.
                    Si vemos esta conducta tendremos que sancionar tu cuenta.
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="10"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={
                '¿Qué necesito para poder hacer el curso y conseguir resultados?'
              } /* TITLE */
            />
            <Accordion.Collapse eventKey="10">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Solo necesitas un móvil o un ordenador y conexión a internet
                    para poder ver el curso. Además, lo puedes hacer tanto desde
                    tu casa, como al aire libre, como en un gimnasio.
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Col>
        <Col xs={12} lg={6} className="faqCol">
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="5"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={'¿Cómo puedo hacer el pago del curso?'} /* TITLE */
            />
            <Accordion.Collapse eventKey="5">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Puedes pagar con tarjetas de crédito o débito VISA,
                    MASTERCARD, AMERICAN EXPRESS y PAYPAL.
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="6"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={'¿Sólo hago el pago una vez?'} /* TITLE */
            />
            <Accordion.Collapse eventKey="6">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Sí, el curso online se paga una sola vez y tienes acceso a
                    él para siempre.
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="7"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={
                'Cuando me compre el curso, ¿tendré acceso de por vida?'
              } /* TITLE */
            />
            <Accordion.Collapse eventKey="7">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Sí, una vez compres el curso siempre lo tendrás disponible y
                    lo podrás ver.
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="8"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={'¿Puedo hacerlo desde casa?'} /* TITLE */
            />
            <Accordion.Collapse eventKey="8">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Sí que puedes, el curso está pensado tanto para hacerlo en
                    el gimnasio como para hacerlo en casa.
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_direction">
            <CustomToggle
              eventKey="9"
              activeEventKey={activeEventKey}
              updateEventKey={updateActiveEventKey}
              faqTitle={'¿Está disponible en mi país?'} /* TITLE */
            />
            <Accordion.Collapse eventKey="9">
              <Card.Body className="accordionBody">
                <div className="accordionText">
                  <p>
                    Sí, se trata de un curso online al que accedes desde
                    internet por lo que está disponible en todos los países del
                    mundo.
                  </p>
                  {/* DESC */}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard accordionCardSpace accordionCardFaq cardConfig gs_reveal gs_reveal_scale">
            <Button
              bsPrefix
              className="buttonStyle buttonText button--calypso button--calypso-primary"
              onClick={() => {
                getScroll().scrollTo(document.querySelector('#payment'), {
                  offset: -72,
                });
              }}
            >
              <span>EMPEZAR TRANSFORMACIÓN</span>
            </Button>
          </Card>
        </Col>
      </Accordion>
    </Row>
  );
};

export default FaqSection;
