import React from 'react';

// PAGES
import Layout, { isPromoActive } from '../layout/Layout';
import Home from './landing/pages/home/Home';
import Footer from '../components/footer/Footer';
import Cite from './landing/pages/cite/Cite';
import AboutMe from './landing/pages/aboutMe/AboutMe';
import SliderInfo from './landing/pages/sliderInfo/SliderInfo';
import Info from './landing/pages/info/Info';
import Members from './landing/pages/members/Members';
import Inside from './landing/pages/inside/Inside';
import Modules from './landing/pages/modules/Modules';
import Tips from './landing/pages/tips/Tips';
import Payment from './landing/pages/payment/Payment';
import Faq from './landing/pages/faq/Faq';
import Update2_0 from './landing/pages/update2_0/Update2_0';

export const MainRu = (props) => {
  return (
    <Layout>
      <Home />
      {/* <Cite /> */}
      <AboutMe />
      <Update2_0 />
      <SliderInfo />
      <Info />
      <Members />
      <Inside />
      <Modules />
      <Tips />
      <Payment />
      <Faq />
      <Footer />
    </Layout>
  );
};

export default MainRu;
