import React, { useContext, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import heroTitle from '../../../../assets/imgs/home/heroTitleAds.svg';
import downArrow from '../../../../assets/imgs/icons/downArrow.svg';
import gsap from 'gsap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { PlayerTogglerContext, ScrollContext } from '../../../../layout/Layout';

const HomeSection = () => {
  const getScroll = useContext(ScrollContext);
  const PlayerToggler = useContext(PlayerTogglerContext);

  useEffect(() => {
    gsap.fromTo(
      '.downArrow',
      {
        y: 0,
      },
      {
        y: -30,
        duration: 2,
        ease: 'easeInOut',
        yoyo: true,
        repeat: -1,
      }
    );
  }, []);
  return (
    <Row className="rowSection">
      <Col xs={12} lg={6} className="homeCol homeColTitle">
        <div className="homeTitleDivWrap gs_reveal gs_reveal_fromLeft">
          {/* <h3 className="titleHero">KAT SAFAROVA</h3>
          <h1>метод</h1> */}
          <div>
            <img src={heroTitle} alt="" className="homeTitle" />
          </div>
        </div>
      </Col>
      <Col xs={12} lg={6} className="homeCol">
        <div className="buttonGroupWrap gs_reveal gs_reveal_fromRight">
          <Button
            bsPrefix
            className="buttonStyle buttonText button--calypso button--calypso-primary"
            onClick={() => {
              getScroll().scrollTo(document.querySelector('#payment'), {
                offset: -72,
              });
            }}
          >
            <span>
              Записаться
              <br />
              по супер цене
            </span>
          </Button>
          <Button
            bsPrefix
            className="buttonStyle buttonText button--calypso button--calypso-secondary"
            onClick={() => {
              PlayerToggler.setToggler({
                opened: !PlayerToggler.toggler.opened,
                src: 'https://player.vimeo.com/video/568330230?badge=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479',
              });
            }}
          >
            <FontAwesomeIcon icon={faPlay} />
            <span>Посмотреть трейлер</span>
          </Button>
        </div>
      </Col>
      <div className="downArrowWrap">
        <img
          src={downArrow}
          alt=""
          className="downArrow"
          onClick={() => {
            getScroll().scrollTo(document.querySelector('#aboutMe'));
          }}
        />
      </div>
    </Row>
  );
};

export default HomeSection;
