import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import validatePromCode from '../../../../assets/js/discounts';
import PaymentSection from '../../sections/paymentSection/PaymentSection';

const Payment = () => {
  const [discount, setDiscount] = useState({});
  useEffect(() => {
    const discountData = validatePromCode();
    if (discountData) {
      setDiscount(discountData);
    }
  }, []);

  return (
    <Row className="RootPayment RootContainers sectionsPadding sectionPolygon sectionPolygonBottomCenter">
      <div className="sectionPolygon1 spb" />
      <div className="sectionPolygon2 spb" />
      <PaymentSection discount={discount} />
    </Row>
  );
};

export default Payment;
