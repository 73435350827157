import React, { useEffect } from 'react';

import Router from './routes/Router';
import { HelmetProvider } from 'react-helmet-async';

// SASS Importing
import './App.scss';

export const App = (props) => {
  useEffect(() => {
    const onTidioChatApiReady = () => {
      function hideTdo() {
        var timer = null;
        var target = document.querySelector('#tidio-chat iframe');
        if (!target) {
          if (timer !== null) {
            clearTimeout(timer);
          }
          timer = setTimeout(hideTdo, 500);
          return;
        } else {
          var timer2 = null;
          var tdo = document
            .querySelector('#tidio-chat iframe')
            .contentDocument.querySelector('.widgetLabel');
          if (!tdo) {
            if (timer2 !== null) {
              clearTimeout(timer2);
            }
            timer2 = setTimeout(hideTdo, 1);
            return;
          }
          document
            .querySelector('#tidio-chat iframe')
            .contentDocument.querySelector('.widgetLabel').style.display =
            'none';
          return true;
        }
      }

      hideTdo();

      setInterval(hideTdo, 10);

      window.tidioChatApi.setColorPalette('#8b12c3');
    };

    let tidioChatReadyListener = null;

    if (window.tidioChatApi) {
      window.tidioChatApi.on('ready', onTidioChatApiReady);
    } else {
      tidioChatReadyListener = document.addEventListener(
        'tidioChat-ready',
        onTidioChatApiReady
      );
    }
    return () => {
      if (tidioChatReadyListener) {
        document.removeEventListener(tidioChatReadyListener);
      }
    };
  }, []);
  return (
    <HelmetProvider>
      <Router />
    </HelmetProvider>
  );
};

export default App;
