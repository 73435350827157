import React, { useContext, useRef } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import CustomPrevArrow from '../../../../components/customSlickArrows/CustomArrowsSlickLeft';
import CustomNextArrow from '../../../../components/customSlickArrows/CustomArrowsSlickRight';
import sliderImg1 from '../../../../assets/imgs/sliderInfo/1.jpg';
import sliderImg2 from '../../../../assets/imgs/sliderInfo/2.jpg';
import sliderImg3 from '../../../../assets/imgs/sliderInfo/3.jpg';
import sliderImg4 from '../../../../assets/imgs/sliderInfo/4.jpg';
import sliderImg5 from '../../../../assets/imgs/sliderInfo/5.jpg';
import { ScrollContext } from '../../../../layout/Layout';

const SliderInfoSection = () => {
  const getScroll = useContext(ScrollContext);

  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slideCount: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    pauseOnFocus: true,
    initialSlide: 0,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    arrows: true,
    nextArrow: (
      <CustomNextArrow
        className={'customArrows'}
        sliderArrowMouse="sliderMouse"
        to="next"
      />
    ),
    prevArrow: (
      <CustomPrevArrow
        className="customArrows"
        sliderArrowMouse="sliderMouse"
        to="prev"
      />
    ),

    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,

          arrows: false,
        },
      },
    ],
  };
  const next = () => {
    sliderRef.current.slickNext();
  };

  const prev = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <Row className="rowSection gs_reveal gs_reveal_direction">
      <Row className="sliderInfoRow sliderInfoRowTitle">
        <Col xs={12} lg={12} className="sliderInfoCol">
          <h2>
            На кого рассчитан <br className="d-block d-lg-none" />
            данный <span className="featuredText">курс?</span>
          </h2>
        </Col>
      </Row>
      <Row className="sliderInfoRow sliderInfoRowSlick">
        <Col xs={12} lg={12} className="sliderInfoCol">
          <Slider {...settings} ref={sliderRef}>
            <div className="item">
              <Card bsPrefix className="cardStyle neonBorder neonBorderPrimary">
                <div>
                  <Card.Header className="cardHeader triangleTopLeft triangleTopLeftBlurr triangleTopLeftBlurrPrimary">
                    <div>
                      <img src={sliderImg1} alt="" className="sliderImg" />
                    </div>
                  </Card.Header>
                  <Card.Body className="cardBody">
                    <div className="cardBodyWrap">
                      <h5 className="cardTitle">
                        Хочешь узнать, как я добилась всесторонних изменений?
                      </h5>
                      <p className="cardText">
                        Не только внешних, но и внутренних.
                        <br />И в первую очередь, это касается моего отличного
                        настроения и неиссякаемой энергии
                      </p>
                    </div>
                  </Card.Body>
                </div>
              </Card>
            </div>
            <div className="item">
              <Card
                bsPrefix
                className="cardStyle neonBorder neonBorderSecondary"
              >
                <div>
                  <Card.Header className="cardHeader triangleTopLeft triangleTopLeftBlurr triangleTopLeftBlurrSecondary">
                    <div>
                      <img src={sliderImg2} alt="" className="sliderImg" />
                    </div>
                  </Card.Header>
                  <Card.Body className="cardBody">
                    <div className="cardBodyWrap">
                      <h5 className="cardTitle">У тебя мало времени</h5>
                      <p className="cardText">
                        Ты хочешь тратить на тренировки 30 минут в день,
                        занимаясь дома, в спортзале или на свежем воздухе.
                      </p>
                    </div>
                  </Card.Body>
                </div>
              </Card>
            </div>
            <div className="item">
              <Card bsPrefix className="cardStyle neonBorder neonBorderPrimary">
                <div>
                  <Card.Header className="cardHeader triangleTopLeft triangleTopLeftBlurr triangleTopLeftBlurrPrimary">
                    <div>
                      <img src={sliderImg3} alt="" className="sliderImg" />
                    </div>
                  </Card.Header>
                  <Card.Body className="cardBody">
                    <div className="cardBodyWrap">
                      <h5 className="cardTitle">
                        Ты хочешь научиться поддерживать здоровые привычки
                      </h5>
                      <p className="cardText">
                        В независимости от твоего возраста и предыдущего опыта.
                      </p>
                    </div>
                  </Card.Body>
                </div>
              </Card>
            </div>
            <div className="item">
              <Card
                bsPrefix
                className="cardStyle neonBorder neonBorderSecondary"
              >
                <div>
                  <Card.Header className="cardHeader triangleTopLeft triangleTopLeftBlurr triangleTopLeftBlurrSecondary">
                    <div>
                      <img src={sliderImg4} alt="" className="sliderImg" />
                    </div>
                  </Card.Header>
                  <Card.Body className="cardBody">
                    <div className="cardBodyWrap">
                      <h5 className="cardTitle">
                        Ты хочешь, чтобы твои тренировки были эффективными
                      </h5>
                      <p className="cardText">
                        Особенно ягодицы, живот, руки и ноги.
                      </p>
                    </div>
                  </Card.Body>
                </div>
              </Card>
            </div>
            <div className="item">
              <Card bsPrefix className="cardStyle neonBorder neonBorderPrimary">
                <div>
                  <Card.Header className="cardHeader triangleTopLeft triangleTopLeftBlurr triangleTopLeftBlurrPrimary">
                    <div>
                      <img src={sliderImg5} alt="" className="sliderImg" />
                    </div>
                  </Card.Header>
                  <Card.Body className="cardBody">
                    <div className="cardBodyWrap">
                      <h5 className="cardTitle">
                        Ты уже пыталась сделать это раньше
                      </h5>
                      <p className="cardText">
                        Но потерпела неудачу и всё забросила, так и не увидев
                        результат.
                      </p>
                    </div>
                  </Card.Body>
                </div>
              </Card>
            </div>
          </Slider>
          <div className="customArrowsWrapper d-flex d-lg-none">
            <Button bsPrefix onClick={prev} className="sliderArrowsCustom">
              <svg
                width="17"
                height="30"
                viewBox="0 0 17 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.6789 1.25L1.92889 15L15.6789 28.75"
                  stroke="white"
                  strokeWidth="2"
                />
              </svg>
            </Button>
            <Button bsPrefix onClick={next} className="sliderArrowsCustom">
              <svg
                width="17"
                height="30"
                viewBox="0 0 17 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.50018 28.75L15.2502 15L1.50019 1.25"
                  stroke="white"
                  strokeWidth="2"
                />
              </svg>
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="sliderInfoRow sliderInfoRowButton">
        <Col
          xs={12}
          lg={12}
          className="sliderInfoCol gs_reveal gs_reveal_scale"
        >
          <Button
            bsPrefix
            className="buttonStyle buttonText button--calypso button--calypso-primary"
            onClick={() => {
              getScroll().scrollTo(document.querySelector('#payment'), {
                offset: -72,
              });
            }}
          >
            <span>НАЧАТЬ ТРАНСФОРМАЦИЮ</span>
          </Button>
        </Col>
      </Row>
    </Row>
  );
};

export default SliderInfoSection;
