import React, { useContext } from 'react';
import { Row, Button, Col, ListGroup } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import brandLogo from '../../assets/imgs/home/heroTitleEs.svg';
import { ScrollContext } from '../../layout/Layout';
export const FooterEs = (props) => {
  const getScroll = useContext(ScrollContext);

  return (
    <Row className="RootFooter sectionPolygon sectionPolygonTopExterior">
      {props.polygons != null ? (
        ''
      ) : (
        <>
          <div className="sectionPolygon1 spb" />
          <div className="sectionPolygon2 spb" />
        </>
      )}

      <Row className="rowSection">
        <Col xs={12} lg={3} className="brandFooter footerCol">
          <div className="footerColWrapper">
            <img src={brandLogo} alt="" className="footerBrand" />
          </div>
        </Col>
        <Col xs={12} lg={5} className="brandFooter footerCol d-none d-lg-flex">
          <p className="footerFaceText">
            Esta web no pertenece a Facebook o Facebook Inc. y tampoco está
            respaldada por Facebook de ninguna manera. Facebook proporciona una
            plataforma para ofrecer publicidad, pero los contenidos ofrecidos en
            esta web no son representativos de Facebook Inc.
            <br />
            <br />
            This website does not belong to Facebook or Facebook Inc. and is not
            endorsed by Facebook in any way. Facebook provides a platform to
            offer advertising, but the content offered on this website is not
            representative of Facebook Inc.
          </p>
        </Col>
        <Col xs={12} lg={2} className="interestLinks footerCol">
          <ListGroup className="listGroupFooter">
            <ListGroup.Item className="listGroupItemFooter listGroupTitle footerTitle">
              Páginas
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter footerText listGroupText">
              <Button
                as="a"
                bsPrefix
                className="footerText"
                onClick={() => {
                  getScroll().scrollTo(document.querySelector('#info'), {
                    offset: -72,
                  });
                }}
              >
                Qúe es el método
              </Button>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter footerText listGroupText">
              <Button
                as="a"
                bsPrefix
                className="footerText"
                onClick={() => {
                  getScroll().scrollTo(document.querySelector('#sliderInfo'), {
                    offset: -72,
                  });
                }}
              >
                Para quién es este método
              </Button>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter footerText listGroupText">
              <Button
                as="a"
                bsPrefix
                className="footerText"
                onClick={() => {
                  getScroll().scrollTo(document.querySelector('#aboutMe'), {
                    offset: -72,
                  });
                }}
              >
                Quien soy
              </Button>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter footerText listGroupText">
              <Button
                as="a"
                bsPrefix
                className="footerText"
                onClick={() => {
                  getScroll().scrollTo(document.querySelector('#advantages'), {
                    offset: -72,
                  });
                }}
              >
                Ventajas del método
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        {/* <Col xs={1}></Col> */}
        <Col xs={12} lg={2} className="footerCol">
          <ListGroup className="listGroupFooter">
            <ListGroup.Item className="listGroupItemFooter listGroupTitle footerTitle">
              Legal
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter footerText listGroupText">
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={(location) => ({
                  ...location,
                  pathname: '/es/politica-de-privacidad',
                })}
              >
                Política de Privacidad
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter footerText listGroupText">
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={(location) => ({
                  ...location,
                  pathname: '/es/politica-de-cookies',
                })}
              >
                Política de Cookies
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter footerText listGroupText">
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={(location) => ({
                  ...location,
                  pathname: '/es/terminos-y-condiciones',
                })}
              >
                Avisos Legal
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter footerText listGroupText">
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={(location) => ({
                  ...location,
                  pathname: '/es/exclusion-de-responsabilidad',
                })}
              >
                Exclusión de responsabilidad
              </Link>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col xs={12} lg={5} className="brandFooter footerCol d-flex d-lg-none">
          <p className="footerFaceText">
            Esta web no pertenece a Facebook o Facebook Inc. y tampoco está
            respaldada por Facebook de ninguna manera. Facebook proporciona una
            plataforma para ofrecer publicidad, pero los contenidos ofrecidos en
            esta web no son representativos de Facebook Inc.
            <br />
            <br />
            This website does not belong to Facebook or Facebook Inc. and is not
            endorsed by Facebook in any way. Facebook provides a platform to
            offer advertising, but the content offered on this website is not
            representative of Facebook Inc.
          </p>
        </Col>
        <Col xs={12} className="poweredSection">
          <p className="powered">
            Powered by{' '}
            <a
              href="https://suprive.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Suprive
            </a>
          </p>
        </Col>
      </Row>
    </Row>
  );
};
export default FooterEs;
