import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Nav, Button, Dropdown } from 'react-bootstrap';
// import { isPromoActive, ScrollContext } from '../../layout/Layout';

import { ScrollContext } from '../../../layout/Layout';

export const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua);
};

export const CustomNavbarThankEs = (props) => {
  const getScroll = useContext(ScrollContext);
  const [navExpanded, setNavExpanded] = useState(false);
  const [dropShow, setDropShow] = useState(false);

  const [navColor, setNavColor] = useState('transparent');
  // const [navLogo, setNavLogo] = useState(logo2);

  useEffect(() => {
    // setTimeout(
    //   () =>
    window.addEventListener('DOMContentLoaded', (event) => {
      getScroll().on('scroll', (instance) => {
        if (instance.direction) {
          setNavExpanded(false);
          setDropShow(false);
        }
        if (instance.scroll.y < 50) {
          document.querySelector('#navbar').classList.remove('navbarScrolled');
          setTimeout(() => {
            // document.querySelector('.navBrand').style.mixBlendMode = 'unset';
          }, 300);
        } else {
          document.querySelector('#navbar').classList.add('navbarScrolled');
        }
      });
    });
    //   100
    // );
  }, []);

  const collapsetoggle = () => {
    setNavExpanded(!navExpanded);

    if (!navExpanded) {
      document.querySelector('.navBrand').style.mixBlendMode = 'difference';
      document.querySelector('.navbar-toggler').style.mixBlendMode =
        'difference';
    } else {
      setTimeout(() => {
        document.querySelector('.navBrand').style.mixBlendMode = 'unset';
        document.querySelector('.navbar-toggler').style.mixBlendMode = 'unset';
      }, 300);
    }
  };

  return (
    <Navbar
      id="navbar"
      collapseOnSelect
      expand="lg"
      className={
        // isPromoActive().isActive
        //   ? 'nav-color-scroll nav-promo'
        // :
        'nav-color-scroll navbarThank'
      }
      variant="light"
      fixed="top"
      expanded={navExpanded}
      onToggle={collapsetoggle}
      // data-scroll
      // data-scroll-sticky
      // data-scroll-target="#inicio"
      // style={isMobile() ? { background: navColor } : ''}
    >
      <Navbar.Brand className="navBrand" id="navbarBrandContainer">
        {/* <img alt="" src={navLogo} className="navbarLogo" /> */}
        <svg
          width="310"
          height="19"
          viewBox="0 0 310 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="navbarLogo"
        >
          <path
            d="M5.34454 18.6254V14.3169L10.5838 10.8113L19.0086 18.6254H26.0908L14.7436 8.10845L25.5643 0.374648H18.2188L8.50387 6.90422L5.34454 9.5V0.374648H0V18.6254H5.34454Z"
            fill="#F3F3F3"
          />
          <path
            d="M50.6842 18.6254H57.1609L45.4713 0.374648H38.3628L26.647 18.6254H33.071L35.2562 15.093H48.5254L50.6842 18.6254ZM37.573 11.3197L41.8908 4.30845L46.1822 11.3197H37.573Z"
            fill="#F3F3F3"
          />
          <path
            d="M64.0178 5.03099V18.6254H69.3624V5.03099H79.9988V0.374648H53.3551V5.03099H64.0178Z"
            fill="#F3F3F3"
          />
          <path
            d="M112.384 13.4338C112.384 9.66056 110.067 7.84084 104.906 7.54648L95.1126 6.95775C93.4802 6.8507 92.7957 6.69014 92.7957 5.67324C92.7957 4.57606 93.5066 4.30845 98.3509 4.30845C102.853 4.30845 107.592 4.8169 110.646 5.43239L111.515 1.33803C107.961 0.561972 102.774 0.0535222 99.5883 0.0535222C91.2687 0.0535222 87.7408 1.71268 87.7408 5.80704C87.7408 9.20563 89.768 11.0254 95.3495 11.293L105.275 11.8817C106.67 12.0155 107.329 12.1493 107.329 13.2732C107.329 14.3704 106.565 14.7183 102.353 14.7183C96.9555 14.7183 92.4798 13.9958 88.7939 13.1662L88.0304 17.5282C91.2687 18.331 96.4553 18.9732 100.931 18.9732C109.803 18.9732 112.384 17.2873 112.384 13.4338Z"
            fill="#F3F3F3"
          />
          <path
            d="M136.507 18.6254H142.983L131.294 0.374648H124.185L112.469 18.6254H118.893L121.079 15.093H134.348L136.507 18.6254ZM123.395 11.3197L127.713 4.30845L132.005 11.3197H123.395Z"
            fill="#F3F3F3"
          />
          <path
            d="M149.453 18.6254V12.6577H167.567V8.37606H149.453V5.03099H168.067V0.374648H144.109V18.6254H149.453Z"
            fill="#F3F3F3"
          />
          <path
            d="M190.576 18.6254H197.053L185.363 0.374648H178.255L166.539 18.6254H172.963L175.148 15.093H188.417L190.576 18.6254ZM177.465 11.3197L181.783 4.30845L186.074 11.3197H177.465Z"
            fill="#F3F3F3"
          />
          <path
            d="M203.523 18.6254V13.3268H210.342L216.792 18.6254H223.505L217.608 13.9155L216.186 13.0324L217.345 13.0056C221.32 12.6845 223.663 10.7577 223.663 6.74366C223.663 1.95352 220.583 0.374648 216.16 0.374648H198.178V18.6254H203.523ZM203.523 5.03099H215.633C217.397 5.03099 218.135 5.53944 218.135 7.17183C218.135 8.67042 217.397 9.25915 215.633 9.25915H203.523V5.03099Z"
            fill="#F3F3F3"
          />
          <path
            d="M240.008 19C250.303 19 254.752 16.0296 254.752 9.5C254.752 2.97042 250.303 0 240.008 0C229.714 0 225.265 2.97042 225.265 9.5C225.265 16.0296 229.714 19 240.008 19ZM240.008 14.3437C232.795 14.3437 230.609 13.0324 230.609 9.5C230.609 5.96761 232.795 4.65634 240.008 4.65634C247.196 4.65634 249.302 5.96761 249.302 9.5C249.302 13.0324 247.196 14.3437 240.008 14.3437Z"
            fill="#F3F3F3"
          />
          <path
            d="M265.629 18.6254H272.764L284.374 0.374648H277.977L269.262 14.1831L260.521 0.374648H254.019L265.629 18.6254Z"
            fill="#F3F3F3"
          />
          <path
            d="M303.523 18.6254H310L298.31 0.374648H291.202L279.486 18.6254H285.91L288.095 15.093H301.364L303.523 18.6254ZM290.412 11.3197L294.73 4.30845L299.021 11.3197H290.412Z"
            fill="#F3F3F3"
          />
        </svg>
      </Navbar.Brand>
    </Navbar>
  );
};

export default CustomNavbarThankEs;
