import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Nav, Button, Dropdown } from 'react-bootstrap';
// import { isPromoActive, ScrollContext } from '../../layout/Layout';

import navLogo from '../../assets/imgs/brand/navLogo.svg';
import { isPromoActive, ScrollContext } from '../../layout/Layout';
import ruFlag from '../../assets/imgs/navbar/ru.svg';
import spFlag from '../../assets/imgs/navbar/sp.svg';
import checkPaths from '../../_helpers/checkPaths';
export const CustomNavbarEs = (props) => {
  const getScroll = useContext(ScrollContext);
  const [navExpanded, setNavExpanded] = useState(false);
  const [dropShow, setDropShow] = useState(false);
  const [navColor, setNavColor] = useState('transparent');
  // const [navLogo, setNavLogo] = useState(logo2);

  useEffect(() => {
    // setTimeout(
    //   () =>
    window.addEventListener('DOMContentLoaded', (event) => {
      getScroll().on('scroll', (instance) => {
        if (instance.direction) {
          setNavExpanded(false);
          setDropShow(false);
          // setTimeout(() => {
          //   document.querySelector('#navbar').style.mixBlendMode = 'difference';
          // }, 300);
        }
        if (instance.scroll.y < 50) {
          document.querySelector('#navbar').classList.remove('navbarScrolled');
          setTimeout(() => {
            document.querySelector('.navBrand').style.mixBlendMode = 'unset';
            document.querySelector('.navbar-toggler').style.mixBlendMode =
              'unset';
          }, 300);
        } else {
          document.querySelector('#navbar').classList.add('navbarScrolled');
        }
      });
    });
    //   100
    // );
  }, []);

  const collapsetoggle = () => {
    setNavExpanded(!navExpanded);

    if (!navExpanded) {
      document.querySelector('.navBrand').style.mixBlendMode = 'difference';
      document.querySelector('.navbar-toggler').style.mixBlendMode =
        'difference';
    } else {
      setTimeout(() => {
        document.querySelector('.navBrand').style.mixBlendMode = 'unset';
        document.querySelector('.navbar-toggler').style.mixBlendMode = 'unset';
      }, 300);
    }
  };

  return (
    <Navbar
      id="navbar"
      collapseOnSelect
      expand="lg"
      className={
        isPromoActive() ? 'nav-color-scroll nav-promo' : 'nav-color-scroll'
      }
      variant="light"
      fixed="top"
      expanded={navExpanded}
      onToggle={collapsetoggle}
      // data-scroll
      // data-scroll-sticky
      // data-scroll-target="#inicio"
      // style={isMobile() ? { background: navColor } : ''}
    >
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        // className="ml-auto"
        id="containerNavToggle"
        style={{ outline: 'none' }}
      />
      <Navbar.Brand
        className="navBrand d-flex d-lg-none"
        id="navbarBrandContainer"
        href="/"
      >
        {/* <img alt="" src={navLogo} className="navbarLogo" /> */}
        <svg
          width="182"
          height="10"
          viewBox="0 0 182 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="navbarLogo"
        >
          <path
            d="M3.12244 9.80282V7.53521L6.18334 5.69014L11.1054 9.80282H15.243L8.61362 4.26761L14.9354 0.197183H10.644L4.96821 3.6338L3.12244 5V0.197183H0V9.80282H3.12244Z"
            fill="white"
          />
          <path
            d="M29.6112 9.80282H33.3951L26.5657 0.197183H22.4127L15.568 9.80282H19.321L20.5977 7.94366H28.3499L29.6112 9.80282ZM21.9513 5.95775L24.4738 2.26761L26.981 5.95775H21.9513Z"
            fill="white"
          />
          <path
            d="M37.4011 2.64789V9.80282H40.5235V2.64789H46.7376V0.197183H31.1716V2.64789H37.4011Z"
            fill="white"
          />
          <path
            d="M65.6578 7.07042C65.6578 5.08451 64.3042 4.12676 61.2894 3.97183L55.5675 3.66197C54.6139 3.60563 54.214 3.52113 54.214 2.98592C54.214 2.40845 54.6293 2.26761 57.4595 2.26761C60.0897 2.26761 62.8584 2.53521 64.6426 2.85915L65.1502 0.704225C63.0737 0.295775 60.0435 0.0281696 58.1824 0.0281696C53.3218 0.0281696 51.2607 0.901408 51.2607 3.05634C51.2607 4.84507 52.4451 5.80282 55.706 5.94366L61.5048 6.25352C62.32 6.32394 62.7045 6.39437 62.7045 6.98591C62.7045 7.56338 62.2585 7.74648 59.7974 7.74648C56.6442 7.74648 54.0294 7.3662 51.876 6.92958L51.4299 9.22535C53.3218 9.64789 56.352 9.98591 58.9668 9.98591C64.1504 9.98591 65.6578 9.09859 65.6578 7.07042Z"
            fill="white"
          />
          <path
            d="M79.7512 9.80282H83.535L76.7056 0.197183H72.5526L65.7079 9.80282H69.461L70.7376 7.94366H78.4899L79.7512 9.80282ZM72.0912 5.95775L74.6138 2.26761L77.1209 5.95775H72.0912Z"
            fill="white"
          />
          <path
            d="M87.3149 9.80282V6.66197H97.8973V4.40845H87.3149V2.64789H98.1896V0.197183H84.1924V9.80282H87.3149Z"
            fill="white"
          />
          <path
            d="M111.34 9.80282H115.124L108.295 0.197183H104.142L97.297 9.80282H101.05L102.327 7.94366H110.079L111.34 9.80282ZM103.68 5.95775L106.203 2.26761L108.71 5.95775H103.68Z"
            fill="white"
          />
          <path
            d="M118.904 9.80282V7.01408H122.888L126.656 9.80282H130.578L127.133 7.32394L126.302 6.85915L126.979 6.84507C129.302 6.67606 130.671 5.66197 130.671 3.5493C130.671 1.02817 128.871 0.197183 126.287 0.197183H115.781V9.80282H118.904ZM118.904 2.64789H125.979C127.01 2.64789 127.441 2.91549 127.441 3.77465C127.441 4.56338 127.01 4.87324 125.979 4.87324H118.904V2.64789Z"
            fill="white"
          />
          <path
            d="M140.22 10C146.234 10 148.834 8.43662 148.834 5C148.834 1.56338 146.234 0 140.22 0C134.206 0 131.606 1.56338 131.606 5C131.606 8.43662 134.206 10 140.22 10ZM140.22 7.5493C136.005 7.5493 134.729 6.85915 134.729 5C134.729 3.14084 136.005 2.4507 140.22 2.4507C144.419 2.4507 145.65 3.14084 145.65 5C145.65 6.85915 144.419 7.5493 140.22 7.5493Z"
            fill="white"
          />
          <path
            d="M155.188 9.80282H159.357L166.14 0.197183H162.402L157.311 7.46479L152.204 0.197183H148.405L155.188 9.80282Z"
            fill="white"
          />
          <path
            d="M177.327 9.80282H181.111L174.282 0.197183H170.129L163.284 9.80282H167.037L168.314 7.94366H176.066L177.327 9.80282ZM169.667 5.95775L172.19 2.26761L174.697 5.95775H169.667Z"
            fill="white"
          />
        </svg>
      </Navbar.Brand>

      <Navbar.Text className="d-flex d-lg-none">
        <Dropdown
          className="flagsDropDown"
          show={dropShow}
          onToggle={() => {
            setDropShow(!dropShow);
          }}
        >
          <Dropdown.Toggle className="flagsDropDownToggle">
            <img src={spFlag} alt="" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="flagsDropDownMenu">
            <Dropdown.Item
              onClick={() =>
                (window.location.pathname = window.location.pathname.replace(
                  'es',
                  'ru'
                ))
              }
              className="flagsDropDownItem"
            >
              <img src={ruFlag} alt="" />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar.Text>

      <Navbar.Collapse id="responsive-navbar-nav" className="navCollapse">
        <div className="collapseNavHeight d-block d-lg-none" />
        <Nav className="navContent " id="navLinksBlack">
          <Nav.Link
            onClick={() => {
              getScroll().scrollTo('top');
            }}
            className="linksNav"
          >
            <span>Inicio</span>
          </Nav.Link>
          <svg
            width="4"
            height="4"
            viewBox="0 0 4 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="#F3F3F3" />
          </svg>

          <Nav.Link
            onClick={() => {
              getScroll().scrollTo(document.querySelector('#beneficios'), {
                offset: -72,
              });
            }}
            className="linksNav"
          >
            <span>Beneficios</span>
          </Nav.Link>
          <svg
            width="4"
            height="4"
            viewBox="0 0 4 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="#F3F3F3" />
          </svg>

          <Nav.Link
            onClick={() => {
              getScroll().scrollTo(document.querySelector('#colaboracion'), {
                offset: -72,
              });
            }}
            className="linksNav"
          >
            <span>Colaboración</span>
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              if (checkPaths(window.location.pathname).status) {
                window.location.pathname = checkPaths(
                  window.location.pathname
                ).path;
              } else {
                getScroll().scrollTo('top');
              }
            }}
            className="linksNav d-none d-lg-flex"
          >
            <img alt="" src={navLogo} className="navbarLogo" />
          </Nav.Link>
          <svg
            width="4"
            height="4"
            viewBox="0 0 4 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="#F3F3F3" />
          </svg>

          <Nav.Link
            onClick={() => {
              getScroll().scrollTo(document.querySelector('#programa'), {
                offset: -72,
              });
            }}
            className="linksNav"
          >
            <span>Programa</span>
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              getScroll().scrollTo(document.querySelector('#faq'), {
                offset: -72,
              });
            }}
            className="linksNav"
          >
            <span>Faq</span>
          </Nav.Link>
          <svg
            width="4"
            height="4"
            viewBox="0 0 4 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="#F3F3F3" />
          </svg>

          <Nav.Link
            href="https://sso.teachable.com/secure/667005/users/sign_in"
            target="_blank"
            rel="noopener noreferrer"
            className="linksNav"
          >
            <span>Mis Cursos</span>
          </Nav.Link>
          <svg
            width="4"
            height="4"
            viewBox="0 0 4 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="d-none d-lg-flex"
          >
            <circle cx="2" cy="2" r="2" fill="#F3F3F3" />
          </svg>
          <Nav.Link className="linksNav linksNavDropDown d-none d-lg-flex">
            <Dropdown
              className="flagsDropDown"
              // drop={'right'}
              show={dropShow}
              onToggle={() => {
                setDropShow(!dropShow);
              }}
            >
              <Dropdown.Toggle className="flagsDropDownToggle">
                <img src={spFlag} alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="flagsDropDownMenu">
                <Dropdown.Item
                  onClick={() =>
                    (window.location.pathname =
                      window.location.pathname.replace('es', 'ru'))
                  }
                  className="flagsDropDownItem"
                >
                  <img src={ruFlag} alt="" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Link>
        </Nav>
        {/* <Nav className="d-none d-lg-flex">
          <Nav.Link href="#inscribirse" className="curseLink">
            <span>Acceder al Curso</span>
          </Nav.Link>
        </Nav> */}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbarEs;
