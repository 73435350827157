import React from 'react';

export const CustomNextArrow = (props) => {
  const { className, onClick, sliderArrowMouse } = props;
  return (
    <div className={`${className} ${sliderArrowMouse}`} onClick={onClick}>
      <div className="slickNext link" onClick={onClick}>
        <svg
          width="18"
          height="39"
          viewBox="0 0 18 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2 38L16 19.7643L2 1" stroke="#F3F3F3" strokeWidth="3" />
        </svg>
      </div>
    </div>
  );
};

export default CustomNextArrow;
