import React, { useRef, useState } from 'react';
import { Col, Row, ResponsiveEmbed } from 'react-bootstrap';
import ReactPlayer from 'react-player';

const InsideSection = () => {
  const videoRef = useRef(null);
  const [playingState, setPlayingState] = useState(false);

  const onPlay = () => {
    setPlayingState(true);
  };
  const onPause = () => {
    setPlayingState(false);
  };
  return (
    <Row className="rowSection">
      <Col
        xs={12}
        className="insideCol insideColTitle gs_reveal gs_reveal_direction"
      >
        <div>
          <h2>
            Что тебя ждёт <br className="d-none d-lg-block" />в этом{' '}
            <span className="featuredText">курсе</span>
          </h2>
        </div>
      </Col>
      <Col xs={12} className="insideCol insideColVideo">
        <div className="triangleTopLeft playerWrap">
          {/* <img src={vslInsideImg} alt="" className="vslInsideImg" /> */}

          <ResponsiveEmbed aspectRatio="16by9" className="embedContainer">
            <ReactPlayer
              ref={videoRef}
              id="interviewIframe"
              url="https://player.vimeo.com/video/568330310"
              width="100%"
              height="100%"
              controls={true}
              playing={playingState}
              onPlay={onPlay}
              onPause={onPause}
            />
          </ResponsiveEmbed>
          <div
            className={
              playingState
                ? 'playingVideo playContainer magnetic-wrap'
                : 'playContainer magnetic-wrap'
            }
            onClick={() => {
              setPlayingState(!playingState);
            }}
          >
            <div className="js-magnetic-area magnetic-size"></div>
            <div className="js-magnetic-content">
              <svg
                width="185"
                height="129"
                viewBox="0 0 185 129"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                id="playIconInside"
              >
                <path
                  d="M18.8623 0L0 129H165.724L185 0H18.8623Z"
                  fill="#F6AF25"
                />
                <path
                  d="M117 64L79.5 85.6506L79.5 42.3494L117 64Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default InsideSection;
