import gsap from 'gsap';

const polygonAnimation = () => {
  gsap.utils.toArray('.sectionPolygon').forEach((item) => {
    if (item.classList.contains('sectionPolygonTopExterior')) {
      let sectionPolygon1 = item.querySelector('.sectionPolygon1');
      gsap.to(
        sectionPolygon1,
        {
          height: 0,
          duration: 10,
          scrollTrigger: {
            trigger: sectionPolygon1,
            start: 'top 60%',
            end: 'bottom 60%',
            scrub: 2,
            // markers: true,
            scroller: '[data-scroll-container]',
          },
        },
        '>'
      );
      let sectionPolygon2 = item.querySelector('.sectionPolygon2');

      gsap.to(
        sectionPolygon2,
        {
          height: 0,
          duration: 10,
          scrollTrigger: {
            trigger: sectionPolygon2,
            start: 'top 60%',
            end: 'bottom 60%',
            scrub: 2,
            // markers: true,
            scroller: '[data-scroll-container]',
          },
        },
        '<'
      );
    } else {
      let sectionPolygon1 = item.querySelector('.sectionPolygon1');
      gsap.to(
        sectionPolygon1,
        {
          height: 0,
          duration: 10,
          scrollTrigger: {
            trigger: sectionPolygon1,
            start: 'top 60%',
            end: 'bottom 30%',
            scrub: 2,
            // markers: true,
            scroller: '[data-scroll-container]',
          },
        },
        '>'
      );
      let sectionPolygon2 = item.querySelector('.sectionPolygon2');

      gsap.to(
        sectionPolygon2,
        {
          height: 0,
          duration: 10,
          scrollTrigger: {
            trigger: sectionPolygon2,
            start: 'top 60%',
            end: 'bottom 30%',
            scrub: 2,
            // markers: true,
            scroller: '[data-scroll-container]',
          },
        },
        '<'
      );
    }
  });
};

export default polygonAnimation;
