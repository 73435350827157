import React from "react";
import { Col, Row } from "react-bootstrap";
import aboutMeImg from "../../../../assets/imgs/aboutMe/aboutMeImg.jpg";
const AboutMeSection = () => {
   return (
      <Row className="rowSection">
         <Col className="aboutMeCol aboutMeColImg gs_reveal gs_reveal_fromRight" xs={12} lg={6}>
            <div className="aboutMeImg triangleTopLeft triangleBgColor2">
               <div className="section-parallax">
                  <img src={aboutMeImg} alt="" className="cropBorderLeft" />
               </div>
            </div>
         </Col>
         <Col className="aboutMeCol" xs={12} lg={6}>
            <div className="aboutMeContent">
               <div className="aboutMeTitleWrap gs_reveal gs_reveal_fromLeft">
                  <h2>
                     ¿Qué es el
                     <br className="d-block d-lg-none" /> <span className="featuredText">método</span>
                  </h2>
                  <h3>Kat Safarova?</h3>
               </div>
               <div className="aboutMeTextWrap gs_reveal gs_reveal_direction">
                  <div>
                     <p>Es el método que aplico en mi día a día, diseñado por mi entrenadora y mi nutricionista, para fomentar tu equilibrio y que te sientas bien por dentro y por fuera.</p>
                     <p className="d-none d-lg-block">
                        Además, con la <span className="featuredText">Actualización 2.0</span>, tendrás a tu disposición unos descargables que complementan el curso y que te podrás llevar allá donde vayas.
                     </p>
                     <p className="d-none d-lg-block">Te aseguro que si eres constante realizando estas rutinas, apenas tardarás en obtener resultados.</p>
                     <p className="d-none d-lg-block">Da igual tu edad o el nivel desde el que partas, aquí hay sitio para todas, lo importanteson las ganas que tengas de convertirte en la mujer que deseas ser.</p>
                  </div>
               </div>
            </div>
         </Col>
         <Col className="aboutMeCol aboutMeColMobileText d-flex d-lg-none gs_reveal gs_reveal_direction" xs={12} lg={6}>
            <div className="aboutMeContent">
               <div className="aboutMeTextWrap">
                  <div>
                     <div className="h3sWrap">
                        <h3>Rutínas</h3>
                        <h3>Dietas</h3>
                        <h3>Online</h3>
                     </div>
                     <p>Te aseguro que si eres constante realizando estas rutinas, apenas tardarás en obtener resultados.</p>
                     <p>Da igual tu edad o el nivel desde el que partas, aquí hay sitio para todas, lo importante son las ganas que tengas de convertirte en la mujer que deseas ser.</p>
                  </div>
               </div>
            </div>
         </Col>
      </Row>
   );
};

export default AboutMeSection;
