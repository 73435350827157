import React from 'react';
import { Row } from 'react-bootstrap';
import Update2_0Section from '../../sections/update2_0Section/Update2_0Section';

const Update2_0 = () => {
  return (
    <Row
      className="RootUpdate2_0 RootContainers sectionsPadding"
      id="update2_0"
    >
      <Update2_0Section />
    </Row>
  );
};

export default Update2_0;
