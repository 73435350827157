import React from 'react';
import { Row } from 'react-bootstrap';
import FaqSection from '../../sections/faqSection/FaqSection';

const Faq = () => {
  return (
    <Row className="RootFaq RootContainers sectionsPadding" id="faq">
      <FaqSection />
    </Row>
  );
};

export default Faq;
